import React, { useEffect, useState } from "react";

function TermsAndConditions() {
  const [latestFile, setLatestFile] = useState(null);
  const [latestFileUrl, setLatestFileUrl] = useState("");
  const storageUrl =
    "https://storage.googleapis.com/freezecarbon-dev-public/terms/";

  async function listObjects() {
    try {
      const response = await fetch(
        "https://storage.googleapis.com/storage/v1/b/freezecarbon-dev-public/o?prefix=terms/"
      );
      const data = await response.json();
      const items = data.items;
      // Filter PDF files and sort them based on timeCreated (upload timestamp to Cloud Storage)
      const pdfFilesSortedByUploadTimestamp = items
        .filter((file) => file.name.endsWith(".pdf"))
        .sort(
          (a, b) =>
            new Date(b.timeCreated).getTime() -
            new Date(a.timeCreated).getTime()
        );
      // Output the filtered and sorted PDF files
      let fileName = pdfFilesSortedByUploadTimestamp[0].name;
      let fileNameWithoutTerms = fileName.substring(
        fileName.lastIndexOf("/") + 1
      );
      setLatestFile(fileNameWithoutTerms);
    } catch (error) {
      console.error("Error listing objects:", error);
    }
  }

  useEffect(() => {
    listObjects();
  }, []);

  useEffect(() => {
    const fileUrl = `${storageUrl}${latestFile}`;
    setLatestFileUrl(fileUrl);
  }, [latestFile]);

  useEffect(() => {
    if (latestFile) {
      (async () => {
        const response = await fetch(
          "https://storage.googleapis.com/storage/v1/b/freezecarbon-dev-public/o?prefix=terms/"
        );
        const data = await response.json();
        const items = data.items;
        // Filter PDF files and sort them based on timeCreated (upload timestamp to Cloud Storage)
        const pdfFilesSortedByUploadTimestamp = items
          .filter((file) => file.name.endsWith(".pdf"))
          .sort(
            (a, b) =>
              new Date(b.timeCreated).getTime() -
              new Date(a.timeCreated).getTime()
          );
        // Output the filtered and sorted PDF files
        let fileName = pdfFilesSortedByUploadTimestamp[0].name;
        let fileNameWithoutTerms = fileName.substring(
          fileName.lastIndexOf("/") + 1
        );
        window.location.href = `${storageUrl}${fileNameWithoutTerms}`;
      })();
    }
  }, [latestFileUrl]);

  useEffect(() => {
    async function listObjects() {
      try {
        const response = await fetch(
          "https://storage.googleapis.com/storage/v1/b/freezecarbon-dev-public/o?prefix=terms/"
        );
        const data = await response.json();

        const items = data.items;

        // Filter PDF files and sort them based on timeCreated (upload timestamp to Cloud Storage)
        const pdfFilesSortedByUploadTimestamp = items
          .filter((file) => file.name.endsWith(".pdf"))
          .sort(
            (a, b) =>
              new Date(b.timeCreated).getTime() -
              new Date(a.timeCreated).getTime()
          );

        // Output the filtered and sorted PDF files
        let fileName = pdfFilesSortedByUploadTimestamp[0].name;
        let fileNameWithoutTerms = fileName.substring(
          fileName.lastIndexOf("/") + 1
        );
        setLatestFile(fileNameWithoutTerms);
      } catch (error) {
        console.error("Error listing objects:", error);
      }
    }
    listObjects();
  }, []);

  return <></>;
}

export default TermsAndConditions;
