import React from "react";
import { FormattedMessage } from "react-intl";

import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import gp7 from "../../../assets/images/partners/BVMW.png";
import gp8 from "../../../assets/images/partners/IHK_ecofinder.png";

const Partners = () => {
  return (
    <div className="homepage_certification_logo1">
      <div className="homepage_certification_logo1_frame118">
        <div className="homepage_certification_logo1_frame118_heading">
          <FormattedMessage
            id="growth_partners_climate_communities"
            defaultMessage="Partners"
          />
        </div>
        <div className="homepage_certification_logo1_frame118_content">
          <div className="homepage_certification_logo1_frame118_row white_background">
            <img src={gp1} />
          </div>
          <div className="homepage_certification_logo1_frame118_row white_background">
            <img src={gp7} />
          </div>
          <div className="homepage_certification_logo1_frame118_row white_background">
            <img src={gp3} />
          </div>
          <div className="homepage_certification_logo1_frame118_row white_background">
            <img src={gp2} />
          </div>
          <div className="homepage_certification_logo1_frame118_row white_background">
            <img src={gp8} />
          </div>
          {/* <div className="homepage_certification_logo1_frame118_row white_background ">
            <img src={gp6} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Partners;
