// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  Stack,
  CardMedia,
  Button,
} from "@mui/material";

// project imports
import FadeInWhenVisible from "./Animation";
import SubCard from "ui-component/cards/SubCard";
import Avatar from "ui-component/extended/Avatar";
import { Link as RouterLink } from "react-router-dom";

// assets
import Offer1 from "assets/images/learn/importance.jpg";
import Offer2 from "assets/images/learn/types.jpg";
import Offer3 from "assets/images/learn/projects.jpg";
import Offer4 from "assets/images/learn/pricing.jpg";
import Offer5 from "assets/images/learn/quality.jpg";
import Offer6 from "assets/images/learn/UN.png";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

interface OfferCardProps {
  image: string;
  target: string;
}

const OfferCard1 = ({ image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink
            to={target}
            style={{ textDecoration: "none", color: "black" }}
          >
            <CardMedia
              component="img"
              src={image}
              style={{ height: "250px", width: "100%", marginBottom: "1em" }}
            />
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="cardTitle1"
                  defaultMessage="Carbon credits"
                />
              </Typography>
              <Typography variant="body1">
                {" "}
                <FormattedMessage
                  id="cardCaption1"
                  defaultMessage="How carbon credits combat climate change"
                />
              </Typography>
            </Stack>
          </RouterLink>
        </Avatar>
      </Stack>
    </SubCard>
  );
};
const OfferCard2 = ({ image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink
            to={target}
            style={{ textDecoration: "none", color: "black" }}
          >
            <CardMedia
              component="img"
              src={image}
              style={{ height: "250px", width: "100%", marginBottom: "1em" }}
            />
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="cardTitle2"
                  defaultMessage="Carbon credits"
                />
              </Typography>
              <Typography variant="body1">
                {" "}
                <FormattedMessage
                  id="cardCaption2"
                  defaultMessage="How carbon credits combat climate change"
                />
              </Typography>
            </Stack>
          </RouterLink>
        </Avatar>
      </Stack>
    </SubCard>
  );
};
const OfferCard3 = ({ image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink
            to={target}
            style={{ textDecoration: "none", color: "black" }}
          >
            <CardMedia
              component="img"
              src={image}
              style={{ height: "250px", width: "100%", marginBottom: "1em" }}
            />
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="cardTitle3"
                  defaultMessage="Carbon credits"
                />
              </Typography>
              <Typography variant="body1">
                {" "}
                <FormattedMessage
                  id="cardCaption3"
                  defaultMessage="How carbon credits combat climate change"
                />
              </Typography>
            </Stack>
          </RouterLink>
        </Avatar>
      </Stack>
    </SubCard>
  );
};
const OfferCard4 = ({ image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink
            to={target}
            style={{ textDecoration: "none", color: "black" }}
          >
            <CardMedia
              component="img"
              src={image}
              style={{ height: "250px", width: "100%", marginBottom: "1em" }}
            />
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="cardTitle4"
                  defaultMessage="Carbon credits"
                />
              </Typography>
              <Typography variant="body1">
                {" "}
                <FormattedMessage
                  id="cardCaption4"
                  defaultMessage="How carbon credits combat climate change"
                />
              </Typography>
            </Stack>
          </RouterLink>
        </Avatar>
      </Stack>
    </SubCard>
  );
};
const OfferCard5 = ({ image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink
            to={target}
            style={{ textDecoration: "none", color: "black" }}
          >
            <CardMedia
              component="img"
              src={image}
              style={{ height: "250px", width: "100%", marginBottom: "1em" }}
            />
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="cardTitle5"
                  defaultMessage="Carbon credits"
                />
              </Typography>
              <Typography variant="body1">
                {" "}
                <FormattedMessage
                  id="cardCaption5"
                  defaultMessage="How carbon credits combat climate change"
                />
              </Typography>
            </Stack>
          </RouterLink>
        </Avatar>
      </Stack>
    </SubCard>
  );
};
const OfferCard6 = ({ image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink
            to={target}
            style={{ textDecoration: "none", color: "black" }}
          >
            <CardMedia
              component="img"
              src={image}
              style={{ height: "250px", width: "100%", marginBottom: "1em" }}
            />
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="cardTitle6"
                  defaultMessage="Carbon credits"
                />
              </Typography>
              <Typography variant="body1">
                {" "}
                <FormattedMessage
                  id="cardCaption6"
                  defaultMessage="How carbon credits combat climate change"
                />
              </Typography>
            </Stack>
          </RouterLink>
        </Avatar>
      </Stack>
    </SubCard>
  );
};

// =============================|| LANDING - FEATURE PAGE ||============================= //

const FeatureSection = () => (
  <Container>
    <Grid container spacing={7.5} justifyContent="center">
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={5}
          sx={{ "&> .MuiGrid-root > div": { height: "100%" } }}
        >
          <Grid item md={4} sm={6}>
            <OfferCard1
              image={Offer1}
              target="/pages/learn/importance-carbon-offsetting"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard2
              image={Offer2}
              target="/pages/learn/understanding-project-types"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard3
              image={Offer3}
              target="/pages/learn/high-quality-projects"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard4
              image={Offer4}
              target="/pages/learn/pricing-co2-credits"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard5
              image={Offer5}
              target="/pages/learn/certification-standards"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard6 image={Offer6} target="/pages/learn/social-benefits" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default FeatureSection;
