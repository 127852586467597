import {createBrowserRouter} from "react-router-dom";

// routes
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //
const router = createBrowserRouter([
    AuthenticationRoutes
]);

export default router;
