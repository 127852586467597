import React, { useEffect, useRef, useState } from "react";

import { Link, Link as RouterLink } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons";
import NavBar from "../components/NavBar";
import biochar from "../../../assets/images/new-icons/biochar.svg";
import erw from "../../../assets/images/new-icons/erw.svg";
import bb from "../../../assets/images/new-icons/bb.svg";
import dac from "../../../assets/images/new-icons/dac.svg";
import ee from "../../../assets/images/new-icons/ee.svg";
import rh from "../../../assets/images/new-icons/rh.svg";
import mg from "../../../assets/images/new-icons/mg.svg";
import om from "../../../assets/images/new-icons/om.svg";
import sdw from "../../../assets/images/new-icons/sdw.svg";
import gs from "../../../assets/images/new-icons/gs.svg";
import iso from "../../../assets/images/new-icons/iso.svg";
import dnv from "../../../assets/images/new-icons/dnv.svg";
import pe from "../../../assets/images/new-icons/pe.svg";
import ebc from "../../../assets/images/new-icons/csi.png";
import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import logo from "assets/images/logo.png";
import Footer from "../components/Footer";
import pccs from "../../../assets/images/new-icons/pccs.svg";
import qarm from "../../../assets/images/new-icons/qarm.svg";
import cep from "../../../assets/images/new-icons/cep.svg";
import ProjectScroller from "ui-component/ProjectScroller";
import enpulse from "../../../assets/images/clients/enpulse.png";
import vfu from "../../../assets/images/clients/vfu.png";
import robin from "../../../assets/images/clients/robin.jpeg";
import henrik from "../../../assets/images/clients/henrik.webp";
import philip from "../../../assets/images/clients/dkv_phiplip.jpeg";
import dkv from "../../../assets/images/clients/dkv.png";
import MeetingsEmbed from "../components/MeetingsEmbed";
import ViewsContactLong from "views/hub-spot/contact-long";
import { FormattedMessage } from "react-intl";
import { IconArrowDown, IconHeartHandshake, IconPlus } from "@tabler/icons";
import LinkedIn from "@mui/icons-material/LinkedIn";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Partners from "../components/Partners";
import ClientScroller from "ui-component/ClientScroller";
import via from "../../../assets/images/clients/via.png";
import un from "../../../assets/images/clients/un.svg";
import dend from "../../../assets/images/clients/dendrit.jpg";
import pyur from "../../../assets/images/clients/pyur.png";
import Sparkasse from "../../../assets/images/clients/sparkasse.svg";
import exa from "../../../assets/images/clients/exa.jpg";
import race from "../../../assets/images/clients/race.png";
import laxmi from "../../../assets/images/clients/laxmi.jpg";
import ThorstenCard from "ui-component/ThorstenCard";

const Kompensation = () => {
  const handleServices1 = () => {};

  useEffect(() => {
    sessionStorage.setItem("browserLanguage", "de-DE");
  }, []);

  const containerRef = useRef<HTMLDivElement>(null); // Specify the type of ref
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeDot, setActiveDot] = useState(0);

  const handleScrollTo = (index: number) => {
    if (containerRef.current) {
      // Check if containerRef.current is not null
      const scrollAmount = containerRef.current.offsetWidth * index;
      console.log(scrollAmount);
      containerRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
      setActiveDot(index);
      setScrollPosition(scrollAmount);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeDot + 1) % 3; // Assuming there are 3 sections to scroll through
      handleScrollTo(nextIndex);
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [activeDot]);

  return (
    <div>
      <NavBar />

      {/* project types  */}
      <div className="homepage_hero_container">
        <video autoPlay loop muted className="homepage_hero_container_video">
          <source src="https://storage.googleapis.com/freezecarbon-website/hero.mp4" />{" "}
          {/* Update the type to 'video/quicktime' */}
          Your browser does not support the video tag.
        </video>

        <div className="homepage_hero_textbox">
          <div className="homepage_hero_section_title">
            {/* <div className="homepage_hero_frame129">
              <div className="homepage_hero_frame129_heading">
                <FormattedMessage id="welcome" defaultMessage="Welcome" />
              </div>
            </div> */}
            <div className="homepage_hero_heading">
              <FormattedMessage
                id="heroHeading"
                defaultMessage="Quality & Simplicity in Carbon Credits"
              />
            </div>
            <div className="homepage_hero_text">
              {" "}
              <FormattedMessage
                id="heroDesc"
                defaultMessage="Freeze Carbon curates carbon projects matching your culture and DNA. Deep quality analysis and transparent assessment to mitigate risks."
              />
            </div>
          </div>
          <div>
            {" "}
            <div className="bullet-point-item" style={{ margin: "0" }}>
              <span className="bullet-marker" style={{ color: "white" }}>
                •
              </span>
              <span
                className="bullet-text"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2em",
                  opacity: "1",
                }}
              >
                <FormattedMessage
                  id="header_bullet1"
                  defaultMessage="Cost-efficient procurement"
                />
              </span>
            </div>
            <div className="bullet-point-item">
              <span className="bullet-marker" style={{ color: "white" }}>
                •
              </span>
              <span
                className="bullet-text"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2em",
                  opacity: "1",
                }}
              >
                <FormattedMessage
                  id="header_bullet2"
                  defaultMessage="Complete transparency"
                />
              </span>
            </div>
            <div className="bullet-point-item">
              <span className="bullet-marker" style={{ color: "white" }}>
                •
              </span>
              <span
                className="bullet-text"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2em",
                  opacity: "1",
                }}
              >
                <FormattedMessage
                  id="header_bullet3"
                  defaultMessage="Projects matching your company's DNA"
                />
              </span>
            </div>
          </div>
          <div className="heropage_hero_action">
            {/* <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_kompensation homepage_appbar_nav_get_started homepage_appbar_nav_get_started_kompensation_carbon_credits"
            >
              <FormattedMessage
                id="getStartedPrivate"
                defaultMessage="Sign up"
              />
            </RouterLink> */}
            <a
              href="#register-free-account"
              className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_kompensation"
            >
              <FormattedMessage
                id="exploreProjects"
                defaultMessage="Start now"
              />
              <IconArrowRight color="white" />
            </a>
            {/* <div className="homepage_appbar_nav_watch_video">
            Watch Video <IconArrowRight color="white" />
          </div> */}
          </div>
        </div>
      </div>

      <div className="scroller_tab">
        <div className="client-scroller">
          <div className="client-scroller-track1" ref={containerRef}>
            <div className="client1">
              <img src={via} alt="via" />
            </div>
            <div className="client1">
              <img src={dkv} alt="dkv" />
            </div>
            <div className="client1">
              <img src={enpulse} alt="enpulse" />
            </div>
            <div className="client1">
              <img src={pyur} alt="pyur" />
            </div>
            <div className="client1">
              <img src={Sparkasse} alt="Sparkasse" />
            </div>
            <div className="client1">
              <img src={vfu} alt="vfu" />
            </div>
            <div className="client1">
              <img src={exa} alt="exa" />
            </div>
            <div className="client1">
              <img src={race} alt="race" />
            </div>
            <div className="client1">
              <img style={{ objectFit: "cover" }} src={un} alt="un" />
            </div>
            <div className="client1">
              <img style={{ objectFit: "cover" }} src={dend} alt="dend" />
            </div>
          </div>
        </div>
      </div>

      <div className="homepage_project_container">
        <div className="heading_width">
          <div className="homepage_project_header_container">
            <div className="homepage_project_heading">
              <div className="homepage_project_heading_content">
                <FormattedMessage id="projects" defaultMessage="Projects" />
              </div>
            </div>
            <div className="homepage_project_heading_data_content">
              <div className="homepage_project_heading_data_content_value">
                <FormattedMessage
                  id="homeHeading"
                  defaultMessage="Build a diversified portfolio from a variety of project types"
                />
                {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
              </div>
            </div>
            <div
              className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
              style={{ marginBottom: "0.8em", textTransform: "none" }}
            >
              <FormattedMessage
                id="home_projects_sub_sub"
                defaultMessage="Protect your reputation and contribute to global cooling"
              />
            </div>
          </div>
        </div>
      </div>

      <ProjectScroller />
      <div
        className="homepage_project_container_action center"
        style={{ margin: "2em 0 1em 0" }}
      >
        <a
          href="#register-free-account"
          className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_kompensation"
        >
          <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
        </a>
      </div>

      {/* pathway  */}
      <div className="mission_projects">
        <div className="homepage_project_heading">
          <div className="homepage_project_heading_content">
            {" "}
            <FormattedMessage id="servicesNew" defaultMessage="Principles" />
          </div>
        </div>
        <div
          className="homepage_project_heading_data_content"
          style={{
            margin: "0.3em 0",
          }}
        >
          <div className="homepage_project_heading_data_content_value">
            <FormattedMessage
              id="solutions_text"
              defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
              values={{ break: <br /> }}
            />
            {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
          </div>
        </div>
        <div className="mission_projects_priciples about_section_1">
          <div className="mission_projects_priciples_box">
            <div className="mission_projects_priciples_box_image">
              <IconArrowDown width={50} height={50} />
            </div>
            <div className="mission_projects_priciples_box_heading">
              <FormattedMessage id="solution1" defaultMessage="Together" />
            </div>
            <div className="mission_projects_priciples_box_data">
              <FormattedMessage
                id="solution1_desc"
                defaultMessage="Only as a collective of market participants, companies and teammates can we make the impossible possible."
              />
            </div>
          </div>
          <div className="mission_projects_priciples_box">
            <div className="mission_projects_priciples_box_image">
              <IconHeartHandshake width={50} height={50} />{" "}
            </div>
            <div className="mission_projects_priciples_box_heading">
              <FormattedMessage id="solution2" defaultMessage="Simple" />
            </div>
            <div className="mission_projects_priciples_box_data">
              <FormattedMessage
                id="solution2_desc"
                defaultMessage="We break down the very complex topic of carbon credits into easily digestible pieces."
              />{" "}
            </div>
          </div>
          <div className="mission_projects_priciples_box">
            <div className="mission_projects_priciples_box_image">
              <IconPlus width={50} height={50} />{" "}
            </div>
            <div className="mission_projects_priciples_box_heading">
              {" "}
              <FormattedMessage id="solution3" defaultMessage="transparent" />
            </div>
            <div className="mission_projects_priciples_box_data">
              <FormattedMessage
                id="solution3_desc"
                defaultMessage="We take a very close look at carbon projects so that we understand all the details and can communicate them openly to our customers."
              />
            </div>
          </div>
        </div>
      </div>

      {/* references  */}

      <div className="mission_projects homepage_references">
        <div className="homepage_project_heading">
          <div className="homepage_project_heading_content">
            {" "}
            <FormattedMessage id="customers" defaultMessage="Principles" />
          </div>
        </div>
        <div className="testimonial_reviews_box">
          <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={dkv} style={{ height: "80px" }} />
                </div>

                <div>
                  “
                  <FormattedMessage
                    id="customer5_data"
                    defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                  />
                  ”
                </div>
              </div>
              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={philip}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer5_desc"
                      defaultMessage="Robin Knapp"
                    />
                  </div>
                  <FormattedMessage
                    id="customer5_title"
                    defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={enpulse} style={{ height: "80px" }} />
                </div>

                <div>
                  “
                  <FormattedMessage
                    id="customer1_data"
                    defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                  />
                  ”
                </div>
              </div>
              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={robin}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer1_desc"
                      defaultMessage="Robin Knapp"
                    />
                  </div>
                  <FormattedMessage
                    id="customer1_title"
                    defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2 ">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={vfu} style={{ height: "60px" }} />
                </div>

                <div>
                  {" "}
                  “
                  <FormattedMessage
                    id="customer2_data"
                    defaultMessage="Credibility and quality play a critical role in the market for carbon credits. Carbon Freeze is the “Honest Marketplace” here and offers a platform on which companies can find and invest in high-quality projects."
                  />
                  “
                </div>
              </div>

              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={henrik}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer2_desc"
                      defaultMessage="Henrik Ohlsen"
                    />
                  </div>

                  <FormattedMessage
                    id="customer2_title"
                    defaultMessage="Managing Director,{break} VfU"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className="homepage_project_container_types_row testimonial_reviews_box_child">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2 ">
                <div className="testimonial_reviews_box_icons">
                  <FormatQuoteIcon
                    fontSize="large"
                    className="testimonial_reviews_box_quote"
                  />
                  <img src={race} style={{ height: "60px" }} />
                </div>

                <div>
                  {" "}
                  “
                  <FormattedMessage
                    id="customer6_data"
                    defaultMessage="Credibility and quality play a critical role in the market for carbon credits. Carbon Freeze is the “Honest Marketplace” here and offers a platform on which companies can find and invest in high-quality projects."
                  />
                  “
                </div>
              </div>

              <div className="homepage_project_container_types_row_column_heading2 testimonial_client_photo_data">
                <div className="testimonial_client_photo">
                  <img
                    src={laxmi}
                    style={{
                      height: "80px",
                      borderRadius: "50%",
                      width: "80px",
                      objectFit: "cover",
                      border: "1px solid #0b496b",
                    }}
                  />
                </div>
                <div className="testimonial_client_data">
                  <div className="testimonial_reviewer_position">
                    <FormattedMessage
                      id="customer6_desc"
                      defaultMessage="Henrik Ohlsen"
                    />
                  </div>

                  <FormattedMessage
                    id="customer6_title"
                    defaultMessage="Managing Director,{break} VfU"
                    values={{ break: <br /> }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="customer1_data"
                  defaultMessage="The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row."
                />
              </div>

              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer1_desc"
                    defaultMessage="Robin Knapp"
                  />
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/robin-christoph-knapp-113979131/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px", opacity: "1" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <FormattedMessage
                  id="customer1_title"
                  defaultMessage="Innovation Expert & Project Lead CO2 Taskforce, EnPulse"
                />
              </div>
            </div>
          </div> */}
          {/* <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="customer5_data"
                  defaultMessage='"We like your broad mix of projects, especially that you have many innovative types like biochar and rock weathering."'
                />
              </div>

              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer5_title"
                    defaultMessage="Sustainability Manager"
                  />
                </div>
                <FormattedMessage
                  id="customer5_desc"
                  defaultMessage="Insurance Company"
                />
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column testimonial_review_box">
              <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="customer6_data"
                  defaultMessage='"We like that we can pick projects ourselves and actually understand what is behind each. It was very easy to understand each project."'
                />
              </div>

              <div className="homepage_project_container_types_row_column_heading2">
                <div className="testimonial_reviewer_position">
                  <FormattedMessage
                    id="customer6_title"
                    defaultMessage="ESG Analyst"
                  />
                </div>
                <FormattedMessage
                  id="customer6_desc"
                  defaultMessage="Investment Firm"
                />
              </div>
            </div>
          </div> */}
        </div>
        <div
          className="homepage_project_container_action center"
          style={{ margin: "2em 0 1em 0" }}
        >
          <a
            href="#register-free-account"
            className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_kompensation"
          >
            <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
          </a>
        </div>
      </div>

      {/* certification */}

      <div className="homepage_project_heading_data_content">
        <div className="homepage_project_heading_data_content_value">
          <FormattedMessage
            id="certHeading"
            defaultMessage="Build a diversified portfolio from a variety of project types"
          />
          {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
        </div>
      </div>
      <div className="homepage_certification_logo1">
        <div className="homepage_certification_logo1_frame118">
          <div className="homepage_certification_logo1_frame118_heading">
            {" "}
            <FormattedMessage
              id="certifiers_we_partner_with"
              defaultMessage="Certifiers we partner with"
            />
          </div>
          <div className="homepage_certification_logo1_frame118_content">
            <div className="homepage_certification_logo1_frame118_row">
              <img src={gs} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={iso} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={dnv} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={pe} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={ebc} width="110%" />
            </div>
          </div>
        </div>
      </div>

      {/* services */}

      <div className="homepage_project_container">
        <div className="heading_width">
          {" "}
          <div className="homepage_project_header_container">
            <div className="homepage_project_heading">
              <div className="homepage_project_heading_content">
                <FormattedMessage id="solution" defaultMessage="Services" />
              </div>
            </div>
            <div className="homepage_project_heading_data_content">
              <div className="homepage_project_heading_data_content_value">
                {" "}
                <FormattedMessage
                  id="servicesTitle"
                  defaultMessage="End-to-end{break}carbon credit service"
                  values={{ break: <br /> }}
                />
              </div>
            </div>
            <div
              className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
              style={{ marginBottom: "0.8em", textTransform: "none" }}
            >
              <FormattedMessage
                id="servicesSubTitle"
                defaultMessage="Protect your reputation and contribute to global cooling"
              />
            </div>
          </div>
        </div>
        <div
          className="homepage_project_container_types"
          style={{ width: "100%", padding: "0 1em" }}
        >
          <div className="homepage_how_it_works">
            <RouterLink
              to="/services#quality-analysis"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="homepage_project_container_types_row">
                <div className="homepage_project_container_types_row_column homepage_box_height">
                  <div className="service_banner_image homepage_image">
                    <img src={qarm} className="home_image_width" />
                  </div>
                  <div
                    className="homepage_project_container_types_row_column_heading1"
                    style={{ fontSize: "1.2em" }}
                  >
                    <FormattedMessage
                      id="quality_analysis_risk_mitigation"
                      defaultMessage="Quality analysis{break} & risk mitigation"
                      values={{ break: <br /> }}
                    />
                  </div>
                  {/* <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="detailed_process_description"
                  defaultMessage="Leverage our detailed 3-step process including interviews and questionnaires going beyond the certification process. Only approving 16% of the projects we analyze."
                />
              </div> */}
                </div>
              </div>
            </RouterLink>

            <RouterLink
              to="/services#cost-effective"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="homepage_project_container_types_row">
                <div className="homepage_project_container_types_row_column homepage_box_height">
                  <div className="service_banner_image homepage_image">
                    <img src={cep} className="home_image_width" />
                  </div>
                  <div
                    className="homepage_project_container_types_row_column_heading1"
                    style={{ fontSize: "1.2em" }}
                  >
                    <FormattedMessage
                      id="cost_effective_procurement"
                      defaultMessage="Cost-effective{break} procurement"
                      values={{ break: <br /> }}
                    />
                  </div>
                  {/* <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="cost_description"
                  defaultMessage="Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place whilst benefiting from our volume discounts."
                />
              </div> */}
                </div>
              </div>
            </RouterLink>

            <RouterLink
              to="/services#personalized-strategy"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="homepage_project_container_types_row">
                <div className="homepage_project_container_types_row_column homepage_box_height">
                  <div className="service_banner_image homepage_image">
                    <img src={pccs} className="home_image_width" />
                  </div>
                  <div
                    className="homepage_project_container_types_row_column_heading1"
                    style={{ fontSize: "1.2em" }}
                  >
                    <FormattedMessage
                      id="personalized_carbon_credit_strategy"
                      defaultMessage="Personalized carbon credit strategy"
                    />
                  </div>
                  {/* <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="portfolio_matching_experts"
                  defaultMessage="Our experts tailor a portfolio of projects matching your company DNA, industry and supply chain or get inspiration from our pre-built portfolios."
                />
              </div> */}
                </div>
              </div>
            </RouterLink>
          </div>
        </div>

        <div className="homepage_project_container_action center">
          <a
            href="#register-free-account"
            className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_kompensation"
          >
            <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
          </a>
        </div>
      </div>

      <section id="register-free-account"></section>

      <div className="getting_started_boxes">
        <section className="getting_started_box" id="register-free-account">
          <div className="getting_started_box_content"></div>
          <div className="homepage_project_heading_data_content">
            <div
              className="homepage_project_heading_data_content_value"
              // style={{ width: "1100px" }}
              style={{ marginTop: "2em" }}
            >
              <FormattedMessage
                id="gettingStartedHeader"
                defaultMessage="Sign up to get access to our carbon projects."
                values={{ break: <br /> }}
              />
              {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
            </div>
          </div>
          <div
            style={{ marginTop: "2em" }}
            className="getting_started_contact_form"
          >
            <ViewsContactLong />
          </div>
        </section>

        <ThorstenCard />
      </div>
      {/* partners  */}

      <Partners />

      <Footer />
    </div>
  );
};

export default Kompensation;
