// material-ui
import { useTheme, styled } from "@mui/material/styles";

// project imports
import HubspotForm from "react-hubspot-form";
import AppBar from "ui-component/extended/AppBar";

// assets
import headerBackground from "assets/images/landing/bg-header.jpg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { gridSpacing } from "store/constant";

import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material"; // Divider
import { IconArrowsDoubleSwNe, IconCircleCheck, IconMail } from "@tabler/icons";
import NavBar from "views/new-website/components/NavBar";
import Footer from "views/new-website/components/Footer";
import { FormattedMessage } from "react-intl";

const HeaderWrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${headerBackground})`,
  backgroundSize: "100% 800px",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  // paddingTop: 30,
  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
  },
}));

/*<HubSpotContactForm 
            region="eu1"
            portalId="26684379"
            formId='38e3a863-181e-4729-af63-993a6522cf0d'/>*/

const SectionWrapper = styled("div")({
  paddingTop: 100,
  paddingBottom: 100,
  minWidth: "600px",
});

// ==============================|| SAMPLE PAGE ||============================== //

const ViewsContactLong = () => {
  const theme = useTheme();

  const handleClick = () => {
    console.log("first");
    window.location.pathname = "pages/thank-you";
  };

  return (
    <>
      <NavBar />
      <SectionWrapper
        sx={{
          bgcolor: "background.default",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* thank you page added */}
        <Paper
          elevation={3}
          sx={{ margin: "auto", width: "500px", padding: "2em" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "2em" }}>
            <div>
              <IconCircleCheck color="green" size={40} />
            </div>
            <div>
              <h1>
                {" "}
                <FormattedMessage
                  id="thankYouText"
                  defaultMessage="Thank you!"
                />
              </h1>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.3em" }}
              >
                <Typography>
                  {" "}
                  <FormattedMessage
                    id="linkedInFollow"
                    defaultMessage="Follow us on LinkedIn"
                  />
                </Typography>
                <IconButton
                  size="small"
                  component={Link}
                  href="https://www.linkedin.com/company/freeze-carbon/"
                  target="_blank"
                >
                  <LinkedInIcon
                    sx={{
                      color: "primary.main",
                      "&:hover": { color: "primary.main" },
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </Paper>
      </SectionWrapper>

      {/* footer section */}
      <Footer />
    </>
  );
};

export default ViewsContactLong;
