import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { Stack } from "@mui/system";

const DataPrivacyDE = () => {
  return (
    <div>
      <NavBar />
      <div className="leagal_notice_container">
        <div className="homepage_project_heading_data_content leagal_notice_container_heading">
          <div className="homepage_project_heading_data_content_value">
            Privacy Policy
          </div>
          <div className="leagal_notice_container_description">
            Last updated on 14th Mar 2023
          </div>
        </div>
        <Grid item xs={12} style={{ width: "80%" }}>
          <MainCard
            elevation={4}
            border={false}
            boxShadow
            shadow={4}
            sx={{ mb: 10, backgroundColor: "#F8F8F8" }}
          >
            <Stack spacing={2} sx={{ textAlign: "left" }}>
              <h2>1. Datenschutz auf einen Blick</h2>
              <h3>Allgemeine Hinweise</h3>{" "}
              <p>
                Die folgenden Hinweise geben einen einfachen &Uuml;berblick
                dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert,
                wenn Sie diese Website besuchen. Personenbezogene Daten sind
                alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden
                k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema
                Datenschutz entnehmen Sie unserer unter diesem Text
                aufgef&uuml;hrten Datenschutzerkl&auml;rung.
              </p>
              <h3>Datenerfassung auf dieser Website</h3>{" "}
              <h4>
                Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
                Website?
              </h4>{" "}
              <p>
                Die Datenverarbeitung auf dieser Website erfolgt durch den
                Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
                Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in
                dieser Datenschutzerkl&auml;rung entnehmen.
              </p>{" "}
              <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
              <p>
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
                mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
                Sie in ein Kontaktformular eingeben.
              </p>{" "}
              <p>
                Andere Daten werden automatisch oder nach Ihrer Einwilligung
                beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                sind vor allem technische Daten (z.&nbsp;B. Internetbrowser,
                Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung
                dieser Daten erfolgt automatisch, sobald Sie diese Website
                betreten.
              </p>{" "}
              <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{" "}
              <p>
                Ein Teil der Daten wird erhoben, um eine fehlerfreie
                Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten
                k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
              </p>{" "}
              <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{" "}
              <p>
                Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
                Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem
                ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
                verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
                erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
                f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
                Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
                Weiteren steht Ihnen ein Beschwerderecht bei der
                zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
              </p>{" "}
              <p>
                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
                k&ouml;nnen Sie sich jederzeit an uns wenden.
              </p>
              <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{" "}
              <p>
                Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
                ausgewertet werden. Das geschieht vor allem mit sogenannten
                Analyseprogrammen.
              </p>{" "}
              <p>
                Detaillierte Informationen zu diesen Analyseprogrammen finden
                Sie in der folgenden Datenschutzerkl&auml;rung.
              </p>
              <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
              <p>
                Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
              </p>
              <h3>Externes Hosting</h3>{" "}
              <p>
                Diese Website wird extern gehostet. Die personenbezogenen Daten,
                die auf dieser Website erfasst werden, werden auf den Servern
                des Hosters / der Hoster gespeichert. Hierbei kann es sich
                v.&nbsp;a. um IP-Adressen, Kontaktanfragen, Meta- und
                Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
                Websitezugriffe und sonstige Daten, die &uuml;ber eine Website
                generiert werden, handeln.
              </p>{" "}
              <p>
                Das externe Hosting erfolgt zum Zwecke der
                Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und
                bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
                einer sicheren, schnellen und effizienten Bereitstellung unseres
                Online-Angebots durch einen professionellen Anbieter (Art. 6
                Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
                auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
                oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
                (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
                Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit
                verarbeiten, wie dies zur Erf&uuml;llung seiner
                Leistungspflichten erforderlich ist und unsere Weisungen in
                Bezug auf diese Daten befolgen.
              </p>{" "}
              <p>Wir setzen folgende(n) Hoster ein:</p>
              <p>
                Google Cloud EMEA Ltd <br />
                70 Sir John Rogerson&#039;s Quay, Dublin 2, Dublin
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h3>Google Cloud CDN</h3>{" "}
              <p>
                Wir nutzen das Content Delivery Network Google Cloud CDN.
                Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Google bietet ein weltweit verteiltes Content Delivery Network
                an. Dabei wird technisch der Informationstransfer zwischen Ihrem
                Browser und unserer Website &uuml;ber das Netzwerk von Google
                geleitet. Hierdurch k&ouml;nnen wir die weltweite Erreichbarkeit
                und die Leistungsf&auml;higkeit unserer Website erh&ouml;hen.
              </p>{" "}
              <p>
                Der Einsatz von Google Cloud CDN beruht auf unserem berechtigten
                Interesse an einer m&ouml;glichst fehlerfreien und sicheren
                Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f
                DSGVO).
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://cloud.google.com/terms/eu-model-contract-clause"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/terms/eu-model-contract-clause
                </a>
                .
              </p>{" "}
              <p>
                Weitere Informationen zu Google Cloud CDN finden Sie hier:{" "}
                <a
                  href="https://cloud.google.com/cdn/docs/overview?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/cdn/docs/overview?hl=de
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
              <h3>Datenschutz</h3>{" "}
              <p>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend den
                gesetzlichen Datenschutzvorschriften sowie dieser
                Datenschutzerkl&auml;rung.
              </p>{" "}
              <p>
                Wenn Sie diese Website benutzen, werden verschiedene
                personenbezogene Daten erhoben. Personenbezogene Daten sind
                Daten, mit denen Sie pers&ouml;nlich identifiziert werden
                k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung
                erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie
                nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das
                geschieht.
              </p>{" "}
              <p>
                Wir weisen darauf hin, dass die Daten&uuml;bertragung im
                Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
                Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser
                Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                m&ouml;glich.
              </p>
              <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
              <p>
                Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
                dieser Website ist:
              </p>{" "}
              <p>
                Freeze Carbon GmbH
                <br />
                co TechQuartier <br />
                Platz der Einheit 2 <br />
                60327 Frankfurt am Main
                <br />
                Germany
              </p>
              <p>
                Telefon: +4915209083111
                <br />
                E-Mail: support@freezecarbon.com
              </p>
              <p>
                Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
                Person, die allein oder gemeinsam mit anderen &uuml;ber die
                Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
              </p>
              <h3>Speicherdauer</h3>{" "}
              <p>
                Soweit innerhalb dieser Datenschutzerkl&auml;rung keine
                speziellere Speicherdauer genannt wurde, verbleiben Ihre
                personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die
                Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes
                L&ouml;schersuchen geltend machen oder eine Einwilligung zur
                Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht,
                sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde
                f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben
                (z.&nbsp;B. steuer- oder handelsrechtliche
                Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
                L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
              </p>
              <h3>
                Allgemeine Hinweise zu den Rechtsgrundlagen der
                Datenverarbeitung auf dieser Website
              </h3>{" "}
              <p>
                Sofern Sie in die Datenverarbeitung eingewilligt haben,
                verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO,
                sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO
                verarbeitet werden. Im Falle einer ausdr&uuml;cklichen
                Einwilligung in die &Uuml;bertragung personenbezogener Daten in
                Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf
                Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die
                Speicherung von Cookies oder in den Zugriff auf Informationen in
                Ihr Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting)
                eingewilligt haben, erfolgt die Datenverarbeitung
                zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TTDSG. Die
                Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
                Vertragserf&uuml;llung oder zur Durchf&uuml;hrung
                vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir
                Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
                Weiteren verarbeiten wir Ihre Daten, sofern diese zur
                Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind
                auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
                Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
                Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber
                die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen
                wird in den folgenden Abs&auml;tzen dieser
                Datenschutzerkl&auml;rung informiert.
              </p>
              <h3>
                Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
              </h3>{" "}
              <p>
                Wir verwenden unter anderem Tools von Unternehmen mit Sitz in
                den USA oder sonstigen datenschutzrechtlich nicht sicheren
                Drittstaaten. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
                personenbezogene Daten in diese Drittstaaten &uuml;bertragen und
                dort verarbeitet werden. Wir weisen darauf hin, dass in diesen
                L&auml;ndern kein mit der EU vergleichbares Datenschutzniveau
                garantiert werden kann. Beispielsweise sind US-Unternehmen dazu
                verpflichtet, personenbezogene Daten an Sicherheitsbeh&ouml;rden
                herauszugeben, ohne dass Sie als Betroffener hiergegen
                gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht
                ausgeschlossen werden, dass US-Beh&ouml;rden (z.&nbsp;B.
                Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
                &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
                speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten
                keinen Einfluss.
              </p>
              <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
              <p>
                Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
                ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
                eine bereits erteilte Einwilligung jederzeit widerrufen. Die
                Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
                Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
              </p>
              <h3>
                Widerspruchsrecht gegen die Datenerhebung in besonderen
                F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
              </h3>{" "}
              <p>
                WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT.
                E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS
                GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
                GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH
                EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
                GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
                DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
                DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
                WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
                VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
                SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG
                NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
                &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG,
                AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN
                (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
              </p>{" "}
              <p>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT
                WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT
                SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                NACH ART. 21 ABS. 2 DSGVO).
              </p>
              <h3>
                Beschwerde&shy;recht bei der zust&auml;ndigen
                Aufsichts&shy;beh&ouml;rde
              </h3>{" "}
              <p>
                Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
                Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
                insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
                Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht
                besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                gerichtlicher Rechtsbehelfe.
              </p>
              <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{" "}
              <p>
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert
                verarbeiten, an sich oder an einen Dritten in einem
                g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu
                lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an
                einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                soweit es technisch machbar ist.
              </p>
              <h3>Auskunft, Berichtigung und L&ouml;schung</h3>{" "}
              <p>
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
                gespeicherten personenbezogenen Daten, deren Herkunft und
                Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
                Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
                sowie zu weiteren Fragen zum Thema personenbezogene Daten
                k&ouml;nnen Sie sich jederzeit an uns wenden.
              </p>
              <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{" "}
              <p>
                Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung
                Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen
                Sie sich jederzeit an uns wenden. Das Recht auf
                Einschr&auml;nkung der Verarbeitung besteht in folgenden
                F&auml;llen:
              </p>{" "}
              <ul>
                {" "}
                <li>
                  Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                  personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der
                  Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die
                  Dauer der Pr&uuml;fung haben Sie das Recht, die
                  Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                  Daten zu verlangen.
                </li>{" "}
                <li>
                  Wenn die Verarbeitung Ihrer personenbezogenen Daten
                  unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie
                  statt der L&ouml;schung die Einschr&auml;nkung der
                  Datenverarbeitung verlangen.
                </li>{" "}
                <li>
                  Wenn wir Ihre personenbezogenen Daten nicht mehr
                  ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung
                  oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen,
                  haben Sie das Recht, statt der L&ouml;schung die
                  Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                  Daten zu verlangen.
                </li>{" "}
                <li>
                  Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                  haben, muss eine Abw&auml;gung zwischen Ihren und unseren
                  Interessen vorgenommen werden. Solange noch nicht feststeht,
                  wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
                  Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                  Daten zu verlangen.
                </li>{" "}
              </ul>{" "}
              <p>
                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
                ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
                oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
                Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
                nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
                eines wichtigen &ouml;ffentlichen Interesses der
                Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet
                werden.
              </p>
              <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{" "}
              <p>
                Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
                &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
                Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
                senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
                verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
                Adresszeile des Browsers von &bdquo;http://&ldquo; auf
                &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in
                Ihrer Browserzeile.
              </p>{" "}
              <p>
                Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
                k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht
                von Dritten mitgelesen werden.
              </p>
              <h3>Verschl&uuml;sselter Zahlungsverkehr auf dieser Website</h3>{" "}
              <p>
                Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
                Verpflichtung, uns Ihre Zahlungsdaten (z.&nbsp;B. Kontonummer
                bei Einzugserm&auml;chtigung) zu &uuml;bermitteln, werden diese
                Daten zur Zahlungsabwicklung ben&ouml;tigt.
              </p>{" "}
              <p>
                Der Zahlungsverkehr &uuml;ber die g&auml;ngigen Zahlungsmittel
                (Visa/MasterCard, Lastschriftverfahren) erfolgt
                ausschlie&szlig;lich &uuml;ber eine verschl&uuml;sselte SSL-
                bzw. TLS-Verbindung. Eine verschl&uuml;sselte Verbindung
                erkennen Sie daran, dass die Adresszeile des Browsers von
                &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an
                dem Schloss-Symbol in Ihrer Browserzeile.
              </p>{" "}
              <p>
                Bei verschl&uuml;sselter Kommunikation k&ouml;nnen Ihre
                Zahlungsdaten, die Sie an uns &uuml;bermitteln, nicht von
                Dritten mitgelesen werden.
              </p>
              <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
              <p>
                Der Nutzung von im Rahmen der Impressumspflicht
                ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von
                nicht ausdr&uuml;cklich angeforderter Werbung und
                Informationsmaterialien wird hiermit widersprochen. Die
                Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche
                Schritte im Falle der unverlangten Zusendung von
                Werbeinformationen, etwa durch Spam-E-Mails, vor.
              </p>
              <h2>4. Datenerfassung auf dieser Website</h2>
              <h3>Cookies</h3>{" "}
              <p>
                Unsere Internetseiten verwenden so genannte
                &bdquo;Cookies&ldquo;. Cookies sind kleine Datenpakete und
                richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden
                entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung
                (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
                Endger&auml;t gespeichert. Session-Cookies werden nach Ende
                Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
                bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese
                selbst l&ouml;schen oder eine automatische L&ouml;schung durch
                Ihren Webbrowser erfolgt.
              </p>{" "}
              <p>
                Cookies k&ouml;nnen von uns (First-Party-Cookies) oder von
                Drittunternehmen stammen (sog. Third-Party-Cookies).
                Third-Party-Cookies erm&ouml;glichen die Einbindung bestimmter
                Dienstleistungen von Drittunternehmen innerhalb von Webseiten
                (z.&nbsp;B. Cookies zur Abwicklung von
                Zahlungsdienstleistungen).
              </p>{" "}
              <p>
                Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
                technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
                nicht funktionieren w&uuml;rden (z.&nbsp;B. die
                Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies
                k&ouml;nnen zur Auswertung des Nutzerverhaltens oder zu
                Werbezwecken verwendet werden.
              </p>{" "}
              <p>
                Cookies, die zur Durchf&uuml;hrung des elektronischen
                Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
                erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die
                Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B.
                Cookies zur Messung des Webpublikums) erforderlich sind
                (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1
                lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage
                angegeben wird. Der Websitebetreiber hat ein berechtigtes
                Interesse an der Speicherung von notwendigen Cookies zur
                technisch fehlerfreien und optimierten Bereitstellung seiner
                Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
                und vergleichbaren Wiedererkennungstechnologien abgefragt wurde,
                erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage
                dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25
                Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber
                das Setzen von Cookies informiert werden und Cookies nur im
                Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte
                F&auml;lle oder generell ausschlie&szlig;en sowie das
                automatische L&ouml;schen der Cookies beim Schlie&szlig;en des
                Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
                Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.
              </p>{" "}
              <p>
                Welche Cookies und Dienste auf dieser Website eingesetzt werden,
                k&ouml;nnen Sie dieser Datenschutzerkl&auml;rung entnehmen.
              </p>
              <h3>Removing Cookies</h3>
              <p>
                To remove cookies directly from your browser,{" "}
                <a href="javascript:void(document.cookie='CookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;');">
                  click here
                </a>
                .
              </p>
              <h3>Server-Log-Dateien</h3>{" "}
              <p>
                Der Provider der Seiten erhebt und speichert automatisch
                Informationen in so genannten Server-Log-Dateien, die Ihr
                Browser automatisch an uns &uuml;bermittelt. Dies sind:
              </p>{" "}
              <ul>
                {" "}
                <li>Browsertyp und Browserversion</li>{" "}
                <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
                <li>Hostname des zugreifenden Rechners</li>{" "}
                <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
              </ul>{" "}
              <p>
                Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen
                wird nicht vorgenommen.
              </p>{" "}
              <p>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
                1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an der technisch fehlerfreien Darstellung und der
                Optimierung seiner Website &ndash; hierzu m&uuml;ssen die
                Server-Log-Files erfasst werden.
              </p>
              <h3>Kontaktformular</h3>{" "}
              <p>
                Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                werden Ihre Angaben aus dem Anfrageformular inklusive der von
                Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
                Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns
                gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
                weiter.
              </p>{" "}
              <p>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
                eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
                vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
                &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
                berechtigten Interesse an der effektiven Bearbeitung der an uns
                gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
                wurde; die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben
                bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
                Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r
                die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
                abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
                gesetzliche Bestimmungen &ndash; insbesondere
                Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
              </p>
              <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
              <p>
                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
                Ihre Anfrage inklusive aller daraus hervorgehenden
                personenbezogenen Daten (Name, Anfrage) zum Zwecke der
                Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet.
                Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
              </p>{" "}
              <p>
                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
                eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
                vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
                &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
                berechtigten Interesse an der effektiven Bearbeitung der an uns
                gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
                wurde; die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
                verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern,
                Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
                abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
                gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
                Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
              </p>
              <h3>Google Forms</h3>{" "}
              <p>
                Wir haben Google Forms auf dieser Website eingebunden. Anbieter
                ist die die Google Ireland Limited, Gordon House, Barrow Street,
                Dublin 4, Irland (nachfolgend Google).
              </p>{" "}
              <p>
                Google Forms erm&ouml;glicht es uns, Online-Formulare zu
                erstellen um Nachrichten, Anfragen und sonstige Eingaben unserer
                Websitebesucher strukturiert zu erfassen. Alle von Ihnen
                get&auml;tigten Eingaben werden auf den Servern von Google
                verarbeitet. Google Forms speichert ein Cookie in Ihrem Browser,
                das eine eindeutige ID enth&auml;lt (NID-Cookie). Dieses Cookie
                speichert verschiedene Informationen wie beispielsweise Ihre
                Spracheinstellungen.
              </p>{" "}
              <p>
                Der Einsatz von Google Forms erfolgt auf Grundlage unseres
                berechtigten Interesses an einer m&ouml;glichst
                nutzerfreundlichen Ermittlung Ihres Anliegens (Art. 6 Abs. 1
                lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt
                wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1
                TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
                den Zugriff auf Informationen im Endger&auml;t des Nutzers
                (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
                Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die von Ihnen im Formular eingegebenen Daten verbleiben bei uns,
                bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
                Speicherung widerrufen oder der Zweck f&uuml;r die
                Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
                Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen
                &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben
                unber&uuml;hrt.
              </p>{" "}
              <p>
                Weitere Informationen entnehmen Sie der
                Datenschutzerkl&auml;rung von Google unter{" "}
                <a
                  href="https://policies.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h3>Einsatz von Chatbots</h3>{" "}
              <p>
                Wir setzen Chatbots ein, um mit Ihnen zu kommunizieren. Chatbots
                sind in der Lage, ohne menschliche Hilfe auf Ihre Fragen und
                sonstigen Eingaben zu reagieren. Hierzu analysieren die Chatbots
                neben Ihren Eingaben weitere Daten, um passende Antworten zu
                geben (z.&nbsp;B. Namen, E-Mail-Adressen und sonstige
                Kontaktdaten, Kundennummern und sonstige Identifikatoren,
                Bestellungen und Chatverl&auml;ufe). Ferner k&ouml;nnen
                &uuml;ber den Chatbot Ihre IP-Adresse, Logdateien,
                Standortinformationen und andere Metadaten erfasst werden. Diese
                Daten werden auf den Servern des Chatbot-Anbieters gespeichert.
              </p>{" "}
              <p>
                Auf Grundlage der erfassten Daten k&ouml;nnen Nutzerprofile
                erstellt werden. Au&szlig;erdem k&ouml;nnen die Daten zur
                Ausspielung interessengerechter Werbung genutzt werden, sofern
                die &uuml;brigen rechtlichen Voraussetzungen (insbesondere eine
                Einwilligung) hierf&uuml;r vorliegen. Dazu k&ouml;nnen die
                Chatbots mit Analyse- und Werbetools verkn&uuml;pft werden.
              </p>{" "}
              <p>
                Die erfassten Daten k&ouml;nnen au&szlig;erdem dazu genutzt
                werden, um unsere Chatbots und ihr Antwortverhalten zu
                verbessern (maschinelles Lernen).
              </p>{" "}
              <p>
                Die von Ihnen im Rahmen der Kommunikation eingegebenen Daten
                verbleiben bei uns bzw. dem Chatbotbetreiber, bis Sie uns zur
                L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung
                widerrufen oder der Zweck f&uuml;r die Datenspeicherung
                entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
                Anfrage). Zwingende gesetzliche Bestimmungen &ndash;
                insbesondere Aufbewahrungsfristen &ndash; bleiben
                unber&uuml;hrt.
              </p>{" "}
              <p>
                Rechtsgrundlage f&uuml;r den Einsatz von Chatbots ist Art. 6
                Abs. 1 lit. b DSGVO, sofern der Chatbot zur Vertragsanbahnung
                oder im Rahmen der Vertragserf&uuml;llung eingesetzt wird.
                Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
                die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
                Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
                Einwilligung die Speicherung von Cookies oder den Zugriff auf
                Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                Einwilligung ist jederzeit widerrufbar. In allen anderen
                F&auml;llen erfolgt der Einsatz auf Grundlage unseres
                berechtigten Interesses an einer m&ouml;glichst effektiven
                Kundenkommunikation (Art. 6 Abs. 1 lit. f DSGVO).
              </p>
              <h3>Google Calendar</h3>{" "}
              <p>
                Auf unserer Website haben Sie die M&ouml;glichkeit, Termine mit
                uns zu vereinbaren. F&uuml;r die Planung nutzen wir Google
                Calendar. Anbieter ist die Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland (nachfolgend &bdquo;Google&ldquo;).
              </p>{" "}
              <p>
                Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und
                den Wunschtermin in die daf&uuml;r vorgesehene Maske ein. Die
                eingegebenen Daten werden f&uuml;r die Planung,
                Durchf&uuml;hrung und ggf. f&uuml;r die Nachbereitung des
                Termins verwendet. Die Termindaten werden f&uuml;r uns auf den
                Servern von Google Calendar gespeichert, dessen
                Datenschutzerkl&auml;rung Sie hier einsehen k&ouml;nnen:{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </p>{" "}
              <p>
                Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns
                zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung
                widerrufen oder der Zweck f&uuml;r die Datenspeicherung
                entf&auml;llt. Zwingende gesetzliche Bestimmungen &ndash;
                insbesondere Aufbewahrungsfristen &ndash; bleiben
                unber&uuml;hrt.
              </p>{" "}
              <p>
                Rechtsgrundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1
                lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an einer m&ouml;glichst unkomplizierten
                Terminvereinbarung mit Interessenten und Kunden. Sofern eine
                entsprechende Einwilligung abgefragt wurde, erfolgt die
                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs.
                1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die
                Einwilligung die Speicherung von Cookies oder den Zugriff auf
                Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. f&uuml;r
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://workspace.google.com/terms/dpa_terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://workspace.google.com/terms/dpa_terms.html
                </a>{" "}
                und hier{" "}
                <a
                  href="https://cloud.google.com/terms/sccs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/terms/sccs
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h3>Hubspot CRM</h3>{" "}
              <p>
                Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot
                Inc. 25 Street, Cambridge, MA 02141 USA (nachfolgend Hubspot
                CRM).
              </p>{" "}
              <p>
                Hubspot CRM erm&ouml;glicht es uns unter anderem, bestehende und
                potenzielle Kunden sowie Kundenkontakte zu verwalten. Mit Hilfe
                von Hubspot CRM sind wir in der Lage, Kundeninteraktionen per
                E-Mail, Social Media oder Telefon &uuml;ber verschiedene
                Kan&auml;le hinweg zu erfassen, zu sortieren und zu analysieren.
                Die so erfassten personenbezogenen Daten k&ouml;nnen ausgewertet
                und f&uuml;r die Kommunikation mit dem potenziellen Kunden oder
                f&uuml;r Marketingma&szlig;nahmen (z.&nbsp;B.
                Newslettermailings) verwendet werden. Mit Hubspot CRM sind wir
                ferner in der Lage, das Nutzerverhalten unserer Kontakte auf
                unserer Website zu erfassen und zu analysieren.
              </p>{" "}
              <p>
                Die Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an einer m&ouml;glichst effizienten Kundenverwaltung
                und Kundenkommunikation. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
                auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
                oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
                (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
                Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Details entnehmen Sie der Datenschutzerkl&auml;rung von Hubspot:{" "}
                <a
                  href="https://legal.hubspot.com/de/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://legal.hubspot.com/de/privacy-policy
                </a>
                .
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://www.hubspot.de/data-privacy/privacy-shield"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.hubspot.de/data-privacy/privacy-shield
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h3>Registrierung auf dieser Website</h3>{" "}
              <p>
                Sie k&ouml;nnen sich auf dieser Website registrieren, um
                zus&auml;tzliche Funktionen auf der Seite zu nutzen. Die dazu
                eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des
                jeweiligen Angebotes oder Dienstes, f&uuml;r den Sie sich
                registriert haben. Die bei der Registrierung abgefragten
                Pflichtangaben m&uuml;ssen vollst&auml;ndig angegeben werden.
                Anderenfalls werden wir die Registrierung ablehnen.
              </p>{" "}
              <p>
                F&uuml;r wichtige &Auml;nderungen etwa beim Angebotsumfang oder
                bei technisch notwendigen &Auml;nderungen nutzen wir die bei der
                Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege
                zu informieren.
              </p>{" "}
              <p>
                Die Verarbeitung der bei der Registrierung eingegebenen Daten
                erfolgt zum Zwecke der Durchf&uuml;hrung des durch die
                Registrierung begr&uuml;ndeten Nutzungsverh&auml;ltnisses und
                ggf. zur Anbahnung weiterer Vertr&auml;ge (Art. 6 Abs. 1 lit. b
                DSGVO).
              </p>{" "}
              <p>
                Die bei der Registrierung erfassten Daten werden von uns
                gespeichert, solange Sie auf dieser Website registriert sind und
                werden anschlie&szlig;end gel&ouml;scht. Gesetzliche
                Aufbewahrungsfristen bleiben unber&uuml;hrt.
              </p>
              <h3>Registrierung mit Google</h3>{" "}
              <p>
                Statt einer direkten Registrierung auf dieser Website
                k&ouml;nnen Sie sich mit Google registrieren. Anbieter dieses
                Dienstes ist die Google Ireland Limited (&bdquo;Google&rdquo;),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Um sich mit Google zu registrieren, m&uuml;ssen Sie
                ausschlie&szlig;lich Ihre Google-Namen und Ihr Passwort
                eingeben. Google wird Sie identifizieren und unserer Website
                Ihre Identit&auml;t best&auml;tigen.
              </p>{" "}
              <p>
                Wenn Sie sich mit Google anmelden, ist es uns ggf. m&ouml;glich
                bestimmte Informationen auf Ihrem Konto zu nutzen, um Ihr Profil
                bei uns zu vervollst&auml;ndigen. Ob und welche Informationen
                das sind, entscheiden Sie im Rahmen Ihrer
                Google-Sicherheitseinstellungen, die Sie hier finden:{" "}
                <a
                  href="https://myaccount.google.com/security"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://myaccount.google.com/security
                </a>{" "}
                und{" "}
                <a
                  href="https://myaccount.google.com/permissions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://myaccount.google.com/permissions
                </a>
                .
              </p>{" "}
              <p>
                Die Datenverarbeitung, die mit der Google-Registrierung
                einhergeht beruht auf unserem berechtigten Interesse, unseren
                Nutzern einen m&ouml;glichst einfachen Registrierungsprozess zu
                erm&ouml;glichen (Art. 6 Abs. 1 lit. f DSGVO). Da die Nutzung
                der Registrierungsfunktion freiwillig ist und die Nutzer selbst
                &uuml;ber die jeweiligen Zugriffsm&ouml;glichkeiten entscheiden
                k&ouml;nnen, sind keine entgegenstehenden &uuml;berwiegenden
                Rechte der Betroffenen ersichtlich.
              </p>
              <h3>Kommentar&shy;funktion auf dieser Website</h3>{" "}
              <p>
                F&uuml;r die Kommentarfunktion auf dieser Seite werden neben
                Ihrem Kommentar auch Angaben zum Zeitpunkt der Erstellung des
                Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym
                posten, der von Ihnen gew&auml;hlte Nutzername gespeichert.
              </p>
              <h4>Speicherung der IP-Adresse</h4>{" "}
              <p>
                Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer,
                die Kommentare verfassen. Da wir Kommentare auf dieser Website
                nicht vor der Freischaltung pr&uuml;fen, ben&ouml;tigen wir
                diese Daten, um im Falle von Rechtsverletzungen wie
                Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu
                k&ouml;nnen.
              </p>
              <h4>Speicherdauer der Kommentare</h4>{" "}
              <p>
                Die Kommentare und die damit verbundenen Daten werden
                gespeichert und verbleiben auf dieser Website, bis der
                kommentierte Inhalt vollst&auml;ndig gel&ouml;scht wurde oder
                die Kommentare aus rechtlichen Gr&uuml;nden gel&ouml;scht werden
                m&uuml;ssen (z.&nbsp;B. beleidigende Kommentare).
              </p>
              <h4>Rechtsgrundlage</h4>{" "}
              <p>
                Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie k&ouml;nnen eine
                von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu
                reicht eine formlose Mitteilung per E-Mail an uns. Die
                Rechtm&auml;&szlig;igkeit der bereits erfolgten
                Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf
                unber&uuml;hrt.
              </p>
              <h2>5. Soziale Medien</h2>
              <h3>LinkedIn</h3>{" "}
              <p>
                Diese Website nutzt Elemente des Netzwerks LinkedIn. Anbieter
                ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
                Place, Dublin 2, Irland.
              </p>{" "}
              <p>
                Bei jedem Abruf einer Seite dieser Website, die Elemente von
                LinkedIn enth&auml;lt, wird eine Verbindung zu Servern von
                LinkedIn aufgebaut. LinkedIn wird dar&uuml;ber informiert, dass
                Sie diese Website mit Ihrer IP-Adresse besucht haben. Wenn Sie
                den &bdquo;Recommend-Button&ldquo; von LinkedIn anklicken und in
                Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn
                m&ouml;glich, Ihren Besuch auf dieser Website Ihnen und Ihrem
                Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als
                Anbieter der Seiten keine Kenntnis vom Inhalt der
                &uuml;bermittelten Daten sowie deren Nutzung durch LinkedIn
                haben.
              </p>{" "}
              <p>
                Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
                Einsatz des o.&nbsp;g. Dienstes auf Grundlage von Art. 6 Abs. 1
                lit. a DSGVO und &sect; 25 TTDSG. Die Einwilligung ist jederzeit
                widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt
                die Verwendung des Dienstes auf Grundlage unseres berechtigten
                Interesses an einer m&ouml;glichst umfassenden Sichtbarkeit in
                den Sozialen Medien.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de
                </a>
              </p>{" "}
              <p>
                Weitere Informationen hierzu finden Sie in der
                Datenschutzerkl&auml;rung von LinkedIn unter:{" "}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/privacy-policy
                </a>
                .
              </p>
              <h3>XING</h3>{" "}
              <p>
                Diese Website nutzt Elemente des Netzwerks XING. Anbieter ist
                die New Work SE, Dammtorstra&szlig;e 30, 20354 Hamburg,
                Deutschland.
              </p>{" "}
              <p>
                Bei jedem Abruf einer unserer Seiten, die Elemente von XING
                enth&auml;lt, wird eine Verbindung zu Servern von XING
                hergestellt. Eine Speicherung von personenbezogenen Daten
                erfolgt dabei nach unserer Kenntnis nicht. Insbesondere werden
                keine IP-Adressen gespeichert oder das Nutzungsverhalten
                ausgewertet.
              </p>{" "}
              <p>
                Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
                Einsatz des o.&nbsp;g. Dienstes auf Grundlage von Art. 6 Abs. 1
                lit. a DSGVO und &sect; 25 TTDSG. Die Einwilligung ist jederzeit
                widerrufbar. Soweit keine Einwilligung eingeholt wurde, erfolgt
                die Verwendung des Dienstes auf Grundlage unseres berechtigten
                Interesses an einer m&ouml;glichst umfassenden Sichtbarkeit in
                den Sozialen Medien.
              </p>{" "}
              <p>
                Weitere Information zum Datenschutz und dem XING Share-Button
                finden Sie in der Datenschutzerkl&auml;rung von XING unter:{" "}
                <a
                  href="https://www.xing.com/app/share?op=data_protection"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.xing.com/app/share?op=data_protection
                </a>
                .
              </p>
              <h2>6. Analyse-Tools und Werbung</h2>
              <h3>Google Tag Manager</h3>{" "}
              <p>
                Wir setzen den Google Tag Manager ein. Anbieter ist die Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir
                Tracking- oder Statistik-Tools und andere Technologien auf
                unserer Website einbinden k&ouml;nnen. Der Google Tag Manager
                selbst erstellt keine Nutzerprofile, speichert keine Cookies und
                nimmt keine eigenst&auml;ndigen Analysen vor. Er dient lediglich
                der Verwaltung und Ausspielung der &uuml;ber ihn eingebundenen
                Tools. Der Google Tag Manager erfasst jedoch Ihre IP-Adresse,
                die auch an das Mutterunternehmen von Google in die Vereinigten
                Staaten &uuml;bertragen werden kann.
              </p>{" "}
              <p>
                Der Einsatz des Google Tag Managers erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an einer schnellen und unkomplizierten
                Einbindung und Verwaltung verschiedener Tools auf seiner
                Website. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit
                die Einwilligung die Speicherung von Cookies oder den Zugriff
                auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                Einwilligung ist jederzeit widerrufbar.
              </p>
              <h3>Google Analytics</h3>{" "}
              <p>
                Diese Website nutzt Funktionen des Webanalysedienstes Google
                Analytics. Anbieter ist die Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </p>{" "}
              <p>
                Google Analytics erm&ouml;glicht es dem Websitebetreiber, das
                Verhalten der Websitebesucher zu analysieren. Hierbei
                erh&auml;lt der Websitebetreiber verschiedene Nutzungsdaten, wie
                z.&nbsp;B. Seitenaufrufe, Verweildauer, verwendete
                Betriebssysteme und Herkunft des Nutzers. Diese Daten werden in
                einer User-ID zusammengefasst und dem jeweiligen Endger&auml;t
                des Websitebesuchers zugeordnet.
              </p>{" "}
              <p>
                Des Weiteren k&ouml;nnen wir mit Google Analytics u.&nbsp;a.
                Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner
                verwendet Google Analytics verschiedene
                Modellierungsans&auml;tze, um die erfassten Datens&auml;tze zu
                erg&auml;nzen und setzt Machine-Learning-Technologien bei der
                Datenanalyse ein.
              </p>
              <p>
                Google Analytics verwendet Technologien, die die Wiedererkennung
                des Nutzers zum Zwecke der Analyse des Nutzerverhaltens
                erm&ouml;glichen (z.&nbsp;B. Cookies oder
                Device-Fingerprinting). Die von Google erfassten Informationen
                &uuml;ber die Benutzung dieser Website werden in der Regel an
                einen Server von Google in den USA &uuml;bertragen und dort
                gespeichert.
              </p>{" "}
              <p>
                Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
                Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://privacy.google.com/businesses/controllerterms/mccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/controllerterms/mccs/
                </a>
                .
              </p>{" "}
              <h4>Browser Plugin</h4>{" "}
              <p>
                Sie k&ouml;nnen die Erfassung und Verarbeitung Ihrer Daten durch
                Google verhindern, indem Sie das unter dem folgenden Link
                verf&uuml;gbare Browser-Plugin herunterladen und installieren:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>
                .
              </p>{" "}
              <p>
                Mehr Informationen zum Umgang mit Nutzerdaten bei Google
                Analytics finden Sie in der Datenschutzerkl&auml;rung von
                Google:{" "}
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/6004245?hl=de
                </a>
                .
              </p>
              <h4>Google-Signale</h4>{" "}
              <p>
                Wir nutzen Google-Signale. Wenn Sie unsere Website besuchen,
                erfasst Google Analytics u.&nbsp;a. Ihren Standort, Suchverlauf
                und YouTube-Verlauf sowie demografische Daten (Besucherdaten).
                Diese Daten k&ouml;nnen mit Hilfe von Google-Signal f&uuml;r
                personalisierte Werbung verwendet werden. Wenn Sie &uuml;ber ein
                Google-Konto verf&uuml;gen, werden die Besucherdaten von
                Google-Signal mit Ihrem Google-Konto verkn&uuml;pft und f&uuml;r
                personalisierte Werbebotschaften verwendet. Die Daten werden
                au&szlig;erdem f&uuml;r die Erstellung anonymisierter
                Statistiken zum Nutzerverhalten unserer User verwendet.
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
                abgeschlossen und setzen die strengen Vorgaben der deutschen
                Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics
                vollst&auml;ndig um.
              </p>
              <h4>Google Analytics E-Commerce-Messung</h4>{" "}
              <p>
                Diese Website nutzt die Funktion
                &bdquo;E-Commerce-Messung&ldquo; von Google Analytics. Mit Hilfe
                von E-Commerce-Messung kann der Websitebetreiber das
                Kaufverhalten der Websitebesucher zur Verbesserung seiner
                Online-Marketing-Kampagnen analysieren. Hierbei werden
                Informationen, wie zum Beispiel die get&auml;tigten
                Bestellungen, durchschnittliche Bestellwerte, Versandkosten und
                die Zeit von der Ansicht bis zum Kauf eines Produktes erfasst.
                Diese Daten k&ouml;nnen von Google unter einer Transaktions-ID
                zusammengefasst werden, die dem jeweiligen Nutzer bzw. dessen
                Ger&auml;t zugeordnet ist.
              </p>
              <h3>Google Ads</h3>{" "}
              <p>
                Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
                Online-Werbeprogramm der Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </p>{" "}
              <p>
                Google Ads erm&ouml;glicht es uns Werbeanzeigen in der
                Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn
                der Nutzer bestimmte Suchbegriffe bei Google eingibt
                (Keyword-Targeting). Ferner k&ouml;nnen zielgerichtete
                Werbeanzeigen anhand der bei Google vorhandenen Nutzerdaten
                (z.&nbsp;B. Standortdaten und Interessen) ausgespielt werden
                (Zielgruppen-Targeting). Wir als Websitebetreiber k&ouml;nnen
                diese Daten quantitativ auswerten, indem wir beispielsweise
                analysieren, welche Suchbegriffe zur Ausspielung unserer
                Werbeanzeigen gef&uuml;hrt haben und wie viele Anzeigen zu
                entsprechenden Klicks gef&uuml;hrt haben.
              </p>{" "}
              <p>
                Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
                Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://policies.google.com/privacy/frameworks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy/frameworks
                </a>{" "}
                und{" "}
                <a
                  href="https://privacy.google.com/businesses/controllerterms/mccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/controllerterms/mccs/
                </a>
                .
              </p>
              <h3>Google Ads Remarketing</h3>{" "}
              <p>
                Diese Website nutzt die Funktionen von Google Ads Remarketing.
                Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;),
                Gordon House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Mit Google Ads Remarketing k&ouml;nnen wir Personen, die mit
                unserem Online-Angebot interagieren bestimmten Zielgruppen
                zuordnen, um ihnen anschlie&szlig;end interessenbezogene Werbung
                im Google-Werbenetzwerk anzeigen zu lassen (Remarketing bzw.
                Retargeting).
              </p>{" "}
              <p>
                Des Weiteren k&ouml;nnen die mit Google Ads Remarketing
                erstellten Werbe-Zielgruppen mit den
                ger&auml;te&uuml;bergreifenden Funktionen von Google
                verkn&uuml;pft werden. Auf diese Weise k&ouml;nnen
                interessenbezogene, personalisierte Werbebotschaften, die in
                Abh&auml;ngigkeit Ihres fr&uuml;heren Nutzungs- und
                Surfverhaltens auf einem Endger&auml;t (z.&nbsp;B. Handy) an Sie
                angepasst wurden auch auf einem anderen Ihrer Endger&auml;te
                (z.&nbsp;B. Tablet oder PC) angezeigt werden.
              </p>{" "}
              <p>
                Wenn Sie &uuml;ber einen Google-Account verf&uuml;gen,
                k&ouml;nnen Sie der personalisierten Werbung unter folgendem
                Link widersprechen:{" "}
                <a
                  href="https://www.google.com/settings/ads/onweb/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.google.com/settings/ads/onweb/
                </a>
                .
              </p>{" "}
              <p>
                Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
                Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Weitergehende Informationen und die Datenschutzbestimmungen
                finden Sie in der Datenschutzerkl&auml;rung von Google unter:{" "}
                <a
                  href="https://policies.google.com/technologies/ads?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/technologies/ads?hl=de
                </a>
                .
              </p>
              <h4>Zielgruppenbildung mit Kundenabgleich</h4>{" "}
              <p>
                Zur Zielgruppenbildung verwenden wir unter anderem den
                Kundenabgleich von Google Ads Remarketing. Hierbei
                &uuml;bergeben wir bestimmte Kundendaten (z.&nbsp;B.
                E-Mail-Adressen) aus unseren Kundenlisten an Google. Sind die
                betreffenden Kunden Google-Nutzer und in ihrem Google-Konto
                eingeloggt, werden ihnen passende Werbebotschaften innerhalb des
                Google-Netzwerks (z.&nbsp;B. bei YouTube, Gmail oder in der
                Suchmaschine) angezeigt.
              </p>
              <h3>LinkedIn Insight Tag</h3>{" "}
              <p>
                Diese Website nutzt das Insight-Tag von LinkedIn. Anbieter
                dieses Dienstes ist die LinkedIn Ireland Unlimited Company,
                Wilton Plaza, Wilton Place, Dublin 2, Irland.
              </p>{" "}
              <h4>Datenverarbeitung durch LinkedIn Insight Tag</h4>{" "}
              <p>
                Mit Hilfe des LinkedIn Insight Tags erhalten wir Informationen
                &uuml;ber die Besucher unserer Website. Ist ein Websitebesucher
                bei LinkedIn registriert, k&ouml;nnen wir u.&nbsp;a. die
                beruflichen Eckdaten (z.&nbsp;B. Karrierestufe,
                Unternehmensgr&ouml;&szlig;e, Land, Standort, Branche und
                Berufsbezeichnung) unserer Websitebesucher analysieren und so
                unsere Seite besser auf die jeweiligen Zielgruppen ausrichten.
                Ferner k&ouml;nnen wir mit Hilfe von LinkedIn Insight Tags
                messen, ob die Besucher unserer Webseiten einen Kauf oder eine
                sonstige Aktion t&auml;tigen (Conversion-Messung). Die
                Conversion-Messung kann auch ger&auml;te&uuml;bergreifend
                erfolgen (z.&nbsp;B. von PC zu Tablet). LinkedIn Insight Tag
                bietet au&szlig;erdem eine Retargeting-Funktion an, mit deren
                Hilfe wir den Besuchern unserer Website zielgerichtete Werbung
                au&szlig;erhalb der Website anzeigen lassen k&ouml;nnen, wobei
                laut LinkedIn keine Identifikation des Werbeadressaten
                stattfindet.
              </p>{" "}
              <p>
                LinkedIn selbst erfasst au&szlig;erdem sogenannte Logfiles (URL,
                Referrer-URL, IP-Adresse, Ger&auml;te- und Browsereigenschaften
                und Zeitpunkt des Zugriffs). Die IP-Adressen werden gek&uuml;rzt
                oder (sofern sie genutzt werden, um LinkedIn-Mitglieder
                ger&auml;te&uuml;bergreifend zu erreichen) gehasht
                (pseudonymisiert). Die direkten Kennungen der
                LinkedIn-Mitglieder werden nach sieben Tagen von LinkedIn
                gel&ouml;scht. Die verbleibenden pseudonymisierten Daten werden
                dann innerhalb von 180 Tagen gel&ouml;scht.
              </p>{" "}
              <p>
                Die von LinkedIn erhobenen Daten k&ouml;nnen von uns als
                Websitebetreiber nicht bestimmten Einzelpersonen zugeordnet
                werden. LinkedIn wird die erfassten personenbezogenen Daten der
                Websitebesucher auf seinen Servern in den USA speichern und sie
                im Rahmen eigener Werbema&szlig;nahmen nutzen. Details entnehmen
                Sie der Datenschutzerkl&auml;rung von LinkedIn unter{" "}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy#choices-oblig"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/privacy-policy#choices-oblig
                </a>
                .
              </p>{" "}
              <h4>Rechtsgrundlage</h4>{" "}
              <p>
                Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
                Einsatz des o.&nbsp;g. Dienstes ausschlie&szlig;lich auf
                Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 TTDSG.
                Die Einwilligung ist jederzeit widerrufbar. Soweit keine
                Einwilligung eingeholt wurde, erfolgt die Verwendung dieses
                Dienstes auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO; der
                Websitebetreiber hat ein berechtigtes Interesse an effektiven
                Werbema&szlig;nahmen unter Einschluss der sozialen Medien.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://www.linkedin.com/legal/l/dpa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/l/dpa
                </a>{" "}
                und{" "}
                <a
                  href="https://www.linkedin.com/legal/l/eu-sccs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/l/eu-sccs
                </a>
                .
              </p>{" "}
              <h4>Widerspruch gegen die Verwendung von LinkedIn Insight Tag</h4>{" "}
              <p>
                Der Analyse des Nutzungsverhaltens sowie zielgerichtete Werbung
                durch LinkedIn unter folgendem Link widersprechen:{" "}
                <a
                  href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                </a>
                .
              </p>{" "}
              <p>
                Des Weiteren k&ouml;nnen Mitglieder von LinkedIn die Nutzung
                ihrer personenbezogenen Daten zu Werbezwecken in den
                Kontoeinstellungen steuern. Um einer Verkn&uuml;pfung von auf
                unserer Website erhobenen Daten durch LinkedIn und Ihrem
                LinkedIn Account zu vermeiden, m&uuml;ssen Sie sich aus Ihrem
                LinkedIn-Account ausloggen bevor Sie unsere Website aufsuchen.
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h2>7. Newsletter</h2>
              <h3>Newsletter&shy;daten</h3>{" "}
              <p>
                Wenn Sie den auf der Website angebotenen Newsletter beziehen
                m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse
                sowie Informationen, welche uns die &Uuml;berpr&uuml;fung
                gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse
                und mit dem Empfang des Newsletters einverstanden sind. Weitere
                Daten werden nicht bzw. nur auf freiwilliger Basis erhoben.
                F&uuml;r die Abwicklung der Newsletter nutzen wir
                Newsletterdiensteanbieter, die nachfolgend beschrieben werden.
              </p>
              <h3>Mailchimp</h3>{" "}
              <p>
                Diese Website nutzt die Dienste von Mailchimp f&uuml;r den
                Versand von Newslettern. Anbieter ist die Rocket Science Group
                LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308,
                USA.
              </p>{" "}
              <p>
                Mailchimp ist ein Dienst, mit dem u.a. der Versand von
                Newslettern organisiert und analysiert werden kann. Wenn Sie
                Daten zum Zwecke des Newsletterbezugs eingeben (z.&nbsp;B.
                E-Mail-Adresse), werden diese auf den Servern von Mailchimp in
                den USA gespeichert.
              </p>{" "}
              <p>
                Mit Hilfe von Mailchimp k&ouml;nnen wir unsere
                Newsletterkampagnen analysieren. Wenn Sie eine mit Mailchimp
                versandte E-Mail &ouml;ffnen, verbindet sich eine in der E-Mail
                enthaltene Datei (sog. web-beacon) mit den Servern von Mailchimp
                in den USA. So kann festgestellt werden, ob eine
                Newsletter-Nachricht ge&ouml;ffnet und welche Links ggf.
                angeklickt wurden. Au&szlig;erdem werden technische
                Informationen erfasst (z.&nbsp;B. Zeitpunkt des Abrufs,
                IP-Adresse, Browsertyp und Betriebssystem). Diese Informationen
                k&ouml;nnen nicht dem jeweiligen Newsletter-Empf&auml;nger
                zugeordnet werden. Sie dienen ausschlie&szlig;lich der
                statistischen Analyse von Newsletterkampagnen. Die Ergebnisse
                dieser Analysen k&ouml;nnen genutzt werden, um k&uuml;nftige
                Newsletter besser an die Interessen der Empf&auml;nger
                anzupassen.
              </p>{" "}
              <p>
                Wenn Sie keine Analyse durch Mailchimp wollen, m&uuml;ssen Sie
                den Newsletter abbestellen. Hierf&uuml;r stellen wir in jeder
                Newsletternachricht einen entsprechenden Link zur
                Verf&uuml;gung.
              </p>{" "}
              <p>
                Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
                (Art. 6 Abs. 1 lit. a DSGVO). Sie k&ouml;nnen diese Einwilligung
                jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die
                Rechtm&auml;&szlig;igkeit der bereits erfolgten
                Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf
                unber&uuml;hrt.
              </p>{" "}
              <p>
                Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
                hinterlegten Daten werden von uns bis zu Ihrer Austragung aus
                dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
                gespeichert und nach der Abbestellung des Newsletters aus der
                Newsletterverteilerliste gel&ouml;scht. Daten, die zu anderen
                Zwecken bei uns gespeichert wurden, bleiben hiervon
                unber&uuml;hrt.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://mailchimp.com/eu-us-data-transfer-statement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://mailchimp.com/eu-us-data-transfer-statement/
                </a>{" "}
                und{" "}
                <a
                  href="https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses
                </a>
                .
              </p>{" "}
              <p>
                Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
                E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf.
                in einer Blacklist gespeichert, sofern dies zur Verhinderung
                k&uuml;nftiger Mailings erforderlich ist. Die Daten aus der
                Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht
                mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem
                Interesse als auch unserem Interesse an der Einhaltung der
                gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
                Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
                Speicherung in der Blacklist ist zeitlich nicht befristet.{" "}
                <strong>
                  Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre
                  Interessen unser berechtigtes Interesse &uuml;berwiegen.
                </strong>
              </p>{" "}
              <p>
                N&auml;heres entnehmen Sie den Datenschutzbestimmungen von
                Mailchimp unter:{" "}
                <a
                  href="https://mailchimp.com/legal/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://mailchimp.com/legal/terms/
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h2>8. Plugins und Tools</h2>
              <h3>YouTube mit erweitertem Datenschutz</h3>{" "}
              <p>
                Diese Website bindet Videos der Website YouTube ein. Betreiber
                der Seiten ist die Google Ireland Limited
                (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4,
                Irland.
              </p>{" "}
              <p>
                Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
                bewirkt laut YouTube, dass YouTube keine Informationen &uuml;ber
                die Besucher auf dieser Website speichert, bevor diese sich das
                Video ansehen. Die Weitergabe von Daten an YouTube-Partner wird
                durch den erweiterten Datenschutzmodus hingegen nicht zwingend
                ausgeschlossen. So stellt YouTube &ndash; unabh&auml;ngig davon,
                ob Sie sich ein Video ansehen &ndash; eine Verbindung zum Google
                DoubleClick-Netzwerk her.
              </p>{" "}
              <p>
                Sobald Sie ein YouTube-Video auf dieser Website starten, wird
                eine Verbindung zu den Servern von YouTube hergestellt. Dabei
                wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
                besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt
                sind, erm&ouml;glichen Sie YouTube, Ihr Surfverhalten direkt
                Ihrem pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie
                verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
              </p>{" "}
              <p>
                Des Weiteren kann YouTube nach Starten eines Videos verschiedene
                Cookies auf Ihrem Endger&auml;t speichern oder vergleichbare
                Wiedererkennungstechnologien (z.&nbsp;B. Device-Fingerprinting)
                einsetzen. Auf diese Weise kann YouTube Informationen &uuml;ber
                Besucher dieser Website erhalten. Diese Informationen werden
                u.&nbsp;a. verwendet, um Videostatistiken zu erfassen, die
                Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
                vorzubeugen.
              </p>{" "}
              <p>
                Gegebenenfalls k&ouml;nnen nach dem Start eines YouTube-Videos
                weitere Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden,
                auf die wir keinen Einfluss haben.
              </p>{" "}
              <p>
                Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
                Darstellung unserer Online-Angebote. Dies stellt ein
                berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
                dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit
                die Einwilligung die Speicherung von Cookies oder den Zugriff
                auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Weitere Informationen &uuml;ber Datenschutz bei YouTube finden
                Sie in deren Datenschutzerkl&auml;rung unter:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Vimeo</h3>{" "}
              <p>
                Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist
                die Vimeo Inc., 555 West 18th Street, New York, New York 10011,
                USA.
              </p>{" "}
              <p>
                Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten
                Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo
                hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche
                unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre
                IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Vimeo
                eingeloggt sind oder keinen Account bei Vimeo besitzen. Die von
                Vimeo erfassten Informationen werden an den Vimeo-Server in den
                USA &uuml;bermittelt.
              </p>{" "}
              <p>
                Wenn Sie in Ihrem Vimeo-Account eingeloggt sind,
                erm&ouml;glichen Sie Vimeo, Ihr Surfverhalten direkt Ihrem
                pers&ouml;nlichen Profil zuzuordnen. Dies k&ouml;nnen Sie
                verhindern, indem Sie sich aus Ihrem Vimeo-Account ausloggen.
              </p>{" "}
              <p>
                Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies
                bzw. vergleichbare Wiedererkennungstechnologien (z.&nbsp;B.
                Device-Fingerprinting).
              </p>{" "}
              <p>
                Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
                Darstellung unserer Online-Angebote. Dies stellt ein
                berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO
                dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit
                die Einwilligung die Speicherung von Cookies oder den Zugriff
                auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B.
                Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission sowie nach Aussage
                von Vimeo auf &bdquo;berechtigte Gesch&auml;ftsinteressen&ldquo;
                gest&uuml;tzt. Details finden Sie hier:{" "}
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vimeo.com/privacy
                </a>
                .
              </p>{" "}
              <p>
                Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in
                der Datenschutzerkl&auml;rung von Vimeo unter:{" "}
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vimeo.com/privacy
                </a>
                .
              </p>
              <h3>Google Fonts (lokales Hosting)</h3>{" "}
              <p>
                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
                so genannte Google Fonts, die von Google bereitgestellt werden.
                Die Google Fonts sind lokal installiert. Eine Verbindung zu
                Servern von Google findet dabei nicht statt.
              </p>{" "}
              <p>
                Weitere Informationen zu Google Fonts finden Sie unter{" "}
                <a
                  href="https://developers.google.com/fonts/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://developers.google.com/fonts/faq
                </a>{" "}
                und in der Datenschutzerkl&auml;rung von Google:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Adobe Fonts</h3>{" "}
              <p>
                Diese Website nutzt zur einheitlichen Darstellung bestimmter
                Schriftarten Web Fonts von Adobe. Anbieter ist die Adobe Systems
                Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA
                (Adobe).
              </p>{" "}
              <p>
                Beim Aufruf dieser Website l&auml;dt Ihr Browser die
                ben&ouml;tigten Schriftarten direkt von Adobe, um sie Ihrem
                Endger&auml;t korrekt anzeigen zu k&ouml;nnen. Dabei stellt Ihr
                Browser eine Verbindung zu den Servern von Adobe in den USA her.
                Hierdurch erlangt Adobe Kenntnis dar&uuml;ber, dass &uuml;ber
                Ihre IP-Adresse diese Website aufgerufen wurde. Bei der
                Bereitstellung der Schriftarten werden nach Aussage von Adobe
                keine Cookies gespeichert.
              </p>{" "}
              <p>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse an der einheitlichen Darstellung des
                Schriftbildes auf seiner Website. Sofern eine entsprechende
                Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a
                DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die
                Speicherung von Cookies oder den Zugriff auf Informationen im
                Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im
                Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
                widerrufbar.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://www.adobe.com/de/privacy/eudatatransfers.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.adobe.com/de/privacy/eudatatransfers.html
                </a>
                .
              </p>{" "}
              <p>
                N&auml;here Informationen zu Adobe Fonts erhalten Sie unter:{" "}
                <a
                  href="https://www.adobe.com/de/privacy/policies/adobe-fonts.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.adobe.com/de/privacy/policies/adobe-fonts.html
                </a>
                .
              </p>{" "}
              <p>
                Die Datenschutzerkl&auml;rung von Adobe finden Sie unter:{" "}
                <a
                  href="https://www.adobe.com/de/privacy/policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.adobe.com/de/privacy/policy.html
                </a>
              </p>
              <h3>Google Maps</h3>{" "}
              <p>
                Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
                Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
                Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Zur Nutzung der Funktionen von Google Maps ist es notwendig,
                Ihre IP-Adresse zu speichern. Diese Informationen werden in der
                Regel an einen Server von Google in den USA &uuml;bertragen und
                dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss
                auf diese Daten&uuml;bertragung. Wenn Google Maps aktiviert ist,
                kann Google zum Zwecke der einheitlichen Darstellung der
                Schriftarten Google Fonts verwenden. Beim Aufruf von Google Maps
                l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren
                Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
              </p>{" "}
              <p>
                Die Nutzung von Google Maps erfolgt im Interesse einer
                ansprechenden Darstellung unserer Online-Angebote und an einer
                leichten Auffindbarkeit der von uns auf der Website angegebenen
                Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
                Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
                auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
                oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
                (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
                Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/gdprcontrollerterms/
                </a>{" "}
                und{" "}
                <a
                  href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
                </a>
                .
              </p>{" "}
              <p>
                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                Datenschutzerkl&auml;rung von Google:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h3>Google reCAPTCHA</h3>{" "}
              <p>
                Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im Folgenden
                &bdquo;reCAPTCHA&ldquo;) auf dieser Website. Anbieter ist die
                Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
                Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die
                Dateneingabe auf dieser Website (z.&nbsp;B. in einem
                Kontaktformular) durch einen Menschen oder durch ein
                automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA
                das Verhalten des Websitebesuchers anhand verschiedener
                Merkmale. Diese Analyse beginnt automatisch, sobald der
                Websitebesucher die Website betritt. Zur Analyse wertet
                reCAPTCHA verschiedene Informationen aus (z.&nbsp;B. IP-Adresse,
                Verweildauer des Websitebesuchers auf der Website oder vom
                Nutzer get&auml;tigte Mausbewegungen). Die bei der Analyse
                erfassten Daten werden an Google weitergeleitet.
              </p>{" "}
              <p>
                Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund.
                Websitebesucher werden nicht darauf hingewiesen, dass eine
                Analyse stattfindet.
              </p>{" "}
              <p>
                Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse daran, seine Webangebote vor
                missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor
                SPAM zu sch&uuml;tzen. Sofern eine entsprechende Einwilligung
                abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
                auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs.
                1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
                oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
                (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
                Die Einwilligung ist jederzeit widerrufbar.
              </p>{" "}
              <p>
                Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
                Google-Datenschutzbestimmungen und den Google
                Nutzungsbedingungen unter folgenden Links:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>{" "}
                und{" "}
                <a
                  href="https://policies.google.com/terms?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/terms?hl=de
                </a>
                .
              </p>
              <h2>9. eCommerce und Zahlungs&shy;anbieter</h2>
              <h3>Verarbeiten von Kunden- und Vertragsdaten</h3>{" "}
              <p>
                Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und
                Vertragsdaten zur Begr&uuml;ndung, inhaltlichen Ausgestaltung
                und &Auml;nderung unserer Vertragsbeziehungen. Personenbezogene
                Daten &uuml;ber die Inanspruchnahme dieser Website
                (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit
                dies erforderlich ist, um dem Nutzer die Inanspruchnahme des
                Dienstes zu erm&ouml;glichen oder abzurechnen. Rechtsgrundlage
                hierf&uuml;r ist Art. 6 Abs. 1 lit. b DSGVO.
              </p>{" "}
              <p>
                Die erhobenen Kundendaten werden nach Abschluss des Auftrags
                oder Beendigung der Gesch&auml;ftsbeziehung und Ablauf der ggf.
                bestehenden gesetzlichen Aufbewahrungsfristen gel&ouml;scht.
                Gesetzliche Aufbewahrungsfristen bleiben unber&uuml;hrt.
              </p>
              <h3>
                Daten&shy;&uuml;bermittlung bei Vertragsschluss f&uuml;r
                Online-Shops, H&auml;ndler und Warenversand
              </h3>{" "}
              <p>
                Wenn Sie Waren bei uns bestellen, geben wir Ihre
                personenbezogenen Daten an das zur Lieferung betraute
                Transportunternehmen sowie an den mit der Zahlungsabwicklung
                beauftragten Zahlungsdienstleister weiter. Es werden nur solche
                Daten herausgegeben, die der jeweilige Dienstleister zur
                Erf&uuml;llung seiner Aufgabe ben&ouml;tigt. Rechtsgrundlage
                hierf&uuml;r ist Art. 6 Abs. 1 lit. b DSGVO, der die
                Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags oder
                vorvertraglicher Ma&szlig;nahmen gestattet. Sofern Sie eine
                entsprechende Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO
                erteilt haben, werden wir Ihre E-Mail-Adresse an das mit der
                Lieferung betraute Transportunternehmen &uuml;bergeben, damit
                dieses Sie per E-Mail &uuml;ber den Versandstatus Ihrer
                Bestellung informieren kann; Sie k&ouml;nnen die Einwilligung
                jederzeit widerrufen.
              </p>
              <h3>
                Daten&shy;&uuml;bermittlung bei Vertragsschluss f&uuml;r
                Dienstleistungen und digitale Inhalte
              </h3>{" "}
              <p>
                Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann,
                wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa
                an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.
              </p>{" "}
              <p>
                Eine weitergehende &Uuml;bermittlung der Daten erfolgt nicht
                bzw. nur dann, wenn Sie der &Uuml;bermittlung ausdr&uuml;cklich
                zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne
                ausdr&uuml;ckliche Einwilligung, etwa zu Zwecken der Werbung,
                erfolgt nicht.
              </p>{" "}
              <p>
                Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit.
                b DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines
                Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet.
              </p>
              <h3>Zahlungsdienste</h3>{" "}
              <p>
                Wir binden Zahlungsdienste von Drittunternehmen auf unserer
                Website ein. Wenn Sie einen Kauf bei uns t&auml;tigen, werden
                Ihre Zahlungsdaten (z.&nbsp;B. Name, Zahlungssumme,
                Kontoverbindung, Kreditkartennummer) vom Zahlungsdienstleister
                zum Zwecke der Zahlungsabwicklung verarbeitet. F&uuml;r diese
                Transaktionen gelten die jeweiligen Vertrags- und
                Datenschutzbestimmungen der jeweiligen Anbieter. Der Einsatz der
                Zahlungsdienstleister erfolgt auf Grundlage von Art. 6 Abs. 1
                lit. b DSGVO (Vertragsabwicklung) sowie im Interesse eines
                m&ouml;glichst reibungslosen, komfortablen und sicheren
                Zahlungsvorgangs (Art. 6 Abs. 1 lit. f DSGVO). Soweit f&uuml;r
                bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist Art.
                6 Abs. 1 lit. a DSGVO Rechtsgrundlage der Datenverarbeitung;
                Einwilligungen sind jederzeit f&uuml;r die Zukunft widerrufbar.
              </p>
              <p>
                Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im
                Rahmen dieser Website ein:
              </p>
              <h4>Stripe</h4>{" "}
              <p>
                Anbieter f&uuml;r Kunden innerhalb der EU ist die Stripe
                Payments Europe, Ltd.,1 Grand Canal Street Lower, Grand Canal
                Dock, Dublin, Irland (im Folgenden &bdquo;Stripe&ldquo;).
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://stripe.com/de/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/de/privacy
                </a>{" "}
                und{" "}
                <a
                  href="https://stripe.com/de/guides/general-data-protection-regulation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/de/guides/general-data-protection-regulation
                </a>
                .
              </p>{" "}
              <p>
                Details hierzu k&ouml;nnen Sie in der Datenschutzerkl&auml;rung
                von Stripe unter folgendem Link nachlesen:{" "}
                <a
                  href="https://stripe.com/de/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/de/privacy
                </a>
                .
              </p>
              <h2>10. Audio- und Videokonferenzen</h2>
              <h4>Datenverarbeitung</h4>{" "}
              <p>
                F&uuml;r die Kommunikation mit unseren Kunden setzen wir unter
                anderen Online-Konferenz-Tools ein. Die im Einzelnen von uns
                genutzten Tools sind unten aufgelistet. Wenn Sie mit uns per
                Video- oder Audiokonferenz via Internet kommunizieren, werden
                Ihre personenbezogenen Daten von uns und dem Anbieter des
                jeweiligen Konferenz-Tools erfasst und verarbeitet.
              </p>{" "}
              <p>
                Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur
                Nutzung der Tools bereitstellen/einsetzen (E-Mail-Adresse
                und/oder Ihre Telefonnummer). Ferner verarbeiten die
                Konferenz-Tools die Dauer der Konferenz, Beginn und Ende (Zeit)
                der Teilnahme an der Konferenz, Anzahl der Teilnehmer und
                sonstige &bdquo;Kontextinformationen&ldquo; im Zusammenhang mit
                dem Kommunikationsvorgang (Metadaten).
              </p>{" "}
              <p>
                Des Weiteren verarbeitet der Anbieter des Tools alle technischen
                Daten, die zur Abwicklung der Online-Kommunikation erforderlich
                sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen,
                Ger&auml;te-IDs, Ger&auml;tetyp, Betriebssystemtyp und -version,
                Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die
                Art der Verbindung.
              </p>{" "}
              <p>
                Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen
                oder in sonstiger Weise bereitgestellt werden, werden diese
                ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu
                solchen Inhalten z&auml;hlen insbesondere Cloud-Aufzeichnungen,
                Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und
                Videos, Dateien, Whiteboards und andere Informationen, die
                w&auml;hrend der Nutzung des Dienstes geteilt werden.
              </p>{" "}
              <p>
                Bitte beachten Sie, dass wir nicht vollumf&auml;nglich Einfluss
                auf die Datenverarbeitungsvorg&auml;nge der verwendeten Tools
                haben. Unsere M&ouml;glichkeiten richten sich ma&szlig;geblich
                nach der Unternehmenspolitik des jeweiligen Anbieters. Weitere
                Hinweise zur Datenverarbeitung durch die Konferenztools
                entnehmen Sie den Datenschutzerkl&auml;rungen der jeweils
                eingesetzten Tools, die wir unter diesem Text aufgef&uuml;hrt
                haben.
              </p>{" "}
              <h4>Zweck und Rechtsgrundlagen</h4>{" "}
              <p>
                Die Konferenz-Tools werden genutzt, um mit angehenden oder
                bestehenden Vertragspartnern zu kommunizieren oder bestimmte
                Leistungen gegen&uuml;ber unseren Kunden anzubieten (Art. 6 Abs.
                1 lit. b DSGVO). Des Weiteren dient der Einsatz der Tools der
                allgemeinen Vereinfachung und Beschleunigung der Kommunikation
                mit uns bzw. unserem Unternehmen (berechtigtes Interesse im
                Sinne von Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung
                abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf
                Grundlage dieser Einwilligung; die Einwilligung ist jederzeit
                mit Wirkung f&uuml;r die Zukunft widerrufbar.
              </p>{" "}
              <h4>Speicherdauer</h4>{" "}
              <p>
                Die unmittelbar von uns &uuml;ber die Video- und Konferenz-Tools
                erfassten Daten werden von unseren Systemen gel&ouml;scht,
                sobald Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung
                zur Speicherung widerrufen oder der Zweck f&uuml;r die
                Datenspeicherung entf&auml;llt. Gespeicherte Cookies verbleiben
                auf Ihrem Endger&auml;t, bis Sie sie l&ouml;schen. Zwingende
                gesetzliche Aufbewahrungsfristen bleiben unber&uuml;hrt.
              </p>{" "}
              <p>
                Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
                Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
                keinen Einfluss. F&uuml;r Einzelheiten dazu informieren Sie sich
                bitte direkt bei den Betreibern der Konferenz-Tools.
              </p>{" "}
              <h4>Eingesetzte Konferenz-Tools</h4>{" "}
              <p>Wir setzen folgende Konferenz-Tools ein:</p>
              <h3>Zoom</h3>{" "}
              <p>
                Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom
                Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor,
                San Jose, CA 95113, USA. Details zur Datenverarbeitung entnehmen
                Sie der Datenschutzerkl&auml;rung von Zoom:{" "}
                <a
                  href="https://zoom.us/de-de/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://zoom.us/de-de/privacy.html
                </a>
                .
              </p>{" "}
              <p>
                Die Daten&uuml;bertragung in die USA wird auf die
                Standardvertragsklauseln der EU-Kommission gest&uuml;tzt.
                Details finden Sie hier:{" "}
                <a
                  href="https://zoom.us/de-de/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://zoom.us/de-de/privacy.html
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h3>Google Meet</h3>{" "}
              <p>
                Wir nutzen Google Meet. Anbieter ist die Google Ireland Limited,
                Gordon House, Barrow Street, Dublin 4, Irland. Details zur
                Datenverarbeitung entnehmen Sie der Datenschutzerkl&auml;rung
                von Google:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
                .
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
              <h2>11. Eigene Dienste</h2>
              <h3>Umgang mit Bewerberdaten</h3>{" "}
              <p>
                Wir bieten Ihnen die M&ouml;glichkeit, sich bei uns zu bewerben
                (z.&nbsp;B. per E-Mail, postalisch oder via
                Online-Bewerberformular). Im Folgenden informieren wir Sie
                &uuml;ber Umfang, Zweck und Verwendung Ihrer im Rahmen des
                Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir
                versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer
                Daten in &Uuml;bereinstimmung mit geltendem Datenschutzrecht und
                allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten
                streng vertraulich behandelt werden.
              </p>{" "}
              <h4>Umfang und Zweck der Datenerhebung </h4>{" "}
              <p>
                Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir
                Ihre damit verbundenen personenbezogenen Daten (z.&nbsp;B.
                Kontakt- und Kommunikationsdaten, Bewerbungsunterlagen, Notizen
                im Rahmen von Bewerbungsgespr&auml;chen etc.), soweit dies zur
                Entscheidung &uuml;ber die Begr&uuml;ndung eines
                Besch&auml;ftigungsverh&auml;ltnisses erforderlich ist.
                Rechtsgrundlage hierf&uuml;r ist &sect; 26 BDSG nach deutschem
                Recht (Anbahnung eines Besch&auml;ftigungsverh&auml;ltnisses),
                Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und
                &ndash; sofern Sie eine Einwilligung erteilt haben &ndash; Art.
                6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit
                widerrufbar. Ihre personenbezogenen Daten werden innerhalb
                unseres Unternehmens ausschlie&szlig;lich an Personen
                weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt
                sind.
              </p>{" "}
              <p>
                Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
                eingereichten Daten auf Grundlage von &sect; 26 BDSG und Art. 6
                Abs. 1 lit. b DSGVO zum Zwecke der Durchf&uuml;hrung des
                Besch&auml;ftigungsverh&auml;ltnisses in unseren
                Datenverarbeitungssystemen gespeichert.
              </p>{" "}
              <h4>Aufbewahrungsdauer der Daten</h4>{" "}
              <p>
                Sofern wir Ihnen kein Stellenangebot machen k&ouml;nnen, Sie ein
                Stellenangebot ablehnen oder Ihre Bewerbung zur&uuml;ckziehen,
                behalten wir uns das Recht vor, die von Ihnen &uuml;bermittelten
                Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs.
                1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des
                Bewerbungsverfahrens (Ablehnung oder Zur&uuml;ckziehung der
                Bewerbung) bei uns aufzubewahren. Anschlie&szlig;end werden die
                Daten gel&ouml;scht und die physischen Bewerbungsunterlagen
                vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken
                im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die
                Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden
                (z.&nbsp;B. aufgrund eines drohenden oder anh&auml;ngigen
                Rechtsstreits), findet eine L&ouml;schung erst statt, wenn der
                Zweck f&uuml;r die weitergehende Aufbewahrung entf&auml;llt.
              </p>{" "}
              <p>
                Eine l&auml;ngere Aufbewahrung kann au&szlig;erdem stattfinden,
                wenn Sie eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a
                DSGVO) erteilt haben oder wenn gesetzliche
                Aufbewahrungspflichten der L&ouml;schung entgegenstehen.
              </p>
              <h3>Google Drive</h3>{" "}
              <p>
                Wir haben Google Drive auf dieser Website eingebunden. Anbieter
                ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon
                House, Barrow Street, Dublin 4, Irland.
              </p>{" "}
              <p>
                Google Drive erm&ouml;glicht es uns, einen Uploadbereich auf
                unserer Website einzubinden, in dem Sie Inhalte hochladen
                k&ouml;nnen. Wenn Sie Inhalte hochladen, werden diese auf den
                Servern von Google Drive gespeichert. Wenn Sie unsere Website
                betreten, wird au&szlig;erdem eine Verbindung zu Google Drive
                aufgebaut, sodass Google Drive feststellen kann, dass Sie unsere
                Website besucht haben.
              </p>{" "}
              <p>
                Die Verwendung von Google Drive erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse an einem zuverl&auml;ssigen Uploadbereich auf seiner
                Website. Sofern eine entsprechende Einwilligung abgefragt wurde,
                erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
                Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                widerrufbar.
              </p>
              <h4>Auftragsverarbeitung</h4>{" "}
              <p>
                Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
                Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt
                es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
                der gew&auml;hrleistet, dass dieser die personenbezogenen Daten
                unserer Websitebesucher nur nach unseren Weisungen und unter
                Einhaltung der DSGVO verarbeitet.
              </p>
            </Stack>
          </MainCard>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default DataPrivacyDE;
