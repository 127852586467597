import React, { useEffect, useRef } from "react";
import via from "../assets/images/clients/via.png";
import vfu from "../assets/images/clients/vfu.png";
import un from "../assets/images/clients/un.svg";
import dend from "../assets/images/clients/dendrit.jpg";
import dkv from "../assets/images/clients/dkv.png";
import enpulse from "../assets/images/clients/enpulse.png";
import pyur from "../assets/images/clients/pyur.png";
import Sparkasse from "../assets/images/clients/sparkasse.svg";
import exa from "../assets/images/clients/exa.jpg";
import race from "../assets/images/clients/race.png";

const ClientScroller = () => {
  const containerRef = useRef<HTMLDivElement>(null); // Explicitly type the ref as HTMLDivElement

  // Duplicate the logos to create the seamless infinite effect
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const originalContent = container.innerHTML; // Save original content
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
      container.innerHTML += originalContent; // Duplicate content for seamless scroll
    }
  }, []);

  return (
    <div className="scroller_tab">
      <div className="client-scroller">
        <div className="client-scroller-track" ref={containerRef}>
          <div className="client">
            <img src={via} alt="via" />
          </div>
          <div className="client">
            <img src={dkv} alt="dkv" />
          </div>
          <div className="client">
            <img src={enpulse} alt="enpulse" />
          </div>
          <div className="client">
            <img src={pyur} alt="pyur" />
          </div>
          <div className="client">
            <img src={Sparkasse} alt="Sparkasse" />
          </div>
          <div className="client">
            <img src={vfu} alt="vfu" />
          </div>
          <div className="client">
            <img src={exa} alt="exa" />
          </div>
          <div className="client">
            <img src={race} alt="race" />
          </div>
          <div className="client">
            <img style={{ objectFit: "cover" }} src={un} alt="un" />
          </div>
          <div className="client">
            <img style={{ objectFit: "cover" }} src={dend} alt="dend" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientScroller;
