import { Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

/*
'body1' - normal text
| 'body2'
| 'button'
| 'caption'
| 'h1' - headline overall page
| 'h2' - reserved
| 'h3' - chapter headlines (e.g. Buyer, Seller)
| 'h4' - CTA
| 'h5'
| 'h6' - 
| 'subtitle1' - section subtitle
| 'subtitle2'
*/

const Typography = (theme: Theme, borderRadius: number, fontFamily: string): TypographyOptions => ({
    fontFamily,
    // Footer # 
    h6: {
        fontWeight: 500,
        color: theme.palette.grey[600],
        fontSize: '1.0rem'
    },

    // Small highlights
    h5: {
        fontSize: '1.25rem',
        color: theme.palette.grey[600],
        fontWeight: 500
    },

    // CTA
    h4: {
        fontSize: '1.5rem',
        color: theme.palette.grey[600],
        fontWeight: 600
    },

    // chapter headlines (e.g. Buyer, Seller) #
    h3: {
        fontSize: '2.0rem',
        color: theme.palette.grey[600],
        fontWeight: 600
    },

    //reserved
    h2: {
        fontSize: '2.0rem',
        color: theme.palette.grey[600],
        fontWeight: 700
    },

    //overall headline
    h1: {
        fontSize: '3.2rem',
        color: theme.palette.grey[600],
        fontWeight: 700
    },

    //section text subtitle
    subtitle1: {
        fontSize: '1.0rem',
        fontWeight: 600,
        color: theme.palette.text.dark
    },

    //section text subtitle
    subtitle2: {
        fontSize: '1.0rem',
        fontWeight: 400,
        color: theme.palette.text.secondary
    },
    caption: {
        fontSize: '1rem',
        color: theme.palette.text.secondary,
        fontWeight: 400
    },

    //normal text
    body1: {
        fontSize: '1.0rem',
        fontWeight: 400,
        lineHeight: '1.334em'
    },
    body2: {
        letterSpacing: '0em',
        fontWeight: 400,
        lineHeight: '1.5em',
        color: theme.palette.text.primary
    },
    button: {
        textTransform: 'capitalize',
        fontSize: '1.0rem'
    },
    customInput: {
        marginTop: 1,
        marginBottom: 1,
        '& > label': {
            top: 23,
            left: 0,
            color: theme.palette.grey[500],
            '&[data-shrink="false"]': {
                top: 5
            }
        },
        '& > div > input': {
            padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    },
    mainContent: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[100],
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `${borderRadius}px`
    },
    menuCaption: {
        fontSize: '1.1rem',
        fontWeight: 500,
        color: theme.palette.grey[600],
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px'
    },
    subMenuCaption: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.text.secondary,
        textTransform: 'capitalize'
    },
    commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px'
    },
    smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem'
    },
    mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem'
    },
    largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem'
    }
});

export default Typography;
