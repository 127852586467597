import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { Stack } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";

const DataPrivacy = () => {
  return (
    <div>
      <NavBar />
      <div className="leagal_notice_container">
        <div className="homepage_project_heading_data_content leagal_notice_container_heading">
          <div className="homepage_project_heading_data_content_value">
            Privacy Policy
          </div>
          <div className="leagal_notice_container_description">
            Last updated on 14th Mar 2023
          </div>
          <div className="leagal_notice_container_description">
            <RouterLink to="/data-privacy-de">German version</RouterLink>
          </div>
        </div>
        <Grid item xs={12} style={{ width: "80%" }}>
          <MainCard
            elevation={4}
            border={false}
            boxShadow
            shadow={4}
            sx={{ mb: 10, backgroundColor: "#F8F8F8" }}
          >
            <Stack spacing={2} sx={{ textAlign: "left" }}>
              <h1>Privacy Policy</h1>
              <h2>1. An overview of data protection</h2>
              <h3>General information</h3>{" "}
              <p>
                The following information will provide you with an easy to
                navigate overview of what will happen with your personal data
                when you visit this website. The term &ldquo;personal
                data&rdquo; comprises all data that can be used to personally
                identify you. For detailed information about the subject matter
                of data protection, please consult our Data Protection
                Declaration, which we have included beneath this copy.
              </p>
              <h3>Data recording on this website</h3>{" "}
              <h4>
                Who is the responsible party for the recording of data on this
                website (i.e., the &ldquo;controller&rdquo;)?
              </h4>{" "}
              <p>
                The data on this website is processed by the operator of the
                website, whose contact information is available under section
                &ldquo;Information about the responsible party (referred to as
                the &ldquo;controller&rdquo; in the GDPR)&rdquo; in this Privacy
                Policy.
              </p>{" "}
              <h4>How do we record your data?</h4>{" "}
              <p>
                We collect your data as a result of your sharing of your data
                with us. This may, for instance be information you enter into
                our contact form.
              </p>{" "}
              <p>
                Other data shall be recorded by our IT systems automatically or
                after you consent to its recording during your website visit.
                This data comprises primarily technical information (e.g., web
                browser, operating system, or time the site was accessed). This
                information is recorded automatically when you access this
                website.
              </p>{" "}
              <h4>What are the purposes we use your data for?</h4>{" "}
              <p>
                A portion of the information is generated to guarantee the error
                free provision of the website. Other data may be used to analyze
                your user patterns.
              </p>{" "}
              <h4>
                What rights do you have as far as your information is concerned?
              </h4>{" "}
              <p>
                You have the right to receive information about the source,
                recipients, and purposes of your archived personal data at any
                time without having to pay a fee for such disclosures. You also
                have the right to demand that your data are rectified or
                eradicated. If you have consented to data processing, you have
                the option to revoke this consent at any time, which shall
                affect all future data processing. Moreover, you have the right
                to demand that the processing of your data be restricted under
                certain circumstances. Furthermore, you have the right to log a
                complaint with the competent supervising agency.
              </p>{" "}
              <p>
                Please do not hesitate to contact us at any time if you have
                questions about this or any other data protection related
                issues.
              </p>
              <h3>Analysis tools and tools provided by third parties</h3>{" "}
              <p>
                There is a possibility that your browsing patterns will be
                statistically analyzed when your visit this website. Such
                analyses are performed primarily with what we refer to as
                analysis programs.
              </p>{" "}
              <p>
                For detailed information about these analysis programs please
                consult our Data Protection Declaration below.
              </p>
              <h2>2. Hosting and Content Delivery Networks (CDN)</h2>
              <p>
                We are hosting the content of our website at the following
                provider:
              </p>
              <h3>External Hosting</h3>{" "}
              <p>
                This website is hosted externally. Personal data collected on
                this website are stored on the servers of the host. These may
                include, but are not limited to, IP addresses, contact requests,
                metadata and communications, contract information, contact
                information, names, web page access, and other data generated
                through a web site.
              </p>{" "}
              <p>
                The external hosting serves the purpose of fulfilling the
                contract with our potential and existing customers (Art. 6(1)(b)
                GDPR) and in the interest of secure, fast, and efficient
                provision of our online services by a professional provider
                (Art. 6(1)(f) GDPR). If appropriate consent has been obtained,
                the processing is carried out exclusively on the basis of Art. 6
                (1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the consent
                includes the storage of cookies or the access to information in
                the user's end device (e.g., device fingerprinting) within the
                meaning of the TTDSG. This consent can be revoked at any time.
              </p>{" "}
              <p>
                Our host(s) will only process your data to the extent necessary
                to fulfil its performance obligations and to follow our
                instructions with respect to such data.
              </p>{" "}
              <p>We are using the following host(s):</p>
              <p>
                Google Cloud EMEA Ltd <br />
                70 Sir John Rogerson&#039;s Quay, Dublin 2, Dublin
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h3>Google Cloud CDN</h3>{" "}
              <p>
                We use the Google Cloud CDN content delivery network. The
                provider is Google Ireland Limited (&ldquo;Google&rdquo;),
                Gordon House, Barrow Street, Dublin 4, Ireland.
              </p>{" "}
              <p>
                Google offers a globally distributed content delivery network.
                Technically, the transfer of information between your browser
                and our website is routed through the Google network. This
                enables us to increase the global accessibility and performance
                of our website.
              </p>{" "}
              <p>
                The use of Google Cloud CDN is based on our legitimate interest
                in the most error-free and secure provision of our website (Art.
                6(1)(f) GDPR).
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://cloud.google.com/terms/eu-model-contract-clause"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/terms/eu-model-contract-clause
                </a>
                .
              </p>{" "}
              <p>
                You can find more information about Google Cloud CDN here:{" "}
                <a
                  href="https://cloud.google.com/cdn/docs/overview?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/cdn/docs/overview?hl=en
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h2>3. General information and mandatory information</h2>
              <h3>Data protection</h3>{" "}
              <p>
                The operators of this website and its pages take the protection
                of your personal data very seriously. Hence, we handle your
                personal data as confidential information and in compliance with
                the statutory data protection regulations and this Data
                Protection Declaration.
              </p>{" "}
              <p>
                Whenever you use this website, a variety of personal information
                will be collected. Personal data comprises data that can be used
                to personally identify you. This Data Protection Declaration
                explains which data we collect as well as the purposes we use
                this data for. It also explains how, and for which purpose the
                information is collected.
              </p>{" "}
              <p>
                We herewith advise you that the transmission of data via the
                Internet (i.e., through e-mail communications) may be prone to
                security gaps. It is not possible to completely protect data
                against third-party access.
              </p>
              <h3>
                Information about the responsible party (referred to as the
                &ldquo;controller&rdquo; in the GDPR)
              </h3>{" "}
              <p>The data processing controller on this website is:</p>{" "}
              <p>
                Freeze Carbon GmbH
                <br />
                co TechQuartier <br />
                Platz der Einheit 2 <br />
                60327 Frankfurt am Main
                <br />
                Germany
              </p>
              <p>
                Phone: +4915209083111
                <br />
                E-mail: support@freezecarbon.com
              </p>
              <p>
                The controller is the natural person or legal entity that
                single-handedly or jointly with others makes decisions as to the
                purposes of and resources for the processing of personal data
                (e.g., names, e-mail addresses, etc.).
              </p>
              <h3>Storage duration</h3>{" "}
              <p>
                Unless a more specific storage period has been specified in this
                privacy policy, your personal data will remain with us until the
                purpose for which it was collected no longer applies. If you
                assert a justified request for deletion or revoke your consent
                to data processing, your data will be deleted, unless we have
                other legally permissible reasons for storing your personal data
                (e.g., tax or commercial law retention periods); in the latter
                case, the deletion will take place after these reasons cease to
                apply.
              </p>
              <h3>
                General information on the legal basis for the data processing
                on this website
              </h3>{" "}
              <p>
                If you have consented to data processing, we process your
                personal data on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a)
                GDPR, if special categories of data are processed according to
                Art. 9 (1) DSGVO. In the case of explicit consent to the
                transfer of personal data to third countries, the data
                processing is also based on Art. 49 (1)(a) GDPR. If you have
                consented to the storage of cookies or to the access to
                information in your end device (e.g., via device
                fingerprinting), the data processing is additionally based on
                &sect; 25 (1) TTDSG. The consent can be revoked at any time. If
                your data is required for the fulfillment of a contract or for
                the implementation of pre-contractual measures, we process your
                data on the basis of Art. 6(1)(b) GDPR. Furthermore, if your
                data is required for the fulfillment of a legal obligation, we
                process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the
                data processing may be carried out on the basis of our
                legitimate interest according to Art. 6(1)(f) GDPR. Information
                on the relevant legal basis in each individual case is provided
                in the following paragraphs of this privacy policy.
              </p>
              <h3>
                Information on data transfer to the USA and other non-EU
                countries
              </h3>{" "}
              <p>
                Among other things, we use tools of companies domiciled in the
                United States or other from a data protection perspective
                non-secure non-EU countries. If these tools are active, your
                personal data may potentially be transferred to these non-EU
                countries and may be processed there. We must point out that in
                these countries, a data protection level that is comparable to
                that in the EU cannot be guaranteed. For instance, U.S.
                enterprises are under a mandate to release personal data to the
                security agencies and you as the data subject do not have any
                litigation options to defend yourself in court. Hence, it cannot
                be ruled out that U.S. agencies (e.g., the Secret Service) may
                process, analyze, and permanently archive your personal data for
                surveillance purposes. We have no control over these processing
                activities.
              </p>
              <h3>Revocation of your consent to the processing of data</h3>{" "}
              <p>
                A wide range of data processing transactions are possible only
                subject to your express consent. You can also revoke at any time
                any consent you have already given us. This shall be without
                prejudice to the lawfulness of any data collection that occurred
                prior to your revocation.
              </p>
              <h3>
                Right to object to the collection of data in special cases;
                right to object to direct advertising (Art. 21 GDPR)
              </h3>{" "}
              <p>
                IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART.
                6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO
                THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING
                FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING
                BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON
                WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA
                PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO
                LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A
                POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
                PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS
                AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE
                CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION
                PURSUANT TO ART. 21(1) GDPR).
              </p>{" "}
              <p>
                IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN
                DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE
                PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF
                SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO
                THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING.
                IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE
                USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART.
                21(2) GDPR).
              </p>
              <h3>
                Right to log a complaint with the competent supervisory agency
              </h3>{" "}
              <p>
                In the event of violations of the GDPR, data subjects are
                entitled to log a complaint with a supervisory agency, in
                particular in the member state where they usually maintain their
                domicile, place of work or at the place where the alleged
                violation occurred. The right to log a complaint is in effect
                regardless of any other administrative or court proceedings
                available as legal recourses.
              </p>
              <h3>Right to data portability</h3>{" "}
              <p>
                You have the right to have data that we process automatically on
                the basis of your consent or in fulfillment of a contract handed
                over to you or to a third party in a common, machine-readable
                format. If you should demand the direct transfer of the data to
                another controller, this will be done only if it is technically
                feasible.
              </p>
              <h3>Information about, rectification and eradication of data</h3>{" "}
              <p>
                Within the scope of the applicable statutory provisions, you
                have the right to demand information about your archived
                personal data, their source and recipients as well as the
                purpose of the processing of your data at any time. You may also
                have a right to have your data rectified or eradicated. If you
                have questions about this subject matter or any other questions
                about personal data, please do not hesitate to contact us at any
                time.
              </p>
              <h3>Right to demand processing restrictions</h3>{" "}
              <p>
                You have the right to demand the imposition of restrictions as
                far as the processing of your personal data is concerned. To do
                so, you may contact us at any time. The right to demand
                restriction of processing applies in the following cases:
              </p>{" "}
              <ul>
                {" "}
                <li>
                  In the event that you should dispute the correctness of your
                  data archived by us, we will usually need some time to verify
                  this claim. During the time that this investigation is
                  ongoing, you have the right to demand that we restrict the
                  processing of your personal data.
                </li>{" "}
                <li>
                  If the processing of your personal data was/is conducted in an
                  unlawful manner, you have the option to demand the restriction
                  of the processing of your data instead of demanding the
                  eradication of this data.
                </li>{" "}
                <li>
                  If we do not need your personal data any longer and you need
                  it to exercise, defend or claim legal entitlements, you have
                  the right to demand the restriction of the processing of your
                  personal data instead of its eradication.
                </li>{" "}
                <li>
                  If you have raised an objection pursuant to Art. 21(1) GDPR,
                  your rights and our rights will have to be weighed against
                  each other. As long as it has not been determined whose
                  interests prevail, you have the right to demand a restriction
                  of the processing of your personal data.
                </li>{" "}
              </ul>{" "}
              <p>
                If you have restricted the processing of your personal data,
                these data &ndash; with the exception of their archiving &ndash;
                may be processed only subject to your consent or to claim,
                exercise or defend legal entitlements or to protect the rights
                of other natural persons or legal entities or for important
                public interest reasons cited by the European Union or a member
                state of the EU.
              </p>
              <h3>SSL and/or TLS encryption</h3>{" "}
              <p>
                For security reasons and to protect the transmission of
                confidential content, such as purchase orders or inquiries you
                submit to us as the website operator, this website uses either
                an SSL or a TLS encryption program. You can recognize an
                encrypted connection by checking whether the address line of the
                browser switches from &ldquo;http://&rdquo; to
                &ldquo;https://&rdquo; and also by the appearance of the lock
                icon in the browser line.
              </p>{" "}
              <p>
                If the SSL or TLS encryption is activated, data you transmit to
                us cannot be read by third parties.
              </p>
              <h3>Encrypted payment transactions on this website</h3>{" "}
              <p>
                If you are under an obligation to share your payment information
                (e.g. account number if you give us the authority to debit your
                bank account) with us after you have entered into a fee-based
                contract with us, this information is required to process
                payments.
              </p>{" "}
              <p>
                Payment transactions using common modes of paying
                (Visa/MasterCard, debit to your bank account) are processed
                exclusively via encrypted SSL or TLS connections. You can
                recognize an encrypted connection by checking whether the
                address line of the browser switches from &ldquo;http://&rdquo;
                to &ldquo;https://&rdquo; and also by the appearance of the lock
                icon in the browser line.
              </p>{" "}
              <p>
                If the communication with us is encrypted, third parties will
                not be able to read the payment information you share with us.
              </p>
              <h3>Rejection of unsolicited e-mails</h3>{" "}
              <p>
                We herewith object to the use of contact information published
                in conjunction with the mandatory information to be provided in
                our Site Notice to send us promotional and information material
                that we have not expressly requested. The operators of this
                website and its pages reserve the express right to take legal
                action in the event of the unsolicited sending of promotional
                information, for instance via SPAM messages.
              </p>
              <h2>4. Recording of data on this website</h2>
              <h3>Cookies</h3>{" "}
              <p>
                Our websites and pages use what the industry refers to as
                &ldquo;cookies.&rdquo; Cookies are small data packages that do
                not cause any damage to your device. They are either stored
                temporarily for the duration of a session (session cookies) or
                they are permanently archived on your device (permanent
                cookies). Session cookies are automatically deleted once you
                terminate your visit. Permanent cookies remain archived on your
                device until you actively delete them, or they are automatically
                eradicated by your web browser.
              </p>{" "}
              <p>
                Cookies can be issued by us (first-party cookies) or by
                third-party companies (so-called third-party cookies).
                Third-party cookies enable the integration of certain services
                of third-party companies into websites (e.g., cookies for
                handling payment services).
              </p>{" "}
              <p>
                Cookies have a variety of functions. Many cookies are
                technically essential since certain website functions would not
                work in the absence of these cookies (e.g., the shopping cart
                function or the display of videos). Other cookies may be used to
                analyze user behavior or for promotional purposes.
              </p>{" "}
              <p>
                Cookies, which are required for the performance of electronic
                communication transactions, for the provision of certain
                functions you want to use (e.g., for the shopping cart function)
                or those that are necessary for the optimization (required
                cookies) of the website (e.g., cookies that provide measurable
                insights into the web audience), shall be stored on the basis of
                Art. 6(1)(f) GDPR, unless a different legal basis is cited. The
                operator of the website has a legitimate interest in the storage
                of required cookies to ensure the technically error-free and
                optimized provision of the operator&rsquo;s services. If your
                consent to the storage of the cookies and similar recognition
                technologies has been requested, the processing occurs
                exclusively on the basis of the consent obtained (Art. 6(1)(a)
                GDPR and &sect; 25 (1) TTDSG); this consent may be revoked at
                any time.
              </p>{" "}
              <p>
                You have the option to set up your browser in such a manner that
                you will be notified any time cookies are placed and to permit
                the acceptance of cookies only in specific cases. You may also
                exclude the acceptance of cookies in certain cases or in general
                or activate the delete-function for the automatic eradication of
                cookies when the browser closes. If cookies are deactivated, the
                functions of this website may be limited.
              </p>{" "}
              <p>
                Which cookies and services are used on this website can be found
                in this privacy policy.
              </p>
              <h3>Removing Cookies</h3>
              <p>
                To remove cookies directly from your browser,{" "}
                <a href="javascript:void(document.cookie='CookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;');">
                  click here
                </a>
                .
              </p>
              <h3>Server log files</h3>{" "}
              <p>
                The provider of this website and its pages automatically
                collects and stores information in so-called server log files,
                which your browser communicates to us automatically. The
                information comprises:
              </p>{" "}
              <ul>
                {" "}
                <li>The type and version of browser used</li>{" "}
                <li>The used operating system</li> <li>Referrer URL</li>{" "}
                <li>The hostname of the accessing computer</li>{" "}
                <li>The time of the server inquiry</li> <li>The IP address</li>{" "}
              </ul>{" "}
              <p>This data is not merged with other data sources.</p>{" "}
              <p>
                This data is recorded on the basis of Art. 6(1)(f) GDPR. The
                operator of the website has a legitimate interest in the
                technically error free depiction and the optimization of the
                operator&rsquo;s website. In order to achieve this, server log
                files must be recorded.
              </p>
              <h3>Contact form</h3>{" "}
              <p>
                If you submit inquiries to us via our contact form, the
                information provided in the contact form as well as any contact
                information provided therein will be stored by us in order to
                handle your inquiry and in the event that we have further
                questions. We will not share this information without your
                consent.
              </p>{" "}
              <p>
                The processing of these data is based on Art. 6(1)(b) GDPR, if
                your request is related to the execution of a contract or if it
                is necessary to carry out pre-contractual measures. In all other
                cases the processing is based on our legitimate interest in the
                effective processing of the requests addressed to us (Art.
                6(1)(f) GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this
                has been requested; the consent can be revoked at any time.
              </p>{" "}
              <p>
                The information you have entered into the contact form shall
                remain with us until you ask us to eradicate the data, revoke
                your consent to the archiving of data or if the purpose for
                which the information is being archived no longer exists (e.g.,
                after we have concluded our response to your inquiry). This
                shall be without prejudice to any mandatory legal provisions, in
                particular retention periods.
              </p>
              <h3>Request by e-mail, telephone, or fax</h3>{" "}
              <p>
                If you contact us by e-mail, telephone or fax, your request,
                including all resulting personal data (name, request) will be
                stored and processed by us for the purpose of processing your
                request. We do not pass these data on without your consent.
              </p>{" "}
              <p>
                These data are processed on the basis of Art. 6(1)(b) GDPR if
                your inquiry is related to the fulfillment of a contract or is
                required for the performance of pre-contractual measures. In all
                other cases, the data are processed on the basis of our
                legitimate interest in the effective handling of inquiries
                submitted to us (Art. 6(1)(f) GDPR) or on the basis of your
                consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent
                can be revoked at any time.
              </p>{" "}
              <p>
                The data sent by you to us via contact requests remain with us
                until you request us to delete, revoke your consent to the
                storage or the purpose for the data storage lapses (e.g. after
                completion of your request). Mandatory statutory provisions - in
                particular statutory retention periods - remain unaffected.
              </p>
              <h3>Google Forms</h3>{" "}
              <p>
                We have integrated Google Forms into this website. The provider
                is Google Ireland Limited, Gordon House, Barrow Street, Dublin
                4, Ireland (hereinafter referred to as &bdquo;Google&rdquo;).
              </p>{" "}
              <p>
                Google Forms enables us to generate online forms to record
                messages, inquiries and other entries entered by visitors to our
                website. All entries you make will be processed on
                Google&rsquo;s servers. Google Forms stores a cookie in your
                browser that contains a unique ID (NID cookie). This cookie
                stores a wide range of information, including, for example your
                language settings.
              </p>{" "}
              <p>
                We use Google Forms on the basis of our legitimate interest in
                determining your needs as effectively as possible (Art. 6(1)(f)
                GDPR). If appropriate consent has been obtained, the processing
                is carried out exclusively on the basis of Art. 6(1)(a) GDPR and
                &sect; 25 (1) TTDSG, insofar the consent includes the storage of
                cookies or the access to information in the user&rsquo;s end
                device (e.g., device fingerprinting) within the meaning of the
                TTDSG. This consent can be revoked at any time.
              </p>{" "}
              <p>
                The data you enter into the form will remain in our possession
                until you ask us to delete them, revoke your consent to the
                archiving of your data or until the purpose of archiving the
                data no longer exists (e.g., upon completion of the processing
                of your inquiry). This does not affect mandatory statutory
                provisions &ndash; in particular those governing retention
                periods.
              </p>{" "}
              <p>
                For more information, please consult Google&rsquo;s Data Privacy
                Policy at{" "}
                <a
                  href="https://policies.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h3>Use of Chatbots</h3>{" "}
              <p>
                We use chatbots to communicate with you. Chatbots have the
                capability to respond to your questions and other entries
                without the assistance of humans. To do this, chatbots analyze
                your entries and other data to give matching responses (e.g.,
                names, email addresses and other contact information, customer
                numbers and other identification, orders, and chat progresses).
                The chatbot can also register your IP address, log files,
                location information and other meta data. The data is archived
                on the servers of the chatbot provider.
              </p>{" "}
              <p>
                It is possible to generate user profiles based on the recorded
                data. Moreover, the data can be used to display interest-related
                advertising if the other legal requirements are met (in
                particular if consent has been obtained). Moreover, it is
                possible to link chatbots to analytical and advertising tools.
              </p>{" "}
              <p>
                The recorded data can also be used to improve our chatbots and
                their response patterns (machine learning).
              </p>{" "}
              <p>
                We or the chatbot operator retain the data you enter until you
                ask us to delete it, revoke your consent to archive it or if the
                purpose for the data storage is no longer in effect (e.g., once
                your inquiry has been fully processed). This does not affect
                mandatory statutory provisions &ndash; in particular, retention
                time frames.
              </p>{" "}
              <p>
                The legal basis for the use of chatbots is Art. 6(1)(b) GDPR, if
                the chatbot is used to negotiate a contract or in conjunction
                with the fulfillment of a contract. If appropriate consent has
                been obtained, the processing is carried out exclusively on the
                basis of Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the
                consent includes the storage of cookies or the access to
                information in the user&rsquo;s end device (e.g., device
                fingerprinting) within the meaning of the TTDSG. This consent
                can be revoked at any time. In all other cases, the use is based
                on our legitimate interest in the most effective client
                communication possible (Art. 6(1)(f) GDPR).
              </p>
              <h3>Google Calendar</h3>{" "}
              <p>
                On our website, you have the option to set up appointments with
                our company. For planning purposes, we use Google Calendar. The
                provider is Google Ireland Limited (&ldquo;Google&rdquo;),
                Gordon House, Barrow Street, Dublin 4, Ireland (hereinafter
                referred to as &ldquo;Google&rdquo;).
              </p>{" "}
              <p>
                To make reservations for an appointment, you will enter the
                requested data and your preferred meeting date into the
                dedicated screen. The data you enter will be used to plan,
                conduct, and possibly also follow up on the appointment. The
                appointment information will be stored on the servers of Google
                Calendar on our behalf. You may review the company&rsquo;s data
                protection policy here:{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy
                </a>
                .
              </p>{" "}
              <p>
                The data recorded in this manner will be stored until you ask us
                to delete them, revoke your consent to the archiving of your
                data or until the purpose of archiving the data no longer
                exists. This does not affect mandatory statutory provisions
                &ndash; in particular those governing retention periods.
              </p>{" "}
              <p>
                The legal basis for the processing of the data is Art. 6(1)(f)
                GDPR. The operator of the website has a legitimate interest in
                ensuring that appointments with customers and prospective
                customers can be scheduled as easily as possible. If appropriate
                consent has been obtained, the processing is carried out
                exclusively on the basis of Art. 6(1)(a) GDPR and &sect; 25 (1)
                TTDSG, insofar the consent includes the storage of cookies or
                the access to information in the user&rsquo;s end device (e.g.,
                device fingerprinting) within the meaning of the TTDSG. This
                consent can be revoked at any time.
              </p>{" "}
              <p>
                Data transfer to the US is based on the standard contractual
                clauses of the EU Commission. Details can be found here:{" "}
                <a
                  href="https://workspace.google.com/terms/dpa_terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://workspace.google.com/terms/dpa_terms.html
                </a>{" "}
                and{" "}
                <a
                  href="https://cloud.google.com/terms/sccs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://cloud.google.com/terms/sccs
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h3>Hubspot CRM</h3>{" "}
              <p>
                We use Hubspot CRM on this website. The provider is Hubspot Inc.
                25 Street, Cambridge, MA 02141 USA (hereafter Hubspot CRM).
              </p>{" "}
              <p>
                Hubspot CRM enables us, among other things, to manage existing
                and potential customers and customer contacts, to communicate
                with you and to plan and execute marketing activities in line
                with your interests. Hubspot CRM enables us to capture, sort and
                analyze customer interactions via email, social media, or phone
                across multiple channels. The personal data collected in this
                way can be evaluated and used for communication with the
                potential customer or marketing measures (e.g., newsletter
                mailings). Hubspot CRM also enables us to collect and analyze
                the user behavior of our contacts on our website.
              </p>{" "}
              <p>
                The use of Hubspot CRM is based on Art. 6(1)(f) GDPR. The
                website operator has a legitimate interest in the most efficient
                customer management and customer communication. If appropriate
                consent has been obtained, the processing is carried out
                exclusively on the basis of Art. 6(1)(a) GDPR and &sect; 25 (1)
                TTDSG, insofar the consent includes the storage of cookies or
                the access to information in the user&rsquo;s end device (e.g.,
                device fingerprinting) within the meaning of the TTDSG. This
                consent can be revoked at any time.
              </p>{" "}
              <p>
                For details, please refer to Hubspot&rsquo;s privacy policy:{" "}
                <a
                  href="https://legal.hubspot.com/de/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://legal.hubspot.com/de/privacy-policy
                </a>
                .
              </p>{" "}
              <p>
                Data transmission to the US is based on the standard contractual
                clauses of the EU Commission. Details can be found here:{" "}
                <a
                  href="https://www.hubspot.de/data-privacy/privacy-shield"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.hubspot.de/data-privacy/privacy-shield
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h3>Registration on this website</h3>{" "}
              <p>
                You have the option to register on this website to be able to
                use additional website functions. We shall use the data you
                enter only for the purpose of using the respective offer or
                service you have registered for. The required information we
                request at the time of registration must be entered in full.
                Otherwise, we shall reject the registration.
              </p>{" "}
              <p>
                To notify you of any important changes to the scope of our
                portfolio or in the event of technical modifications, we shall
                use the e-mail address provided during the registration process.
              </p>{" "}
              <p>
                We shall process the data entered during the registration
                process on the basis of your consent (Art. 6(1)(a) GDPR).
              </p>{" "}
              <p>
                The data recorded during the registration process shall be
                stored by us as long as you are registered on this website.
                Subsequently, such data shall be deleted. This shall be without
                prejudice to mandatory statutory retention obligations.
              </p>
              <h3>Registration with Google</h3>{" "}
              <p>
                Instead of registering directly on this website, you can
                register with Google. The provider of this service is Google
                Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow
                Street, Dublin 4, Ireland.
              </p>{" "}
              <p>
                To register with Google, you must only enter your Google name
                and password. Google will identify you and confirm your identity
                to our website.
              </p>{" "}
              <p>
                When you sign in with Google, we may be able to use certain
                information in your account to complete your profile with us.
                You decide whether you want this information to be used and if
                so, which information it is, within the framework of your Google
                security settings, which you can find here:{" "}
                <a
                  href="https://myaccount.google.com/security"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://myaccount.google.com/security
                </a>{" "}
                and{" "}
                <a
                  href="https://myaccount.google.com/permissions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://myaccount.google.com/permissions
                </a>
                .
              </p>{" "}
              <p>
                The data processing associated with Google&rsquo;s registration
                is based on our legitimate interest in making the registration
                process as simple as possible for our users (Art. 6(1)(f) GDPR).
                Since the use of the registration function is voluntary and the
                users themselves can decide on the respective access options, no
                conflicting predominant rights of the data subjects are
                apparent.
              </p>
              <h3>The comment function on this website</h3>{" "}
              <p>
                When you use the comment function on this website, information
                on the time the comment was generated and your e-mail-address
                and, if you are not posting anonymously, the username you have
                selected will be archived in addition to your comments.
              </p>
              <h4>Storage of the IP address</h4>{" "}
              <p>
                Our comment function stores the IP addresses of all users who
                enter comments. Given that we do not review the comments prior
                to publishing them, we need this information in order to take
                action against the author in the event of rights violations,
                such as defamation or propaganda.
              </p>
              <h4>Storage period for comments</h4>{" "}
              <p>
                Comments and any affiliated information shall be stored by us
                and remain on this website until the content the comment
                pertained to has been deleted in its entirety or if the comments
                had to be deleted for legal reasons (e.g., insulting comments).
              </p>
              <h4>Legal basis</h4>{" "}
              <p>
                Comments are stored on the basis of your consent (Art. 6(1)(a)
                GDPR). You have the right to revoke at any time any consent you
                have already given us. To do so, all you are required to do is
                sent us an informal notification via e-mail. This shall be
                without prejudice to the lawfulness of any data collection that
                occurred prior to your revocation.
              </p>
              <h2>5. Social media</h2>
              <h3>LinkedIn</h3>{" "}
              <p>
                This website uses elements of the LinkedIn network. The provider
                is LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
                Place, Dublin 2, Ireland.
              </p>{" "}
              <p>
                Any time you access a page of this website that contains
                elements of LinkedIn, a connection to LinkedIn&rsquo;s servers
                is established. LinkedIn is notified that you have visited this
                website with your IP address. If you click on LinkedIn&rsquo;s
                &ldquo;Recommend&rdquo; button and are logged into your LinkedIn
                account at the time, LinkedIn will be in a position to allocate
                your visit to this website to your user account. We have to
                point out that we as the provider of the websites do not have
                any knowledge of the content of the transferred data and its use
                by LinkedIn.
              </p>{" "}
              <p>
                If your approval (consent) has been obtained the use of the
                abovementioned service shall occur on the basis of Art. 6 (1)(a)
                GDPR and &sect; 25 TTDSG (German Telecommunications Act). Such
                consent may be revoked at any time. If your consent was not
                obtained, the use of the service will occur on the basis of our
                legitimate interest in making our information as comprehensively
                visible as possible on social media.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=en
                </a>
                .
              </p>{" "}
              <p>
                For further information on this subject, please consult
                LinkedIn&rsquo;s Data Privacy Declaration at:{" "}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/privacy-policy
                </a>
                .
              </p>
              <h3>XING</h3>{" "}
              <p>
                This website uses elements of the XING network. The provider is
                the New Work SE, Dammtorstra&szlig;e 30, 20354 Hamburg, Germany.
              </p>{" "}
              <p>
                Any time one of our sites/pages that contains elements of XING
                is accessed, a connection with XING&rsquo;s servers is
                established. As far as we know, this does not result in the
                archiving of any personal data. In particular, the service does
                not store any IP addresses or analyze user patterns.
              </p>{" "}
              <p>
                If your approval (consent) has been obtained the use of the
                abovementioned service shall occur on the basis of Art. 6 (1)(a)
                GDPR and &sect; 25 TTDSG (German Telecommunications Act). Such
                consent may be revoked at any time. If your consent was not
                obtained, the use of the service will occur on the basis of our
                legitimate interest in making our information as comprehensively
                visible as possible on social media.
              </p>{" "}
              <p>
                For more information on data protection and the XING share
                button please consult the Data Protection Declaration of Xing
                at:{" "}
                <a
                  href="https://www.xing.com/app/share?op=data_protection"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.xing.com/app/share?op=data_protection
                </a>
                .
              </p>
              <h2>6. Analysis tools and advertising</h2>
              <h3>Google Tag Manager</h3>{" "}
              <p>
                We use the Google Tag Manager. The provider is Google Ireland
                Limited, Gordon House, Barrow Street, Dublin 4, Ireland
              </p>{" "}
              <p>
                The Google Tag Manager is a tool that allows us to integrate
                tracking or statistical tools and other technologies on our
                website. The Google Tag Manager itself does not create any user
                profiles, does not store cookies, and does not carry out any
                independent analyses. It only manages and runs the tools
                integrated via it. However, the Google Tag Manager does collect
                your IP address, which may also be transferred to Google&rsquo;s
                parent company in the United States.
              </p>{" "}
              <p>
                The Google Tag Manager is used on the basis of Art. 6(1)(f)
                GDPR. The website operator has a legitimate interest in the
                quick and uncomplicated integration and administration of
                various tools on his website. If appropriate consent has been
                obtained, the processing is carried out exclusively on the basis
                of Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the
                consent includes the storage of cookies or the access to
                information in the user&rsquo;s end device (e.g., device
                fingerprinting) within the meaning of the TTDSG. This consent
                can be revoked at any time.
              </p>
              <h3>Google Analytics</h3>{" "}
              <p>
                This website uses functions of the web analysis service Google
                Analytics. The provider of this service is Google Ireland
                Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street,
                Dublin 4, Ireland.
              </p>{" "}
              <p>
                Google Analytics enables the website operator to analyze the
                behavior patterns of website visitors. To that end, the website
                operator receives a variety of user data, such as pages
                accessed, time spent on the page, the utilized operating system
                and the user&rsquo;s origin. This data is summarized in a
                user-ID and assigned to the respective end device of the website
                visitor.
              </p>{" "}
              <p>
                Furthermore, Google Analytics allows us to record your mouse and
                scroll movements and clicks, among other things. Google
                Analytics uses various modeling approaches to augment the
                collected data sets and uses machine learning technologies in
                data analysis.
              </p>
              <p>
                Google Analytics uses technologies that make the recognition of
                the user for the purpose of analyzing the user behavior patterns
                (e.g., cookies or device fingerprinting). The website use
                information recorded by Google is, as a rule transferred to a
                Google server in the United States, where it is stored.
              </p>{" "}
              <p>
                The use of these services occurs on the basis of your consent
                pursuant to Art. 6(1)(a) GDPR and &sect; 25(1) TTDSG. You may
                revoke your consent at any time.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://privacy.google.com/businesses/controllerterms/mccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/controllerterms/mccs/
                </a>
                .
              </p>{" "}
              <h4>Browser plug-in</h4>{" "}
              <p>
                You can prevent the recording and processing of your data by
                Google by downloading and installing the browser plugin
                available under the following link:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=en
                </a>
                .
              </p>{" "}
              <p>
                For more information about the handling of user data by Google
                Analytics, please consult Google&rsquo;s Data Privacy
                Declaration at:{" "}
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/6004245?hl=en
                </a>
                .
              </p>
              <h4>Google Signals</h4>{" "}
              <p>
                We use Google Signals. Whenever you visit our website, Google
                Analytics records, among other things, your location, the
                progression of your search and YouTube progression as well as
                demographic data (site visitor data). This data may be used for
                customized advertising with the assistance of Google Signal. If
                you have a Google account, your site visitor information will be
                linked to your Google account by Google Signal and used to send
                you customized promotional messages. The data is also used to
                compile anonymized statistics of our users&rsquo; online
                patterns.
              </p>
              <h4>Contract data processing</h4>{" "}
              <p>
                We have executed a contract data processing agreement with
                Google and are implementing the stringent provisions of the
                German data protection agencies to the fullest when using Google
                Analytics.
              </p>
              <h4>Google Analytics E-Commerce-Tracking</h4>{" "}
              <p>
                This website uses the &ldquo;E-Commerce Tracking&rdquo; function
                of Google Analytics. With the assistance of E-Commerce Tracking,
                the website operator is in a position to analyze the purchasing
                patterns of website visitors with the aim of improving the
                operator&rsquo;s online marketing campaigns. In this context,
                information, such as the orders placed, the average order
                values, shipping costs and the time from viewing the product to
                making the purchasing decision are tracked. These data may be
                consolidated by Google under a transaction ID, which is
                allocated to the respective user or the user&rsquo;s device.
              </p>
              <h3>Google Ads</h3>{" "}
              <p>
                The website operator uses Google Ads. Google Ads is an online
                promotional program of Google Ireland Limited
                (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
                Ireland.
              </p>{" "}
              <p>
                Google Ads enables us to display ads in the Google search engine
                or on third-party websites, if the user enters certain search
                terms into Google (keyword targeting). It is also possible to
                place targeted ads based on the user data Google has in its
                possession (e.g., location data and interests; target group
                targeting). As the website operator, we can analyze these data
                quantitatively, for instance by analyzing which search terms
                resulted in the display of our ads and how many ads led to
                respective clicks.
              </p>{" "}
              <p>
                The use of these services occurs on the basis of your consent
                pursuant to Art. 6(1)(a) GDPR and &sect; 25(1) TTDSG. You may
                revoke your consent at any time.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://policies.google.com/privacy/frameworks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy/frameworks
                </a>{" "}
                and{" "}
                <a
                  href="https://privacy.google.com/businesses/controllerterms/mccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/controllerterms/mccs/
                </a>
                .
              </p>
              <h3>Google Ads Remarketing</h3>{" "}
              <p>
                This website uses the functions of Google Ads Remarketing. The
                provider of these solutions is Google Ireland Limited
                (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
                Ireland.
              </p>{" "}
              <p>
                With Google Ads Remarketing, we can assign people who interact
                with our online offering to specific target groups in order to
                subsequently display interest-based advertising to them in the
                Google advertising network (remarketing or retargeting).
              </p>{" "}
              <p>
                Moreover, it is possible to link the advertising target groups
                generated with Google Ads Remarketing to device encompassing
                functions of Google. This makes it possible to display
                interest-based customized advertising messages, depending on
                your prior usage and browsing patterns on a device (e.g., cell
                phone) in a manner tailored to you as well as on any of your
                devices (e.g., tablet or PC).
              </p>{" "}
              <p>
                If you have a Google account, you have the option to object to
                personalized advertising under the following link:{" "}
                <a
                  href="https://www.google.com/settings/ads/onweb/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.google.com/settings/ads/onweb/
                </a>
                .
              </p>{" "}
              <p>
                The use of these services occurs on the basis of your consent
                pursuant to Art. 6(1)(a) GDPR and &sect; 25(1) TTDSG. You may
                revoke your consent at any time.
              </p>{" "}
              <p>
                For further information and the pertinent data protection
                regulations, please consult the Data Privacy Policies of Google
                at:{" "}
                <a
                  href="https://policies.google.com/technologies/ads?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/technologies/ads?hl=en
                </a>
                .
              </p>
              <h4>Formation of Target Groups with Customer Reconciliation</h4>{" "}
              <p>
                For the formation of target groups, we use, among other things,
                the Google Ads Remarketing customer reconciliation feature. To
                achieve this, we transfer certain customer data (e.g., email
                addresses) from our customer lists to Google. If the respective
                customers are Google users and are logged into their Google
                accounts, matching advertising messages within the Google
                network (e.g., YouTube, Gmail or in a search engine) are
                displayed for them to view.
              </p>
              <h3>LinkedIn Insight Tag</h3>{" "}
              <p>
                This website uses the Insight tag from LinkedIn. This service is
                provided by LinkedIn Ireland Unlimited Company, Wilton Plaza,
                Wilton Place, Dublin 2, Ireland.
              </p>{" "}
              <h4>Data processing by LinkedIn Insight tag</h4>{" "}
              <p>
                We use the LinkedIn Insight tag to obtain information about
                visitors to our website. Once a website visitor is registered
                with LinkedIn, we can analyze the key occupational data (e.g.,
                career level, company size, country, location, industry, job
                title) of our website visitors to help us better target our site
                to the relevant audience. We can also use LinkedIn Insight tags
                to measure whether visitors to our websites make a purchase or
                perform other actions (conversion measurement). Conversion
                measurement can also be carried out across devices (e.g. from PC
                to tablet). LinkedIn Insight Tag also features a retargeting
                function that allows us to display targeted advertising to
                visitors to our website outside of the website. According to
                LinkedIn, no identification of the advertising addressee takes
                place.
              </p>{" "}
              <p>
                LinkedIn itself also collects log files (URL, referrer URL, IP
                address, device and browser characteristics and time of access).
                The IP addresses are shortened or (if they are used to reach
                LinkedIn members across devices) hashed (pseudonymized). The
                direct identifiers of LinkedIn members are deleted by LinkedIn
                after seven days. The remaining pseudonymized data will then be
                deleted within 180 days.
              </p>{" "}
              <p>
                The data collected by LinkedIn cannot be assigned by us as a
                website operator to specific individuals. LinkedIn will store
                the personal data collected from website visitors on its servers
                in the USA and use it for its own promotional activities. For
                details, please see LinkedIn&rsquo;s privacy policy at{" "}
                <a
                  href="https://www.linkedin.com/legal/privacy-policy#choices-oblig"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/privacy-policy#choices-oblig
                </a>
                .
              </p>{" "}
              <h4>Legal basis</h4>{" "}
              <p>
                If your approval (consent) has been obtained the use of the
                abovementioned service shall occur on the basis of Art. 6(1)(a)
                GDPR and &sect; 25 TTDSG (German Telecommunications Act). Such
                consent may be revoked at any time. If your consent was not
                obtained, the use of the service will occur on the basis of Art.
                6(1)(f) GDPR; the website operator has a legitimate interest in
                effective advertising promotions that include the utilization of
                social media.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://www.linkedin.com/legal/l/dpa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/l/dpa
                </a>{" "}
                and{" "}
                <a
                  href="https://www.linkedin.com/legal/l/eu-sccs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/legal/l/eu-sccs
                </a>
                .
              </p>{" "}
              <h4>Objection to the use of LinkedIn Insight Tag</h4>{" "}
              <p>
                You can object to LinkedIn&rsquo;s analysis of user behavior and
                targeted advertising at the following link:{" "}
                <a
                  href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                </a>
                .
              </p>{" "}
              <p>
                In addition, LinkedIn members can control the use of their
                personal information for promotional purposes in the account
                settings. To prevent LinkedIn from linking information collected
                on our site to your LinkedIn account, you must log out of your
                LinkedIn account before you visit our site.
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h2>7. Newsletter</h2>
              <h3>Newsletter data</h3>{" "}
              <p>
                If you would like to receive the newsletter offered on the
                website, we require an e-mail address from you as well as
                information that allows us to verify that you are the owner of
                the e-mail address provided and that you agree to receive the
                newsletter. Further data is not collected or only on a voluntary
                basis. For the handling of the newsletter, we use newsletter
                service providers, which are described below.
              </p>
              <h3>Mailchimp</h3>{" "}
              <p>
                This website uses the services of Mailchimp to send out its
                newsletters. The provider is the Rocket Science Group LLC, 675
                Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.
              </p>{" "}
              <p>
                Among other things, Mailchimp is a service that can be deployed
                to organize and analyze the sending of newsletters. Whenever you
                enter data for the purpose of subscribing to a newsletter (e.g.
                your e-mail address), the information is stored on Mailchimp
                servers in the United States.
              </p>{" "}
              <p>
                With the assistance of the Mailchimp tool, we can analyze the
                performance of our newsletter campaigns. If you open an e-mail
                that has been sent through the Mailchimp tool, a file that has
                been integrated into the e-mail (a so-called web-beacon)
                connects to Mailchimp&rsquo;s servers in the United States. As a
                result, it can be determined whether a newsletter message has
                been opened and which links the recipient possibly clicked on.
                Technical information is also recorded at that time (e.g. the
                time of access, the IP address, type of browser and operating
                system). This information cannot be allocated to the respective
                newsletter recipient. Their sole purpose is the performance of
                statistical analyses of newsletter campaigns. The results of
                such analyses can be used to tailor future newsletters to the
                interests of their recipients more effectively.
              </p>{" "}
              <p>
                If you do not want to permit an analysis by Mailchimp, you must
                unsubscribe from the newsletter. We provide a link for you to do
                this in every newsletter message.
              </p>{" "}
              <p>
                The data is processed based on your consent (Art. 6(1)(a) GDPR).
                You may revoke any consent you have given at any time by
                unsubscribing from the newsletter. This shall be without
                prejudice to the lawfulness of any data processing transactions
                that have taken place prior to your revocation.
              </p>{" "}
              <p>
                The data deposited with us for the purpose of subscribing to the
                newsletter will be stored by us until you unsubscribe from the
                newsletter or the newsletter service provider and deleted from
                the newsletter distribution list after you unsubscribe from the
                newsletter. Data stored for other purposes with us remain
                unaffected.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://mailchimp.com/eu-us-data-transfer-statement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://mailchimp.com/eu-us-data-transfer-statement/
                </a>{" "}
                and{" "}
                <a
                  href="https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses
                </a>
                .
              </p>{" "}
              <p>
                After you unsubscribe from the newsletter distribution list,
                your e-mail address may be stored by us or the newsletter
                service provider in a blacklist, if such action is necessary to
                prevent future mailings. The data from the blacklist is used
                only for this purpose and not merged with other data. This
                serves both your interest and our interest in complying with the
                legal requirements when sending newsletters (legitimate interest
                within the meaning of Art. 6(1)(f) GDPR). The storage in the
                blacklist is indefinite.{" "}
                <strong>
                  You may object to the storage if your interests outweigh our
                  legitimate interest.
                </strong>
              </p>{" "}
              <p>
                For more details, please consult the Data Privacy Policies of
                Mailchimp at:{" "}
                <a
                  href="https://mailchimp.com/legal/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://mailchimp.com/legal/terms/
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h2>8. Plug-ins and Tools</h2>
              <h3>YouTube with expanded data protection integration</h3>{" "}
              <p>
                Our website embeds videos of the website YouTube. The website
                operator is Google Ireland Limited (&ldquo;Google&rdquo;),
                Gordon House, Barrow Street, Dublin 4, Ireland.
              </p>{" "}
              <p>
                We use YouTube in the expanded data protection mode. According
                to YouTube, this mode ensures that YouTube does not store any
                information about visitors to this website before they watch the
                video. Nevertheless, this does not necessarily mean that the
                sharing of data with YouTube partners can be ruled out as a
                result of the expanded data protection mode. For instance,
                regardless of whether you are watching a video, YouTube will
                always establish a connection with the Google DoubleClick
                network.
              </p>{" "}
              <p>
                As soon as you start to play a YouTube video on this website, a
                connection to YouTube&rsquo;s servers will be established. As a
                result, the YouTube server will be notified, which of our pages
                you have visited. If you are logged into your YouTube account
                while you visit our site, you enable YouTube to directly
                allocate your browsing patterns to your personal profile. You
                have the option to prevent this by logging out of your YouTube
                account.
              </p>{" "}
              <p>
                Furthermore, after you have started to play a video, YouTube
                will be able to place various cookies on your device or
                comparable technologies for recognition (e.g. device
                fingerprinting). In this way YouTube will be able to obtain
                information about this website&rsquo;s visitors. Among other
                things, this information will be used to generate video
                statistics with the aim of improving the user friendliness of
                the site and to prevent attempts to commit fraud.
              </p>{" "}
              <p>
                Under certain circumstances, additional data processing
                transactions may be triggered after you have started to play a
                YouTube video, which are beyond our control.
              </p>{" "}
              <p>
                The use of YouTube is based on our interest in presenting our
                online content in an appealing manner. Pursuant to Art. 6(1)(f)
                GDPR, this is a legitimate interest. If appropriate consent has
                been obtained, the processing is carried out exclusively on the
                basis of Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the
                consent includes the storage of cookies or the access to
                information in the user&rsquo;s end device (e.g., device
                fingerprinting) within the meaning of the TTDSG. This consent
                can be revoked at any time.
              </p>{" "}
              <p>
                For more information on how YouTube handles user data, please
                consult the YouTube Data Privacy Policy under:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <h3>Vimeo</h3>{" "}
              <p>
                This website uses plug-ins of the video portal Vimeo. The
                provider is Vimeo Inc., 555 West 18th Street, New York, New York
                10011, USA.
              </p>{" "}
              <p>
                If you visit one of the pages on our website into which a Vimeo
                video has been integrated, a connection to Vimeo&rsquo;s servers
                will be established. As a consequence, the Vimeo server will
                receive information as to which of our pages you have visited.
                Moreover, Vimeo will receive your IP address. This will also
                happen if you are not logged into Vimeo or do not have an
                account with Vimeo. The information recorded by Vimeo will be
                transmitted to Vimeo&rsquo;s server in the United States.
              </p>{" "}
              <p>
                If you are logged into your Vimeo account, you enable Vimeo to
                directly allocate your browsing patterns to your personal
                profile. You can prevent this by logging out of your Vimeo
                account.
              </p>{" "}
              <p>
                Vimeo uses cookies or comparable recognition technologies (e.g.
                device fingerprinting) to recognize website visitors.
              </p>{" "}
              <p>
                The use of Vimeo is based on our interest in presenting our
                online content in an appealing manner. Pursuant to Art. 6(1)(f)
                GDPR, this is a legitimate interest. If appropriate consent has
                been obtained, the processing is carried out exclusively on the
                basis of Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the
                consent includes the storage of cookies or the access to
                information in the user&rsquo;s end device (e.g., device
                fingerprinting) within the meaning of the TTDSG. This consent
                can be revoked at any time.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission and, according to
                Vimeo, on &ldquo;legitimate business interests&rdquo;. Details
                can be found here:{" "}
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vimeo.com/privacy
                </a>
                .
              </p>{" "}
              <p>
                For more information on how Vimeo handles user data, please
                consult the Vimeo Data Privacy Policy under:{" "}
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://vimeo.com/privacy
                </a>
                .
              </p>
              <h3>Google Fonts (local embedding)</h3>{" "}
              <p>
                This website uses so-called Google Fonts provided by Google to
                ensure the uniform use of fonts on this site. These Google fonts
                are locally installed so that a connection to Google&rsquo;s
                servers will not be established in conjunction with this
                application.
              </p>{" "}
              <p>
                For more information on Google Fonts, please follow this link:{" "}
                <a
                  href="https://developers.google.com/fonts/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://developers.google.com/fonts/faq
                </a>{" "}
                and consult Google&rsquo;s Data Privacy Declaration under:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <h3>Adobe Fonts</h3>{" "}
              <p>
                In order to ensure the uniform depiction of certain fonts, this
                website uses fonts called Adobe Fonts provided by Adobe Systems
                Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA
                (Adobe).
              </p>{" "}
              <p>
                When you access pages of this website, your browser will
                automatically load the required fonts directly from the Adobe
                site to be able to display them correctly on your device. As a
                result, your browser will establish a connection with
                Adobe&rsquo;s servers in the United States. Hence, Adobe learns
                that your IP address was used to access this website. According
                to the information provided by Adobe, no cookies will be stored
                in conjunction with the provision of the fonts.
              </p>{" "}
              <p>
                Data are stored and analyzed on the basis of Art. 6(1)(f) GDPR.
                The website operator has a legitimate interest in a uniform
                presentation of the font on the operator&rsquo;s website. If
                appropriate consent has been obtained, the processing is carried
                out exclusively on the basis of Art. 6(1)(a) GDPR and &sect; 25
                (1) TTDSG, insofar the consent includes the storage of cookies
                or the access to information in the user&rsquo;s end device
                (e.g., device fingerprinting) within the meaning of the TTDSG.
                This consent can be revoked at any time.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://www.adobe.com/de/privacy/eudatatransfers.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.adobe.com/de/privacy/eudatatransfers.html
                </a>
                .
              </p>{" "}
              <p>
                For more information about Adobe Fonts, please read the policies
                under:{" "}
                <a
                  href="https://www.adobe.com/privacy/policies/adobe-fonts.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.adobe.com/privacy/policies/adobe-fonts.html
                </a>
                .
              </p>{" "}
              <p>
                Adobe&rsquo;s Data Privacy Declaration may be reviewed under:{" "}
                <a
                  href="https://www.adobe.com/privacy/policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.adobe.com/privacy/policy.html
                </a>
                .
              </p>
              <h3>Google Maps</h3>{" "}
              <p>
                This website uses the mapping service Google Maps. The provider
                is Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House,
                Barrow Street, Dublin 4, Ireland.
              </p>{" "}
              <p>
                To enable the use of the Google Maps features, your IP address
                must be stored. As a rule, this information is transferred to
                one of Google&rsquo;s servers in the United States, where it is
                archived. The operator of this website has no control over the
                data transfer. In case Google Maps has been activated, Google
                has the option to use Google Fonts for the purpose of the
                uniform depiction of fonts. When you access Google Maps, your
                browser will load the required web fonts into your browser
                cache, to correctly display text and fonts.
              </p>{" "}
              <p>
                We use Google Maps to present our online content in an appealing
                manner and to make the locations disclosed on our website easy
                to find. This constitutes a legitimate interest as defined in
                Art. 6(1)(f) GDPR. If appropriate consent has been obtained, the
                processing is carried out exclusively on the basis of Art.
                6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the consent
                includes the storage of cookies or the access to information in
                the user&rsquo;s end device (e.g., device fingerprinting) within
                the meaning of the TTDSG. This consent can be revoked at any
                time.
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/gdprcontrollerterms/
                </a>{" "}
                and{" "}
                <a
                  href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
                </a>
                .
              </p>{" "}
              <p>
                For more information on the handling of user data, please review
                Google&rsquo;s Data Privacy Declaration under:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <h3>Google reCAPTCHA</h3>{" "}
              <p>
                We use &ldquo;Google reCAPTCHA&rdquo; (hereinafter referred to
                as &ldquo;reCAPTCHA&rdquo;) on this website. The provider is
                Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House,
                Barrow Street, Dublin 4, Ireland.
              </p>{" "}
              <p>
                The purpose of reCAPTCHA is to determine whether data entered on
                this website (e.g., information entered into a contact form) is
                being provided by a human user or by an automated program. To
                determine this, reCAPTCHA analyzes the behavior of the website
                visitors based on a variety of parameters. This analysis is
                triggered automatically as soon as the website visitor enters
                the site. For this analysis, reCAPTCHA evaluates a variety of
                data (e.g., IP address, time the website visitor spent on the
                site or cursor movements initiated by the user). The data
                tracked during such analyses are forwarded to Google.
              </p>{" "}
              <p>
                reCAPTCHA analyses run entirely in the background. Website
                visitors are not alerted that an analysis is underway.
              </p>{" "}
              <p>
                Data are stored and analyzed on the basis of Art. 6(1)(f) GDPR.
                The website operator has a legitimate interest in the protection
                of the operator&rsquo;s websites against abusive automated
                spying and against SPAM. If appropriate consent has been
                obtained, the processing is carried out exclusively on the basis
                of Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the
                consent includes the storage of cookies or the access to
                information in the user&rsquo;s end device (e.g., device
                fingerprinting) within the meaning of the TTDSG. This consent
                can be revoked at any time.
              </p>{" "}
              <p>
                For more information about Google reCAPTCHA please refer to the
                Google Data Privacy Declaration and Terms Of Use under the
                following links:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>{" "}
                and{" "}
                <a
                  href="https://policies.google.com/terms?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/terms?hl=en
                </a>
                .
              </p>
              <h2>9. eCommerce and payment service providers</h2>
              <h3>Processing of Customer and Contract Data</h3>{" "}
              <p>
                We collect, process, and use personal customer and contract data
                for the establishment, content arrangement and modification of
                our contractual relationships. Data with personal references to
                the use of this website (usage data) will be collected,
                processed, and used only if this is necessary to enable the user
                to use our services or required for billing purposes. The legal
                basis for these processes is Art. 6(1)(b) GDPR.
              </p>{" "}
              <p>
                The collected customer data shall be deleted upon completion of
                the order or termination of the business relationship and upon
                expiration of any existing statutory archiving periods. This
                shall be without prejudice to any statutory archiving periods.
              </p>
              <h3>
                Data transfer upon closing of contracts for online stores,
                retailers, and the shipment of merchandise
              </h3>{" "}
              <p>
                Whenever you order merchandise from us, we will share your
                personal data with the transportation company entrusted with the
                delivery as well as the payment service commissioned to handle
                the payment transactions. Only the data these respective service
                providers require to meet their obligations will be shared. The
                legal basis for this sharing is Art. 6 (1)(b) GDPR, which
                permits the processing of data for the fulfillment of
                contractual or pre-contractual obligations. If you give us your
                respective consent pursuant to Art. 6 (1)(a) GDPR, we will share
                your email address with the transportation company entrusted
                with the delivery so that this company can notify you on the
                shipping status for your order via email. You have the option to
                revoke your consent at any time.
              </p>
              <h3>
                Data transfer upon closing of contracts for services and digital
                content
              </h3>{" "}
              <p>
                We share personal data with third parties only if this is
                necessary in conjunction with the handling of the contract; for
                instance, with the financial institution tasked with the
                processing of payments.
              </p>{" "}
              <p>
                Any further transfer of data shall not occur or shall only occur
                if you have expressly consented to the transfer. Any sharing of
                your data with third parties in the absence of your express
                consent, for instance for advertising purposes, shall not occur.
              </p>{" "}
              <p>
                The basis for the processing of data is Art. 6(1)(b) GDPR, which
                permits the processing of data for the fulfilment of a contract
                or for pre-contractual actions.
              </p>
              <h3>Payment services</h3>{" "}
              <p>
                We integrate payment services of third-party companies on our
                website. When you make a purchase from us, your payment data
                (e.g. name, payment amount, bank account details, credit card
                number) are processed by the payment service provider for the
                purpose of payment processing. For these transactions, the
                respective contractual and data protection provisions of the
                respective providers apply. The use of the payment service
                providers is based on Art. 6(1)(b) GDPR (contract processing)
                and in the interest of a smooth, convenient, and secure payment
                transaction (Art. 6(1)(f) GDPR). Insofar as your consent is
                requested for certain actions, Art. 6(1)(a) GDPR is the legal
                basis for data processing; consent may be revoked at any time
                for the future.
              </p>
              <p>
                We use the following payment services / payment service
                providers within the scope of this website:
              </p>
              <h4>Stripe</h4>{" "}
              <p>
                The provider for customers within the EU is Stripe Payments
                Europe, Ltd,1 Grand Canal Street Lower, Grand Canal Dock,
                Dublin, Ireland (hereinafter &ldquo;Stripe&rdquo;).
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://stripe.com/de/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/de/privacy
                </a>{" "}
                and{" "}
                <a
                  href="https://stripe.com/de/guides/general-data-protection-regulation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/de/guides/general-data-protection-regulation
                </a>
                .
              </p>{" "}
              <p>
                Details can be found in Stripe&rsquo;s Privacy Policy at the
                following link:{" "}
                <a
                  href="https://stripe.com/de/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://stripe.com/de/privacy
                </a>
                .
              </p>
              <h2>
                10. Online-based Audio and Video Conferences (Conference tools)
              </h2>
              <h4>Data processing</h4>{" "}
              <p>
                We use online conference tools, among other things, for
                communication with our customers. The tools we use are listed in
                detail below. If you communicate with us by video or audio
                conference using the Internet, your personal data will be
                collected and processed by the provider of the respective
                conference tool and by us. The conferencing tools collect all
                information that you provide/access to use the tools (email
                address and/or your phone number). Furthermore, the conference
                tools process the duration of the conference, start and end
                (time) of participation in the conference, number of
                participants and other &ldquo;context information&rdquo; related
                to the communication process (metadata).
              </p>{" "}
              <p>
                Furthermore, the provider of the tool processes all the
                technical data required for the processing of the online
                communication. This includes, in particular, IP addresses, MAC
                addresses, device IDs, device type, operating system type and
                version, client version, camera type, microphone or loudspeaker
                and the type of connection.
              </p>{" "}
              <p>
                Should content be exchanged, uploaded, or otherwise made
                available within the tool, it is also stored on the servers of
                the tool provider. Such content includes, but is not limited to,
                cloud recordings, chat/ instant messages, voicemail uploaded
                photos and videos, files, whiteboards, and other information
                shared while using the service.
              </p>{" "}
              <p>
                Please note that we do not have complete influence on the data
                processing procedures of the tools used. Our possibilities are
                largely determined by the corporate policy of the respective
                provider. Further information on data processing by the
                conference tools can be found in the data protection
                declarations of the tools used, and which we have listed below
                this text.
              </p>{" "}
              <h4>Purpose and legal bases</h4>{" "}
              <p>
                The conference tools are used to communicate with prospective or
                existing contractual partners or to offer certain services to
                our customers (Art. 6(1)(b) GDPR). Furthermore, the use of the
                tools serves to generally simplify and accelerate communication
                with us or our company (legitimate interest in the meaning of
                Art. 6(1)(f) GDPR). Insofar as consent has been requested, the
                tools in question will be used on the basis of this consent; the
                consent may be revoked at any time with effect from that date.
              </p>{" "}
              <h4>Duration of storage</h4>{" "}
              <p>
                Data collected directly by us via the video and conference tools
                will be deleted from our systems immediately after you request
                us to delete it, revoke your consent to storage, or the reason
                for storing the data no longer applies. Stored cookies remain on
                your end device until you delete them. Mandatory legal retention
                periods remain unaffected.
              </p>{" "}
              <p>
                We have no influence on the duration of storage of your data
                that is stored by the operators of the conference tools for
                their own purposes. For details, please directly contact the
                operators of the conference tools.
              </p>{" "}
              <h4>Conference tools used</h4>{" "}
              <p>We employ the following conference tools:</p>
              <h3>Zoom</h3>{" "}
              <p>
                We use Zoom. The provider of this service is Zoom Communications
                Inc, San Jose, 55 Almaden Boulevard, 6th Floor, San Jose, CA
                95113, USA. For details on data processing, please refer to
                Zoom&rsquo;s privacy policy:{" "}
                <a
                  href="https://zoom.us/en-us/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://zoom.us/en-us/privacy.html
                </a>
                .
              </p>{" "}
              <p>
                Data transmission to the US is based on the Standard Contractual
                Clauses (SCC) of the European Commission. Details can be found
                here:{" "}
                <a
                  href="https://zoom.us/de-de/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://zoom.us/de-de/privacy.html
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h3>Google Meet</h3>{" "}
              <p>
                We use Google Meet. The provider is Google Ireland Limited,
                Gordon House, Barrow Street, Dublin 4, Ireland. For details on
                data processing, please see the Google privacy policy:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
              <h2>11. Custom Services</h2>
              <h3>Handling applicant data</h3>{" "}
              <p>
                We offer website visitors the opportunity to submit job
                applications to us (e.g., via e-mail, via postal services on by
                submitting the online job application form). Below, we will
                brief you on the scope, purpose and use of the personal data
                collected from you in conjunction with the application process.
                We assure you that the collection, processing, and use of your
                data will occur in compliance with the applicable data privacy
                rights and all other statutory provisions and that your data
                will always be treated as strictly confidential.
              </p>{" "}
              <h4>Scope and purpose of the collection of data</h4>{" "}
              <p>
                If you submit a job application to us, we will process any
                affiliated personal data (e.g., contact and communications data,
                application documents, notes taken during job interviews, etc.),
                if they are required to make a decision concerning the
                establishment or an employment relationship. The legal grounds
                for the aforementioned are &sect; 26 BDSG according to German
                Law (Negotiation of an Employment Relationship), Art. 6(1)(b)
                GDPR (General Contract Negotiations) and &ndash; provided you
                have given us your consent &ndash; Art. 6(1)(a) GDPR. You may
                revoke any consent given at any time. Within our company, your
                personal data will only be shared with individuals who are
                involved in the processing of your job application.
              </p>{" "}
              <p>
                If your job application should result in your recruitment, the
                data you have submitted will be archived on the grounds of
                &sect; 26 BDSG and Art. 6(1)(b) GDPR for the purpose of
                implementing the employment relationship in our data processing
                system.
              </p>{" "}
              <h4>Data Archiving Period</h4>{" "}
              <p>
                If we are unable to make you a job offer or you reject a job
                offer or withdraw your application, we reserve the right to
                retain the data you have submitted on the basis of our
                legitimate interests (Art. 6(1)(f) GDPR) for up to 6 months from
                the end of the application procedure (rejection or withdrawal of
                the application). Afterwards the data will be deleted, and the
                physical application documents will be destroyed. The storage
                serves in particular as evidence in the event of a legal
                dispute. If it is evident that the data will be required after
                the expiry of the 6-month period (e.g., due to an impending or
                pending legal dispute), deletion will only take place when the
                purpose for further storage no longer applies.
              </p>{" "}
              <p>
                Longer storage may also take place if you have given your
                agreement (Article 6(1)(a) GDPR) or if statutory data retention
                requirements preclude the deletion.
              </p>
              <h3>Google Drive</h3>{" "}
              <p>
                We have integrated Google Drive on this website. The provider is
                Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House,
                Barrow Street, Dublin 4, Ireland.
              </p>{" "}
              <p>
                Google Drive allows us to include an upload area on our website
                where you can upload content. When you upload content, it is
                stored on Google Drive&rsquo;s servers. When you visit our
                website, a connection to Google Drive is additionally
                established so that Google Drive can determine that you have
                visited our website.
              </p>{" "}
              <p>
                The use of Google Drive is based on Art. 6(1)(f) GDPR. The
                website operator has a legitimate interest in having a reliable
                upload area on its website. If a corresponding consent has been
                obtained, the processing is carried out exclusively on the basis
                of Art. 6(1)(a) GDPR; the consent can be revoked at any time.
              </p>
              <h4>Data processing</h4>{" "}
              <p>
                We have concluded a data processing agreement (DPA) for the use
                of the above-mentioned service. This is a contract mandated by
                data privacy laws that guarantees that they process personal
                data of our website visitors only based on our instructions and
                in compliance with the GDPR.
              </p>
            </Stack>
          </MainCard>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default DataPrivacy;
