import axios from "axios";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export class GenericBackend {
  //   static authenticateUser = async () => {
  //     const user = firebase.auth().currentUser;
  //     if (user) {
  //       const idToken = await user.getIdToken(/* forceRefresh */ true);
  //       const token = `Bearer ${idToken}`;
  //       return token;
  //     } else {
  //       throw new Error("User not authenticated.");
  //     }
  //   };

  static post = async (url, data, token, publicURL = false) => {
    // let token = "";
    // if (!publicURL) token = await GenericBackend.authenticateUser();

    const response = await axios.post(url + "", data, {
      withCredentials: true,
      headers: {
        authorization: token,
      },
    });

    return response;
  };

  static postv2 = async (url, data, token, publicURL = false) => {
    // let token = "";
    // if (!publicURL) token = await GenericBackend.authenticateUser();

    try {
      const response = await axios.post(url + "", data, {
        withCredentials: true,
        headers: {
          authorization: token,
        },
      });

      return response;
    } catch (error: any) {
      console.log(error);
      return error.response;
    }
  };
}
