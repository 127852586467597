import React from "react";
import NavBar from "../components/NavBar";
import learning from "../../../assets/images/new-icons/learning.svg";
import blog from "../../../assets/images/new-icons/blog.svg";
import { Link as RouterLink } from "react-router-dom";
import Footer from "../components/Footer";
import { FormattedMessage } from "react-intl";

const Knowledge = () => {
  return (
    <div>
      <NavBar />
      <div className="knwoledge_container">
        <div className="knwoledge_container_learning">
          <div className="knwoledge_container_learning_image">
            <img src={learning} className="image_width" />
          </div>
          <div className="knwoledge_container_learning_data">
            <div className="knwoledge_container_learning_data_heading">
              <FormattedMessage
                id="learning_academy"
                defaultMessage="Learning academy"
              />
            </div>
            <div className="knwoledge_container_learning_data_description">
              <RouterLink
                to="/learn"
                className="homepage_appbar_nav_get_started"
              >
                <FormattedMessage id="learn" />
              </RouterLink>
            </div>
          </div>
        </div>
        <div className="knwoledge_container_learning">
          <div className="knwoledge_container_learning_image">
            <img src={blog} className="image_width" />
          </div>
          <div className="knwoledge_container_learning_data">
            <div className="knwoledge_container_learning_data_heading">
              Blog
            </div>
            <div className="knwoledge_container_learning_data_description">
              <a
                className="homepage_appbar_nav_get_started"
                href="https://blog.freezecarbon.com/"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FormattedMessage id="learn" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Knowledge;
