import React from "react";
import design from "../../../assets/images/new-icons/design.svg";
import together from "../../../assets/images/new-icons/together.svg";
import honest from "../../../assets/images/new-icons/honest.svg";
import real from "../../../assets/images/new-icons/real.svg";

import job from "../../../assets/images/new-icons/job.svg";
import pccs from "../../../assets/images/new-icons/pccs.svg";
import { Link as RouterLink } from "react-router-dom";
import logo from "assets/images/logo.png";
import LinkedIn from "@mui/icons-material/LinkedIn";

import thorsten from "../assets/images/profile/thorsten.jpg";

import {
  IconArrowRight,
  IconCalendarStats,
  IconMail,
  IconMessage2,
  IconPhone,
} from "@tabler/icons";
import { FormattedMessage } from "react-intl";

const ThorstenCard = () => {
  return (
    <div className="mission_projects">
      <div className="homepage_project_heading">
        <div className="homepage_project_heading_content">
          <FormattedMessage id="contactTitle" defaultMessage="Contact" />
        </div>
      </div>
      <div>
        <div
          className="homepage_project_heading_data_content"
          style={{ fontSize: "1.5em" }}
        >
          <FormattedMessage
            id="contactHeading"
            defaultMessage="Do you have any questions?"
          />
        </div>
      </div>
      <div
        className="homepage_project_container_types our_team_row"
        style={{
          justifyContent: "center",
        }}
      >
        <div className="homepage_project_container_types_row">
          <div className="homepage_project_container_types_row_column  contact_us_row_column">
            <div className="our_team_member_photo">
              <img
                src={thorsten}
                className="our_team_member_photo_image contact_us_photo_image"
              />
            </div>
            <div className="member_details contact_us_details">
              <div className="member_name">Thorsten Ratzlaff </div>
              {/* <div className="member_position">CEO & Co-Founder</div> */}
              <a
                href="mailto:thorsten.ratzlaff@freezecarbon.com"
                className="contact_us_email contact_us_email_address"
              >
                <IconMail /> thorsten.ratzlaff@freezecarbon.com
              </a>
              <a
                href="tel:+4915209083111"
                className="contact_us_email contact_us_phone"
              >
                <IconPhone /> +49 152 0908 3111
              </a>
              <RouterLink
                className="contact_us_email contact_us_hubspot"
                to={{
                  pathname:
                    "https://meetings-eu1.hubspot.com/thorsten-ratzlaff/vorstellung",
                }}
                target="_blank"
              >
                <IconCalendarStats />{" "}
                <FormattedMessage
                  id="OurProjects_title_book"
                  defaultMessage="Book an intro call"
                />
              </RouterLink>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThorstenCard;
