// material-ui
import { useTheme } from "@mui/material/styles";
import { Container, Grid, Typography, Stack, CardMedia } from "@mui/material";

// project imports
import SubCard from "ui-component/cards/SubCard";
import Avatar from "ui-component/extended/Avatar";
import { Link as RouterLink } from "react-router-dom";

// assets
import Offer1 from "assets/images/fact_sheets/DAC.png";
import Offer1DE from "assets/images/fact_sheets/dacde.png";
import Offer2 from "assets/images/fact_sheets/BIOCHAR.png";
import Offer2DE from "assets/images/fact_sheets/Biokohle.png";
import Offer3 from "assets/images/fact_sheets/erw.png";
import Offer3DE from "assets/images/fact_sheets/erwde.png";
import Offer4 from "assets/images/fact_sheets/cc.png";
import Offer4DE from "assets/images/fact_sheets/ccde.png";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { getLocales } from "../../../react-native-localize";
import { width } from "@mui/system";

// OfferCard Component
interface OfferCardProps {
  image: string;
  target: string;
  titleId: string;
}

// retry

const OfferCard = ({ image, target, titleId }: OfferCardProps) => {
  const theme = useTheme();
  const AvatarSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: "100%",
    height: "100%",
  };

  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
        width: "100%",
      }}
    >
      <Stack spacing={4} style={{ width: "100%" }}>
        <Avatar variant="rounded" sx={AvatarSx}>
          <a
            href={target}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Stack spacing={2} sx={{ marginLeft: "5px", marginBottom: "10px" }}>
              <Typography variant="h4">
                <FormattedMessage
                  id={titleId}
                  defaultMessage="Carbon credits"
                />
              </Typography>
            </Stack>
            <CardMedia
              component="img"
              src={image}
              style={{
                height: "250px",
                width: "320px",
                marginBottom: "1em",
                objectPosition: "top",
              }}
            />
          </a>
        </Avatar>
      </Stack>
    </SubCard>
  );
};

// FactSheets Component
const FactSheets = () => {
  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;

  // Determine language to use
  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const selectedItem =
    sessionStorage.getItem("browserLanguage") || languageToUse;
  const [language, setLanguage] = useState(selectedItem);

  // Define images and links based on the language
  const factSheets1 = [
    {
      en: {
        image: Offer1,
        target: "https://landing.freezecarbon.com/dac/en",
      },
      de: {
        image: Offer1DE,
        target: "https://landing.freezecarbon.com/dac/de",
      },
      titleId: "factsheet1",
    },
    {
      en: { image: Offer2, target: "https://landing.freezecarbon.com/biochar" },
      de: {
        image: Offer2DE,
        target: "https://landing.freezecarbon.com/biokohle",
      },
      titleId: "factsheet2",
    },
    {
      en: {
        image: Offer3,
        target: "https://landing.freezecarbon.com/enhanced-rock-weathering",
      },
      de: {
        image: Offer3DE,
        target: "https://landing.freezecarbon.com/gesteinsmehl",
      },
      titleId: "factsheet3",
    },
  ];

  const factSheets2 = [
    {
      en: {
        image: Offer4,
        target: "https://landing.freezecarbon.com/climate-contribution",
      },
      de: {
        image: Offer4DE,
        target: "https://landing.freezecarbon.com/klimabeitrag",
      },
      titleId: "factsheet4",
    },
  ];

  return (
    <div>
      <NavBar />
      <div className="homepage_project_container">
        <div className="homepage_project_heading_data_content">
          <div className="homepage_project_heading_data_content_value">
            <FormattedMessage
              values={{ break: <br /> }}
              id="sheet"
              defaultMessage=""
            />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
          style={{ marginBottom: "0.8em", textTransform: "none" }}
        >
          <FormattedMessage id="click_download" />
        </div>
      </div>

      <div
        style={{
          margin: "2em 0",
        }}
      >
        <div
          className="homepage_project_header_container"
          style={{ margin: "2em 0px 1em 0" }}
        >
          <div className="homepage_project_heading navbar_margin_zero">
            <div className="homepage_project_heading_content">
              <FormattedMessage
                id="factsheets1Heading"
                defaultMessage="The Extra Mile in Quality"
              />
            </div>
          </div>
        </div>

        <div
          className="services_banner_container row-reverse services_banner_container_desktop factSheet_container"
          id="quality-analysis"
        >
          <span
            className="bullet-text"
            style={{ maxWidth: "800px", textAlign: "center" }}
          >
            {" "}
            <FormattedMessage
              id="factsheetProject"
              defaultMessage="First, we define which project types provide the highest safeguards and most accurate climate impact calculation. Additionally, we look for projects contributing to the United Nations 17 Social Development Goals and support these beyond a climate impact."
            />
          </span>
        </div>
        <Container>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-start"
                spacing={2}
                sx={{ "&> .MuiGrid-root > div": { height: "100%" } }}
              >
                {factSheets1.map((sheet, index) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    key={index}
                    style={{ width: "100%" }}
                  >
                    <OfferCard
                      image={
                        language === "de-DE" ? sheet.de.image : sheet.en.image
                      }
                      target={
                        language === "de-DE" ? sheet.de.target : sheet.en.target
                      }
                      titleId={sheet.titleId}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          margin: "3em 0",
        }}
      >
        <div
          className="homepage_project_header_container"
          style={{ margin: "4em 0px 1em 0" }}
        >
          <div className="homepage_project_heading navbar_margin_zero">
            <div className="homepage_project_heading_content">
              <FormattedMessage
                id="factsheets2Heading"
                defaultMessage="The Extra Mile in Quality"
              />
            </div>
          </div>
        </div>

        <div
          className="services_banner_container row-reverse services_banner_container_desktop factSheet_container"
          id="quality-analysis"
        >
          <span
            className="bullet-text"
            style={{ maxWidth: "800px", textAlign: "center" }}
          >
            {" "}
            <FormattedMessage
              id="factsheetStart"
              defaultMessage="First, we define which project types provide the highest safeguards and most accurate climate impact calculation. Additionally, we look for projects contributing to the United Nations 17 Social Development Goals and support these beyond a climate impact."
            />
          </span>
        </div>
        <Container>
          <Grid container spacing={7.5} justifyContent="center">
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-start"
                spacing={2}
                sx={{
                  "&> .MuiGrid-root > div": { height: "100%" },
                }}
              >
                {factSheets2.map((sheet, index) => (
                  <Grid item md={4} sm={6} key={index}>
                    <OfferCard
                      image={
                        language === "de-DE" ? sheet.de.image : sheet.en.image
                      }
                      target={
                        language === "de-DE" ? sheet.de.target : sheet.en.target
                      }
                      titleId={sheet.titleId}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default FactSheets;
