import React, { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import biochar from "../assets/images/new-icons/biochar.svg"; // Replace with your actual paths
import erw from "../assets/images/new-icons/erw.svg";
import dac from "../assets/images/new-icons/dac.svg";
import mg from "../assets/images/new-icons/mg.svg";
import sdw from "../assets/images/new-icons/Biooil.svg";
import ee from "../assets/images/new-icons/ee.svg";
import om from "../assets/images/new-icons/om.svg";
import ReactCountryFlag from "react-country-flag";

const ProjectScroller = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Duplicate the projects to create the seamless infinite effect
  // useEffect(() => {
  //   const container = containerRef.current;
  //   if (container) {
  //     const originalContent = container.innerHTML; // Save original content
  //     container.innerHTML += originalContent; // Duplicate content for seamless scroll
  //     container.innerHTML += originalContent; // Duplicate content for seamless scroll
  //     container.innerHTML += originalContent; // Duplicate content for seamless scroll
  //     container.innerHTML += originalContent; // Duplicate content for seamless scroll
  //     container.innerHTML += originalContent; // Duplicate content for seamless scroll
  //   }
  // }, []);

  // Define the project data in an array of objects
  const projects = [
    {
      image: biochar,
      alt: "Biochar",
      title: <FormattedMessage id="biochar" defaultMessage="Biochar" />,
      countries: [
        "NA", // Namibia
        "IN", // India
        "KE", // Kenya
        "TH", // Thailand
        "AT", // Austria
        "DE", // Germany
        "PH", // Philippines
        "US",
      ], // Example country codes
    },
    {
      image: erw,
      alt: "Enhanced Rock Weathering",
      title: (
        <FormattedMessage id="erw" defaultMessage="Enhanced Rock Weathering" />
      ),
      countries: ["BR", "GB", "SR"],
    },
    {
      image: dac,
      alt: "DAC",
      title: <FormattedMessage id="dac" defaultMessage="DAC" />,
      countries: ["KE", "US", "GB", "DE"],
    },
    {
      image: mg,
      alt: "Methane Gas",
      title: <FormattedMessage id="methane_gas" defaultMessage="Methane Gas" />,
      countries: ["KE", "ID", "KH", "UG"],
    },
    {
      image: sdw,
      alt: "Biofuels",
      title: (
        <FormattedMessage id="safe_drinking_water" defaultMessage="Biofuels" />
      ),
      countries: ["KE"],
    },
    {
      image: ee,
      alt: "Energy Efficiency",
      title: (
        <FormattedMessage
          id="energy_efficiency"
          defaultMessage="Energy Efficiency"
        />
      ),
      countries: ["BR"],
    },
    // {
    //   image: om,
    //   alt: "Ocean Mineralization",
    //   title: (
    //     <FormattedMessage
    //       id="ocean_mineralization"
    //       defaultMessage="Ocean Mineralization"
    //     />
    //   ),
    //   countries: [],
    // },
  ];

  // Sort the projects alphabetically by the alt text
  const sortedProjects = projects.sort((a, b) => a.alt.localeCompare(b.alt));

  return (
    <div className="scroller_tab">
      <div className="project-scroller">
        <div className="project-scroller-track" ref={containerRef}>
          {sortedProjects.map((project, index) => (
            <div
              className="project homepage_project_container_types_row_column"
              key={index}
            >
              <div className="project_image">
                <img src={project.image} alt={project.alt} />

                <div className="flags">
                  {project.countries.map((countryCode, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <ReactCountryFlag
                        countryCode={countryCode}
                        svg
                        style={{
                          width: "2em",
                          height: "2em",
                          marginRight: "2px",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="project_title homepage_project_container_types_row_column_title">
                {project.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectScroller;
