import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import { Link as RouterLink } from "react-router-dom";
import biochar from "../../../assets/images/new-icons/biochar.svg";
import erw from "../../../assets/images/new-icons/erw.svg";
import bb from "../../../assets/images/new-icons/bb.svg";
import dac from "../../../assets/images/new-icons/dac.svg";
import ee from "../../../assets/images/new-icons/ee.svg";
import rh from "../../../assets/images/new-icons/rh.svg";
import mg from "../../../assets/images/new-icons/mg.svg";
import om from "../../../assets/images/new-icons/om.svg";
import sdw from "../../../assets/images/new-icons/sdw.svg";
import gs from "../../../assets/images/new-icons/gs.svg";
import iso from "../../../assets/images/new-icons/iso.svg";
import dnv from "../../../assets/images/new-icons/dnv.svg";
import pe from "../../../assets/images/new-icons/pe.svg";
import ebc from "../../../assets/images/new-icons/csi.png";
import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import pccs from "../../../assets/images/new-icons/pccs.svg";
import qarm from "../../../assets/images/new-icons/qarm.svg";
import cep from "../../../assets/images/new-icons/cep.svg";
import step1 from "../../../assets/images/how_it_works/1-min.svg";
import step2 from "../../../assets/images/how_it_works/2-min.svg";
import step3 from "../../../assets/images/how_it_works/3-min.svg";
import step4 from "../../../assets/images/how_it_works/4-min.svg";
import step5 from "../../../assets/images/how_it_works/5-min.svg";
import step6 from "../../../assets/images/how_it_works/three-steps.png";

import Icon from "@mui/material/Icon";

import logo from "assets/images/logo.png";
import Footer from "../components/Footer";
import { FormattedMessage } from "react-intl";
import Partners from "../components/Partners";
import ClientScroller from "ui-component/ClientScroller";

const Services = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 0);
    }
  }, []);
  return (
    <div>
      <NavBar />

      {/* services */}

      <div className="homepage_project_container" style={{ paddingTop: "3em" }}>
        <div className="heading_width">
          <div className="homepage_project_header_container">
            {/* <div className="homepage_project_heading">
            <div className="homepage_project_heading_content">
              {" "}
              <FormattedMessage id="services" defaultMessage="Services" />
            </div>
          </div> */}
            <div className="homepage_project_heading_data_content ">
              <div className="homepage_project_heading_data_content_value ">
                <FormattedMessage
                  id="end_carbon_credit_service"
                  defaultMessage="The most stringent quality assessment and{break} the most conservative approval process in the world"
                  values={{ break: <br /> }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="homepage_project_heading_data_content homepage_project_container_types_row_column_sub"
          style={{ marginBottom: "0.8em", textTransform: "none" }}
        >
          <FormattedMessage
            id="services_sub"
            defaultMessage="Protect your reputation and contribute to global cooling"
          />
        </div>
      </div>

      {/* <div className="homepage_project_container_action center">
        <RouterLink
          to="/getting-started"
          className="homepage_appbar_nav_get_started"
        >
          <FormattedMessage
            id="exploreProjects"
            defaultMessage="Explore projects"
          />
        </RouterLink>
      </div> */}

      {/* other steps  */}

      <div
        className="homepage_project_header_container"
        style={{ margin: "3em 0px 1em 0" }}
      >
        <div className="homepage_project_heading navbar_margin_zero">
          <div className="homepage_project_heading_content">
            <FormattedMessage
              id="extramiles"
              defaultMessage="The Extra Mile in Quality"
            />
          </div>
        </div>
      </div>

      {/* <div className="homepage_project_heading_data_content">
        <div className="homepage_project_heading_data_content_value">
          <FormattedMessage
            id="extramilesDesc"
            defaultMessage="The most stringent quality assessment and{break} the most conservative approval process in the world"
            values={{ break: <br /> }}
          />
        </div>
      </div> */}

      {/* services banner */}

      <div
        className="services_banner_container row-reverse services_banner_container_desktop "
        id="quality-analysis"
      >
        <div className="service_banner_data three_steps_data">
          <div className="bullet-point-item add-bullet-gap">
            <div>
              {" "}
              <div className="circle">1</div>
            </div>
            <span className="bullet-text">
              {" "}
              <FormattedMessage
                id="extramilesstep1"
                defaultMessage="First, we define which project types provide the highest safeguards and most accurate climate impact calculation. Additionally, we look for projects contributing to the United Nations 17 Social Development Goals and support these beyond a climate impact."
              />
            </span>
          </div>
          <div className="bullet-point-item add-bullet-gap">
            <div>
              {" "}
              <div className="circle">2</div>
            </div>
            <span className="bullet-text">
              <FormattedMessage
                id="extramilesstep2"
                defaultMessage="Secondly, we screen all projects in these categories using external sources. For this, we review the project’s certification document and related climate impact calculations, as well as publicly available information."
              />
            </span>
          </div>
          <div className="bullet-point-item add-bullet-gap">
            <div>
              {" "}
              <div className="circle">3</div>
            </div>
            <span className="bullet-text">
              <FormattedMessage
                id="extramilesstep3"
                defaultMessage="Thirdly, we conduct a detailed quality analysis above and beyond the requirements of the certification standard to ensure only the best of the best projects are listed on Freeze Carbon. This is the most extensive part and most vital to our work as we focus on quality over quantity."
              />
            </span>
          </div>
        </div>

        <div className="service_banner_image_reverse three_steps_image">
          <img src={step6} className="services_image_width_90" />
        </div>
      </div>

      {/* Mobile version of "Quality Analysis & Risk Mitigation" banner */}
      <div
        className="services_banner_container services_banner_container_mobile"
        id="quality-analysis"
      >
        <div className="service_banner_image_reverse">
          <img src={step6} className="service_image_width three_steps_image" />
        </div>
        <div className="bullet-point-item add-bullet-gap">
          <div>
            {" "}
            <div className="circle">1</div>
          </div>
          <span className="bullet-text">
            <FormattedMessage
              id="service2_desc1"
              defaultMessage="Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze."
            />
          </span>
        </div>
        <div className="bullet-point-item add-bullet-gap">
          <div>
            {" "}
            <div className="circle">2</div>
          </div>
          <span className="bullet-text">
            <FormattedMessage
              id="service2_desc2"
              defaultMessage="Ongoing monitoring and tracking of each project."
            />
          </span>
        </div>
        <div className="bullet-point-item add-bullet-gap">
          <div>
            {" "}
            <div className="circle">3</div>
          </div>
          <span className="bullet-text">
            <FormattedMessage
              id="service2_desc3"
              defaultMessage="Comprehensive 5-page report on each project, providing you with all details to make an informed purchase"
            />
          </span>
        </div>
      </div>

      {/* first steps  */}
      <div
        className="homepage_project_header_container "
        style={{ margin: "3em 0px 1em 0" }}
      >
        <div className="homepage_project_heading navbar_margin_zero">
          <div className="homepage_project_heading_content">
            <FormattedMessage id="firstSteps" defaultMessage="First steps" />
          </div>
        </div>
      </div>

      {/* steps 1  */}

      <div
        className="services_banner_container services_banner_container_desktop service_steps_desktop "
        id="personalized-strategy"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">1</div>
            <div>
              <FormattedMessage id="step1_one" defaultMessage="Sign up " />
              <a href="/getting-started" style={{ color: "inherit" }}>
                <FormattedMessage id="step1_two" defaultMessage="here" />
              </a>
              <FormattedMessage
                id="step1_three"
                defaultMessage=" to get access to Freeze Carbon"
              />
            </div>
          </div>
        </div>

        <div className="service_banner_image service_image_desktop">
          <img src={step1} className="image_width" />
        </div>
      </div>

      {/* step 1 banner mobile */}

      <div
        className="services_banner_container services_banner_container_mobile"
        id="personalized-strategy"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">1</div>
            <div>
              <FormattedMessage id="step1_one" defaultMessage="Sign up " />
              <a href="/getting-started" style={{ color: "inherit" }}>
                <FormattedMessage id="step1_two" defaultMessage="here" />
              </a>
              <FormattedMessage
                id="step1_three"
                defaultMessage=" to get access to Freeze Carbon"
              />
            </div>
          </div>
        </div>

        <div className="service_banner_image">
          <img src={step1} className="service_image_width" />
        </div>
      </div>

      {/* step 2 */}

      <div
        className="services_banner_container row-reverse services_banner_container_desktop  service_steps_reverse_desktop"
        id="quality-analysis"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">2</div>
            <FormattedMessage
              id="step2"
              defaultMessage="View and compare the best carbon projects on the planet"
              values={{ break: <br /> }}
            />
          </div>
        </div>

        <div className="service_banner_image_reverse service_image_desktop">
          <img src={step2} className="image_width" />
        </div>
      </div>

      {/* step 2 Mobile version  */}
      <div
        className="services_banner_container services_banner_container_mobile"
        id="quality-analysis"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">2</div>
            <FormattedMessage
              id="step2"
              defaultMessage="View and compare the best carbon projects on the planet"
              values={{ break: <br /> }}
            />
          </div>
        </div>

        <div className="service_banner_image_reverse">
          <img src={step2} className="service_image_width" />
        </div>
      </div>

      {/* steps 3 */}

      <div
        className="services_banner_container services_banner_container_desktop service_steps_desktop"
        id="personalized-strategy"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">3</div>
            <FormattedMessage
              id="step3"
              defaultMessage="Sign up here to get access to Freeze Carbon"
            />
          </div>
        </div>

        <div className="service_banner_image">
          <img src={step3} className="image_width" />
        </div>
      </div>

      {/* step 3 banner mobile */}

      <div
        className="services_banner_container services_banner_container_mobile"
        id="personalized-strategy"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">3</div>
            <FormattedMessage
              id="step3"
              defaultMessage="Sign up here to get access to Freeze Carbon"
            />
          </div>
        </div>

        <div className="service_banner_image">
          <img src={step3} className="service_image_width" />
        </div>
      </div>

      {/* step 4 */}

      <div
        className="services_banner_container row-reverse services_banner_container_desktop service_steps_reverse_desktop"
        id="quality-analysis"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">4</div>
            <FormattedMessage
              id="step4"
              defaultMessage="View and compare the best carbon projects on the planet"
              values={{ break: <br /> }}
            />
          </div>
        </div>

        <div className="service_banner_image_reverse service_steps_image_width">
          <img src={step4} className="image_width" />
        </div>
      </div>

      {/* step 4 Mobile version  */}
      <div
        className="services_banner_container services_banner_container_mobile"
        id="quality-analysis"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">4</div>
            <FormattedMessage
              id="step4"
              defaultMessage="View and compare the best carbon projects on the planet"
              values={{ break: <br /> }}
            />
          </div>
        </div>

        <div className="service_banner_image_reverse">
          <img
            src={step4}
            className="service_image_width service_steps_image_width"
          />
        </div>
      </div>

      {/* steps 5  */}

      <div
        className="services_banner_container services_banner_container_desktop service_steps_desktop"
        id="personalized-strategy"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">5</div>
            <FormattedMessage
              id="step5"
              defaultMessage="Sign up here to get access to Freeze Carbon"
            />
          </div>
        </div>

        <div className="service_banner_image service_banner_steps_image_width">
          <img src={step5} className="service_steps_image_width" />
        </div>
      </div>

      {/* step 1 banner mobile */}

      <div
        className="services_banner_container services_banner_container_mobile"
        id="personalized-strategy"
      >
        <div className="service_banner_data service_steps_data_desktop">
          <div className="service_banner_heading service_steps_banner_heading">
            <div className="circle">5</div>
            <FormattedMessage
              id="step5"
              defaultMessage="Sign up here to get access to Freeze Carbon"
            />
          </div>
        </div>

        <div className="service_banner_image">
          <img src={step5} className="service_image_width" />
        </div>
      </div>

      <div className="homepage_project_container_action center">
        <a
          href="#register-free-account"
          className="homepage_appbar_nav_get_started"
        >
          <FormattedMessage id="exploreProjects" defaultMessage="Start now" />
        </a>
      </div>

      {/* clients  */}
      <div style={{ marginTop: "3em" }}>
        <ClientScroller />
      </div>

      {/* certification */}

      <div className="homepage_certification_logo1">
        <div className="homepage_certification_logo1_frame118">
          <div className="homepage_certification_logo1_frame118_heading">
            <FormattedMessage
              id="certifiers_we_partner_with"
              defaultMessage="Certifiers we partner with"
            />
          </div>
          <div className="homepage_certification_logo1_frame118_content">
            <div className="homepage_certification_logo1_frame118_row">
              <img src={gs} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={iso} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={dnv} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={pe} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={ebc} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="homepage_project_header_container"
        style={{ margin: "6em 0px 1em 0" }}
      >
        <div className="homepage_project_heading navbar_margin_zero">
          <div className="homepage_project_heading_content">
            <FormattedMessage
              id="end_to_end"
              defaultMessage="End-to-end service"
            />
          </div>
        </div>
      </div>

      {/* services banner */}

      <div
        className="services_banner_container services_banner_container_desktop "
        id="personalized-strategy"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            <FormattedMessage
              id="personalized_carbon_credit_strategy"
              defaultMessage="Personalized carbon credit strategy"
            />
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              <FormattedMessage
                id="service1_desc1"
                defaultMessage="Our experts tailor a portfolio of projects matching your ESG goals (e.g. SBTi, TÜV), company DNA, industry and supply chain."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              <FormattedMessage
                id="service1_desc2"
                defaultMessage="Get inspired by our pre-build portfolios or custom design one with our experts."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              <FormattedMessage
                id="service1_desc3"
                defaultMessage="We support your climate contribution ambition or accelerate your journey towards net-zero emissions."
              />
            </span>
          </div>
          <div className="service_banner_button">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started"
            >
              <FormattedMessage id="getStarted" defaultMessage="Sign up" />
            </RouterLink>
          </div>
        </div>

        <div className="service_banner_image">
          <img src={pccs} className="image_width" />
        </div>
      </div>

      {/* services banner mobile */}

      <div
        className="services_banner_container services_banner_container_mobile"
        id="personalized-strategy"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            <FormattedMessage
              id="personalized_carbon_credit_strategy"
              defaultMessage="Personalized carbon credit strategy"
            />
          </div>
        </div>

        <div className="service_banner_image">
          <img src={pccs} className="service_image_width" />
        </div>

        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service1_desc1"
              defaultMessage="Our experts tailor a portfolio of projects matching your ESG goals (e.g. SBTi, TÜV), company DNA, industry and supply chain."
            />
          </span>
        </div>
        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service1_desc2"
              defaultMessage="Get inspired by our pre-build portfolios or custom design one with our experts."
            />
          </span>
        </div>
        <div className="service_banner_button">
          <RouterLink
            to="/getting-started"
            className="homepage_appbar_nav_get_started"
          >
            <FormattedMessage id="getStarted" defaultMessage="Sign up" />
          </RouterLink>
        </div>
      </div>

      {/* services banner */}

      <div
        className="services_banner_container row-reverse services_banner_container_desktop "
        id="quality-analysis"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            <FormattedMessage
              id="quality_analysis_risk_mitigation"
              defaultMessage="Quality analysis{break} & risk mitigation"
              values={{ break: <br /> }}
            />
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              {" "}
              <FormattedMessage
                id="service2_desc1"
                defaultMessage="Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              <FormattedMessage
                id="service2_desc2"
                defaultMessage="Ongoing monitoring and tracking of each project."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              <FormattedMessage
                id="service2_desc3"
                defaultMessage="Comprehensive 5-page report on each project, providing you with all details to make an informed purchase"
              />
            </span>
          </div>
          <div className="service_banner_button">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started"
            >
              <FormattedMessage id="getStarted" defaultMessage="Sign up" />
            </RouterLink>
          </div>
        </div>

        <div className="service_banner_image_reverse">
          <img src={qarm} className="image_width" />
        </div>
      </div>

      {/* Mobile version of "Quality Analysis & Risk Mitigation" banner */}
      <div
        className="services_banner_container services_banner_container_mobile"
        id="quality-analysis"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            <FormattedMessage
              id="quality_analysis_risk_mitigation"
              defaultMessage="Quality analysis{break} & risk mitigation"
              values={{ break: <br /> }}
            />
          </div>
        </div>

        <div className="service_banner_image_reverse">
          <img src={qarm} className="service_image_width" />
        </div>
        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service2_desc1"
              defaultMessage="Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze."
            />
          </span>
        </div>
        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service2_desc2"
              defaultMessage="Ongoing monitoring and tracking of each project."
            />
          </span>
        </div>
        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service2_desc3"
              defaultMessage="Comprehensive 5-page report on each project, providing you with all details to make an informed purchase"
            />
          </span>
        </div>
        <div className="service_banner_button">
          <RouterLink
            to="/getting-started"
            className="homepage_appbar_nav_get_started"
          >
            <FormattedMessage id="getStarted" defaultMessage="Sign up" />
          </RouterLink>
        </div>
      </div>

      {/* services banner */}

      <div
        className="services_banner_container services_banner_container_desktop"
        id="cost-effective"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            <FormattedMessage
              id="cost_effective_procurement"
              defaultMessage="Cost-effective{break} procurement"
              values={{ break: <br /> }}
            />
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              {" "}
              <FormattedMessage
                id="service3_desc1"
                defaultMessage="Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place."
              />
            </span>
          </div>
          <div className="bullet-point-item">
            <span className="bullet-marker">•</span>
            <span className="bullet-text">
              {" "}
              <FormattedMessage
                id="service3_desc2"
                defaultMessage="Utilize secure payment options, acting as an escrow service."
              />
            </span>
          </div>
          <div className="service_banner_button">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started"
            >
              <FormattedMessage id="getStarted" defaultMessage="Sign up" />
            </RouterLink>
          </div>
        </div>

        <div className="service_banner_image">
          <img src={cep} className="image_width" />
        </div>
      </div>

      {/* Mobile version of "Cost-effective Procurement" banner */}
      <div
        className="services_banner_container services_banner_container_mobile"
        id="cost-effective"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            <FormattedMessage
              id="cost_effective_procurement"
              defaultMessage="Cost-effective{break} procurement"
              values={{ break: <br /> }}
            />
          </div>
        </div>

        <div className="service_banner_image">
          <img src={cep} className="service_image_width" />
        </div>
        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service3_desc1"
              defaultMessage="Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place."
            />
          </span>
        </div>
        <div className="bullet-point-item">
          <span className="bullet-marker">•</span>
          <span className="bullet-text">
            <FormattedMessage
              id="service3_desc2"
              defaultMessage="Utilize secure payment options, acting as an escrow service."
            />
          </span>
        </div>
        <div className="service_banner_button">
          <RouterLink
            to="/getting-started"
            className="homepage_appbar_nav_get_started"
          >
            <FormattedMessage id="getStarted" defaultMessage="Sign up" />
          </RouterLink>
        </div>
      </div>

      {/* partners  */}

      <Partners />

      <Footer />
    </div>
  );
};

export default Services;

// <div className="homepage_project_container_types">
// <div className="homepage_project_container_types_row">
//   <div className="homepage_project_container_types_row_column homepage_box_height">
//     <div className="service_banner_image homepage_image">
//       <img src={pccs} className="home_image_width" />
//     </div>
//     <div className="homepage_project_container_types_row_column_heading1">
//       <FormattedMessage
//         id="personalized_carbon_credit_strategy"
//         defaultMessage="Personalized carbon credit strategy"
//       />
//     </div>

//   </div>
// </div>
// <div className="homepage_project_container_types_row">
//   <div className="homepage_project_container_types_row_column homepage_box_height">
//     <div className="service_banner_image homepage_image">
//       <img src={qarm} className="home_image_width" />
//     </div>
//     <div className="homepage_project_container_types_row_column_heading1">
//       <FormattedMessage
//         id="quality_analysis_risk_mitigation"
//         defaultMessage="Quality analysis{break} & risk mitigation"
//         values={{ break: <br /> }}
//       />
//     </div>

//   </div>
// </div>
// <div className="homepage_project_container_types_row">
//   <div className="homepage_project_container_types_row_column homepage_box_height">
//     <div className="service_banner_image homepage_image">
//       <img src={cep} className="home_image_width" />
//     </div>
//     <div className="homepage_project_container_types_row_column_heading1">
//       <FormattedMessage
//         id="cost_effective_procurement"
//         defaultMessage="Cost-effective{break} procurement"
//         values={{ break: <br /> }}
//       />
//     </div>

//   </div>
// </div>
// </div>
