import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import FeatureSection from "views/pages/learn/FeatureSection";

const Learn = () => {
  return (
    <div>
      <NavBar />
      <div className="learn_section">
        <FeatureSection />
      </div>
      <Footer />
    </div>
  );
};

export default Learn;
