import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Grid } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { Stack } from "@mui/system";

const LegalNotice = () => {
  return (
    <div>
      <NavBar />
      <div className="leagal_notice_container">
        <div className="homepage_project_heading_data_content leagal_notice_container_heading">
          <div className="homepage_project_heading_data_content_value">
            Imprint
          </div>
          <div className="leagal_notice_container_description">
            Last updated on 14th Mar 2023
          </div>
        </div>
        <Grid item xs={12}>
          <MainCard
            elevation={4}
            border={false}
            boxShadow
            shadow={4}
            sx={{ mb: 10, backgroundColor: "#F8F8F8" }}
          >
            <Stack spacing={2} sx={{ textAlign: "left" }}>
              <h2>Imprint/ Angaben gemäß § 5 TMG</h2>
              Freeze Carbon GmbH <br />
              co TechQuartier
              <br />
              Platz der Einheit 2<br />
              60327 Frankfurt
              <br />
              <br />
              Register number/ HRB: 131410
              <br />
              Register/ Registergericht: Amtsgericht Frankfurt am Main
              <br />
              VAT: DE361709801
              <br />
              Representative/ Vertreten durch:
              <br />
              Thorsten Ratzlaff
              <br />
              <h2>Contact/ Kontakt</h2>
              Telephone/ Telefon: +4915209083111
              <br />
              E-Mail: support@freezecarbon.com
              <br />
              <h2>EU Dispute Settlement/ EU-Streitschlichtung</h2>
              The European Commission provides a platform for online dispute
              resolution. <br />
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung bereit: <br />
              https://ec.europa.eu/consumers/odr/.
              <h2>
                Consumer dispute resolution, universal arbitration board/
                Verbraucher¬streit¬beilegung,Universal-schlichtungs¬stelle
              </h2>
              We are not willing or obliged to participate in dispute settlement
              procedures before a consumer arbitration board./
              <br />
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
              <br />
              Source/ Quelle: eRecht24
            </Stack>
          </MainCard>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default LegalNotice;
